(function () {
  'use strict';

  class ReportPlayerDeleteController {
    private modalInstance;

    confirm() {
      this.modalInstance.close('ok');
    }

    cancel() {
      this.modalInstance.dismiss();
    }
  }

  angular.module('app.scouting').component('modalReportPlayerDelete', {
    templateUrl: 'scouting/components/modals/report-player-delete.html',
    controller: ReportPlayerDeleteController,
    bindings: {
      modalInstance: '<',
    },
  });
})();
