var ScoutingCollectionListsController = (function () {
    function ScoutingCollectionListsController(ScoutingService, $state) {
        this.$state = $state;
        this.paginators = [];
        this.selectedListIdx = -1;
    }
    ScoutingCollectionListsController.prototype.$onChanges = function (changes) {
        var params = this.$state.params;
        var collection = changes.selectedCollection.currentValue;
        if (collection) {
            this.paginators = [];
            this.selectedListIdx = collection.lists.findIndex(function (list) { return list._id === params.listId; });
            if (this.selectedListIdx < 0) {
                this.selectedListIdx = 0;
            }
        }
    };
    Object.defineProperty(ScoutingCollectionListsController.prototype, "selectedList", {
        get: function () {
            return this.selectedCollection.lists[this.selectedListIdx];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScoutingCollectionListsController.prototype, "paginator", {
        get: function () {
            return this.paginators[this.selectedListIdx];
        },
        enumerable: false,
        configurable: true
    });
    ScoutingCollectionListsController.prototype.setPaginator = function (value) {
        this.paginators[this.selectedListIdx] = value;
    };
    ScoutingCollectionListsController.prototype.switchTab = function (collectionId, listId) {
        this.$state.go('root.scouting.collections.lists.players', {
            id: collectionId,
            listId: listId,
        });
    };
    return ScoutingCollectionListsController;
}());
angular.module('app.scouting').component('scoutingCollectionLists', {
    templateUrl: 'scouting/components/collection-lists.html',
    controller: ScoutingCollectionListsController,
    controllerAs: '$collectionListCtrl',
    bindings: {
        selectedCollection: '<',
        availableLabels: '<',
        onCreateLabel: '&',
    },
});