const MIN_HUE = 0;
const MAX_HUE = 120;

function scColorTransitionFilter() {
  return (step, totalSteps, isReverse, opacity = 1) => {
    if (isReverse) {
      step = totalSteps - 1 - step;
    }

    const hue = (MIN_HUE + (MAX_HUE - MIN_HUE) * (step / (totalSteps - 1))) | 0;
    const lightness = Math.min(50, 60 - 40 * (step / (totalSteps - 1))) | 0; // clamped linear function from [0, 80] to [1, 20]

    return `hsla(${hue}, 100%, ${lightness}%, ${opacity})`;
  };
}

angular.module('app.scouting').filter('scColorTransition', scColorTransitionFilter);
