import {ScoutingRatingSkill} from './scoutingRatingAvg';

function scoutingAggregatableSkillNamesFilter() {
  return (skills: ScoutingRatingSkill[]) => {
    if (!skills) {
      return 'none';
    }

    const names = skills.filter((item) => !!item.includedForAggregation).map((item) => item.name);

    switch (names.length) {
      case 0:
        return 'none';

      case skills.length:
        return 'all skills';

      default:
        return names.join(', ');
    }
  };
}

angular
  .module('app.scouting')
  .filter('scoutingAggregatableSkillNames', scoutingAggregatableSkillNamesFilter);
