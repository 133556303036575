(function () {
  'use strict';

  class ScoutingViewReportPlayerOverviewController {
    private report;
    private playerId;
    private selectedPlayer;

    $onChanges(changes) {
      const playerId = _.get(changes.playerId, 'currentValue') || this.playerId;

      this.selectedPlayer = this.report.players.find(
        (item) => item.selected && item.player._id === playerId,
      );
    }
  }

  angular.module('app.scouting').component('scoutingViewReportPlayerOverview', {
    templateUrl: 'scouting/components/view-report-player-overview.html',
    controller: ScoutingViewReportPlayerOverviewController,
    bindings: {
      report: '<',
      playerId: '<',
      gameReportLineups: '<',
    },
  });
})();
