import {PlayerWrapper, ReportRecord} from '../../services/ScoutingService';

const PLAYER_POSITIONS = [
  {value: 'RIGHT_WING', display: 'Right Wing'},
  {value: 'LEFT_WING', display: 'Left Wing'},
  {value: 'FORWARD', display: 'Forward'},
  {value: 'CENTRE', display: 'Centre'},
  {value: 'DEFENSEMAN', display: 'Defenceman'},
  {value: 'LEFT_DEFENSEMAN', display: 'Left Defenceman'},
  {value: 'RIGHT_DEFENSEMAN', display: 'Right Defenceman'},
];

class ScoutingReportHeaderController {
  private report: ReportRecord;
  private selectedPlayer: PlayerWrapper;
  private onAddLabel: () => void;
  private onPlayersUpdated: (params: {$players: PlayerWrapper[]}) => void;

  constructor(
    private $uibModal,
    private ScoutingService,
  ) {}

  findTeam(id: string) {
    return this.report.teams.find((item) => item._id === id || item.temporaryId === id);
  }

  get predefinedPlayerPositions() {
    return PLAYER_POSITIONS;
  }

  async deletePlayer() {
    const modalInstance = this.$uibModal.open({
      size: 'sm',
      template: '<modal-report-player-delete modal-instance="$ctrl.modalInstance">',
      controllerAs: '$ctrl',
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
        },
      ],
    });

    try {
      await modalInstance.result;
      this.onPlayersUpdated({
        $players: angular.copy(
          this.report.players.map((wrapper) => {
            if (wrapper.player._id === this.selectedPlayer.player._id) {
              wrapper.selected = false;
            }
            return wrapper;
          }),
        ),
      });
    } catch (e) {
      // modal close
    }
  }
}

angular.module('app.scouting').component('scoutingReportHeader', {
  templateUrl: 'scouting/components/report/header.html',
  controller: ScoutingReportHeaderController as any,
  bindings: {
    report: '<',
    gameReportLineups: '<',
    editable: '<',
    selectedPlayer: '<',
    onAddLabel: '&',
    onPlayersUpdated: '&',
  },
});
