import {RosterItem} from '../../services/ScoutingService';

class ScoutingPlayersPickerModalController {
  private $uibModal;
  private players: RosterItem[];

  public cancel() {
    this.$uibModal.dismiss();
  }
}

angular.module('app.scouting').component('modalPlayersPicker', {
  templateUrl: 'scouting/components/modals/players-picker.html',
  controller: ScoutingPlayersPickerModalController,
  bindings: {
    players: '<',
    report: '<',
    $uibModal: '<modalInstance',
  },
});
