import {Filter, FilterCriterion} from '../../services/ScoutingFilterService';

interface Operator {
  type: 'is_exact' | 'is_one_of' | 'contains' | 'is_less_than' | 'is_greater_than' | 'is_between';
  negative: boolean;
}

const AVAILABLE_OPERATORS = {
  isExact: {
    type: 'is_exact',
    negative: false,
  } as Operator,

  isOneOf: {
    type: 'is_one_of',
    negative: false,
  } as Operator,

  isNotOneOf: {
    type: 'is_one_of',
    negative: true,
  } as Operator,

  shouldContain: {
    type: 'contains',
    negative: false,
  } as Operator,

  shouldNotContain: {
    type: 'contains',
    negative: true,
  } as Operator,

  isGreaterThan: {
    type: 'is_greater_than',
    negative: false,
  } as Operator,

  isLessThan: {
    type: 'is_less_than',
    negative: false,
  } as Operator,

  isBetween: {
    type: 'is_between',
    negative: false,
  } as Operator,
};

class ScoutingFilterItemController {
  private $scoutingFilterService;

  private viewType: 'players' | 'reports';
  private instance: Filter;
  private filters: Filter[];
  private onChange: () => void;

  private dateInputFormat: string;
  private criterion: FilterCriterion;

  private inner: {
    criterion: FilterCriterion;
    operator?: Operator;
  };

  constructor(UserService, ScoutingFilterService) {
    this.$scoutingFilterService = ScoutingFilterService;
    this.dateInputFormat = UserService.getUser().settings.dateformat;
  }

  $onInit() {
    this.inner = {
      criterion: this.$scoutingFilterService.availableCriteria.find(
        (item) => item.name === this.instance.criterion,
      ),
    };

    if (this.instance.value) {
      if (this.instance.value.query) {
        this.operator = !this.instance.negative
          ? AVAILABLE_OPERATORS.shouldContain
          : AVAILABLE_OPERATORS.shouldNotContain;
      }

      if (this.instance.value.or) {
        this.operator = !this.instance.negative
          ? AVAILABLE_OPERATORS.isOneOf
          : AVAILABLE_OPERATORS.isNotOneOf;
      }

      if (this.instance.value.exact) {
        this.operator = AVAILABLE_OPERATORS.isExact;
      }

      if (
        this.instance.value.range &&
        this.instance.value.range.gt &&
        !this.instance.value.range.lt
      ) {
        this.instance.value.range.gt = new Date(this.instance.value.range.gt);
        this.operator = AVAILABLE_OPERATORS.isGreaterThan;
      }

      if (
        this.instance.value.range &&
        this.instance.value.range.lt &&
        !this.instance.value.range.gt
      ) {
        this.instance.value.range.lt = new Date(this.instance.value.range.lt);
        this.operator = AVAILABLE_OPERATORS.isLessThan;
      }
    }

    if (!this.operator && this.inner.criterion.features.exactSearch) {
      this.operator = AVAILABLE_OPERATORS.isOneOf;
    }
  }

  get availableOperators() {
    return AVAILABLE_OPERATORS;
  }

  get enabled() {
    return !this.instance.disabled;
  }
  set enabled(value: boolean) {
    this.instance.disabled = !value;
    this.onChange();
  }

  get operator() {
    return this.inner.operator;
  }
  set operator(operator: Operator) {
    if (this.inner.operator && operator.type !== this.inner.operator.type) {
      this.instance.value = {};
    }

    if (operator.type === 'is_one_of' && (!this.instance.value || !this.instance.value.or)) {
      this.instance.value = {or: []};
    }

    this.inner.operator = operator;
    this.instance.negative = operator.negative;
    this.onChange();
  }

  delete() {
    const index = this.filters.indexOf(this.instance);

    if (index >= 0) {
      this.filters.splice(index, 1);
    }

    if (!this.instance.disabled) {
      this.onChange();
    }
  }

  get previousFilters() {
    const thisFilterIdx = this.filters.indexOf(this.instance);
    return this.filters.slice(0, thisFilterIdx);
  }

  get isTemporaryDisabled() {
    if (this.viewType === 'players') {
      return false;
    }

    return this.instance.field.startsWith('players.') && this.instance.field !== 'players.team';
  }
}

angular.module('app.scouting').component('scoutingFilterItem', {
  templateUrl: 'scouting/components/filters/filter-item.html',
  controller: ScoutingFilterItemController,
  bindings: {
    instance: '<',
    filters: '<',
    viewType: '<',
    showPublic: '<',
    onChange: '&',
  },
});
