(function () {
  'use strict';

  class ScoutingReportRatingWidgetController {
    private scales;
    private value;
    private bindingValue;

    constructor(
      private $scope,
      private $filter,
    ) {
      $scope.$watch('$ctrl.value', (current) => {
        this.bindingValue = this.scales.find((s) => s.val === current);
      });
    }

    protected getMinScale(scales) {
      return _.min(scales.map((item) => item.val));
    }

    protected getMaxScale(scales) {
      return _.max(scales.map((item) => item.val));
    }

    protected getValueIndex(value) {
      return this.scales.findIndex((item) => item.val === value);
    }

    protected getValueProgressIndex(value) {
      return (
        this.scales.findIndex((item) => {
          return Math.abs(item.val - value) < 0.05;
        }) + 1
      );
    }

    protected isStarActive(selectedStar, currentStar) {
      if (selectedStar >= currentStar) {
        return true;
      }
    }

    protected select(value) {
      if (this.value == value) {
        delete this.value;
        delete this.bindingValue;
      } else {
        this.value = value;
      }

      this.notifyAboutChanges();
    }

    protected notifyAboutChanges() {
      this.$scope.$emit('scouting:element:changed');
    }

    protected range(count) {
      return _.range(count);
    }

    protected scaleValueMatch(search) {
      return (value: any) => {
        if (typeof search === 'undefined' || search === null || search === '') {
          return value;
        }

        if (search.startsWith('.')) {
          return `${value.val}`.indexOf(search) > -1;
        }

        return `${value.val}`.toLowerCase().startsWith(`${search}`.toLowerCase());
      };
    }
  }

  angular.module('app.scouting').component('scoutingReportRatingWidget', {
    templateUrl: 'scouting/components/field/partials/rating-widget.html',
    controller: ScoutingReportRatingWidgetController,
    bindings: {
      editable: '<',
      reverse: '<',
      useDropdown: '<',
      scales: '<',
      stars: '<',
      ratingType: '<',
      value: '=',
      compact: '<',
      showDescriptions: '<',
      mode: '@',
    },
  });
})();
