var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var ScoutingDepartmentController = (function () {
    function ScoutingDepartmentController($uibModal, ScoutingLabelsResource, $state) {
        this.$uibModal = $uibModal;
        this.$state = $state;
        this.$labelsResource = ScoutingLabelsResource;
    }
    ScoutingDepartmentController.prototype.$onInit = function () {
        var _this = this;
        this.labels = this.$labelsResource.query();
        this.labels.$promise.then(function (labels) {
            var selectedLabel = labels.find(function (item) { return item._id === _this.$state.params.label; });
            if (selectedLabel) {
                _this.select(selectedLabel);
            }
            _this.updateTotalPlayersCount();
        });
    };
    ScoutingDepartmentController.prototype.hasNamePredicate = function () {
        return function (label) { return !!label.label; };
    };
    ScoutingDepartmentController.prototype.select = function (label) {
        this.selectedLabel = label;
        this.$state.go('root.scouting.department', { label: label ? label._id : '' }, { notify: false });
    };
    ScoutingDepartmentController.prototype.updateTotalPlayersCount = function () {
        this.totalPlayersCount = this.labels.reduce(function (sum, item) { return sum + item.playersCount; }, 0);
    };
    ScoutingDepartmentController.prototype.showEditLabelModal = function ($event, label) {
        return __awaiter(this, void 0, void 0, function () {
            var modal, result, reason_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        $event.stopPropagation();
                        modal = {
                            template: '<modal-department-edit-label modal-instance="$ctrl.modalInstance" label="$ctrl.label">',
                            controllerAs: '$ctrl',
                            animation: false,
                            backdrop: true,
                            controller: [
                                '$uibModalInstance',
                                function ($uibModalInstance) {
                                    this.modalInstance = $uibModalInstance;
                                    this.label = angular.copy(label);
                                },
                            ],
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.$uibModal.open(modal).result];
                    case 2:
                        result = _a.sent();
                        if (result) {
                            angular.copy(result, label);
                        }
                        else {
                            this.labels.splice(this.labels.indexOf(label), 1);
                        }
                        this.labels = __spreadArray([], this.labels, true);
                        return [3, 4];
                    case 3:
                        reason_1 = _a.sent();
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    ScoutingDepartmentController.prototype.showCreateLabelModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modal, result, reason_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        modal = {
                            template: '<modal-department-create-label modal-instance="$ctrl.modalInstance">',
                            controllerAs: '$ctrl',
                            animation: false,
                            backdrop: true,
                            controller: [
                                '$uibModalInstance',
                                function ($uibModalInstance) {
                                    this.modalInstance = $uibModalInstance;
                                },
                            ],
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.$uibModal.open(modal).result];
                    case 2:
                        result = _a.sent();
                        this.selectedLabel = result;
                        this.labels.push(result);
                        return [3, 4];
                    case 3:
                        reason_2 = _a.sent();
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    ScoutingDepartmentController.prototype.toggleFavourite = function ($event, label) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        $event.stopPropagation();
                        if (!label.favourite) {
                            label.favourite = true;
                            label.favouriteAt = moment().unix();
                        }
                        else {
                            delete label.favourite;
                            delete label.favouriteAt;
                        }
                        this.labels = _.orderBy(this.labels, [
                            function (lb) { return (lb.favourite ? 1 : 0); },
                            function (lb) { return (lb.label ? lb.label.name.toLowerCase() : ''); },
                        ], ['desc', 'asc']);
                        return [4, label.$toggleFavourite().$promise];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    return ScoutingDepartmentController;
}());
angular.module('app.scouting').component('scoutingDepartment', {
    templateUrl: 'scouting/components/department.html',
    controller: ScoutingDepartmentController,
});