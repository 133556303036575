var ScoutingCollectionPlayersController = (function () {
    function ScoutingCollectionPlayersController(Paginator, PlayerCollectionsService, ScoutingLabelsService, ScoutingService) {
        this.$paginator = Paginator;
        this.$playerCollectionsService = PlayerCollectionsService;
        this.$applyLabelPromise = false;
        this.$scoutingService = ScoutingService;
        this.$labelsService = ScoutingLabelsService;
    }
    ScoutingCollectionPlayersController.prototype.$onChanges = function (changes) {
        var _this = this;
        if (!changes.paginator.currentValue) {
            this.setPaginator({
                newPaginator: this.$paginator.getInstance({
                    limit: 30,
                    strategy: 'PaginatorNormalStrategy',
                    pagingFunction: function (_a) {
                        var skip = _a.skip, limit = _a.limit;
                        return _this.$playerCollectionsService.fetchPlayerValues(_this.selectedCollection._id, _this.selectedList._id, { skip: skip, limit: limit });
                    },
                }),
            });
        }
    };
    ScoutingCollectionPlayersController.prototype.getColumns = function () {
        if (this.selectedList.columnDef) {
            return this.selectedList.columnDef.columns;
        }
        return this.selectedCollection.columnDef.columns;
    };
    ScoutingCollectionPlayersController.prototype.getValue = function (col, playerValue) {
        var colVal = playerValue.values.find(function (item) { return item.columnUuid === col.uuid; });
        return colVal ? colVal.value : '-';
    };
    ScoutingCollectionPlayersController.prototype.createNewReport = function () {
        this.$scoutingService.requestReportCreation();
    };
    ScoutingCollectionPlayersController.prototype.showCommentsBoard = function () {
        this.$scoutingService.showCommentsBoard(this.paginator.data);
    };
    ScoutingCollectionPlayersController.prototype.compareReports = function () {
        var players = this.paginator.data.filter(function (item) { return item.$$selected; });
        this.$scoutingService.compareReports(players, []);
    };
    ScoutingCollectionPlayersController.prototype.applyPlayersChanges = function (changes) {
        var _this = this;
        this.$applyLabelPromise = this.$labelsService
            .applyChanges(this.availableLabels, changes)
            .then(function () {
            _this.paginator.reset();
            return _this.paginator.lastPromise;
        });
    };
    Object.defineProperty(ScoutingCollectionPlayersController.prototype, "isAllPlayersSelected", {
        get: function () {
            if (!this.paginator || this.paginator.data.length === 0) {
                return false;
            }
            return this.paginator.data.every(function (item) { return item.$$selected; });
        },
        set: function (value) {
            this.paginator.data.forEach(function (item) {
                item.$$selected = value;
            });
        },
        enumerable: false,
        configurable: true
    });
    return ScoutingCollectionPlayersController;
}());
angular.module('app.scouting').component('scoutingCollectionPlayers', {
    templateUrl: 'scouting/components/collections-players.html',
    controller: ScoutingCollectionPlayersController,
    bindings: {
        selectedCollection: '<',
        selectedList: '<',
        availableLabels: '<',
        onCreateLabel: '&',
        paginator: '<',
        setPaginator: '&',
    },
});