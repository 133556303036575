var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var ReportSetupModalController = (function () {
    function ReportSetupModalController($scope, $state, $q, ScoutingTemplateResource, SearchService, SeasonService, ScoutingReportResource, ScoutingService, TEAM_IMPORTANT_PROPERTIES, LEAGUE_IMPORTANT_PROPERTIES) {
        this.$scope = $scope;
        this.$state = $state;
        this.$q = $q;
        this.TEAM_IMPORTANT_PROPERTIES = TEAM_IMPORTANT_PROPERTIES;
        this.LEAGUE_IMPORTANT_PROPERTIES = LEAGUE_IMPORTANT_PROPERTIES;
        this.$scoutingTemplateResource = ScoutingTemplateResource;
        this.$searchService = SearchService;
        this.$seasonService = SeasonService;
        this.$scoutingService = ScoutingService;
        this.$scoutingReportResource = ScoutingReportResource;
    }
    ReportSetupModalController.prototype.$onInit = function () {
        var _this = this;
        this.availableTemplates = this.$scoutingTemplateResource.query();
        this.availableSeasons = this.$seasonService.getSeasonList();
        this.promise = this.availableTemplates.$promise;
        this.selected = {
            template: null,
            primaryLeague: this.autoselect.league
                ? _.pick(this.autoselect.league, this.LEAGUE_IMPORTANT_PROPERTIES)
                : null,
            primaryTeam: this.autoselect.team
                ? _.pick(this.autoselect.team, this.TEAM_IMPORTANT_PROPERTIES)
                : null,
            primaryPlayerId: this.autoselect.playerIds ? this.autoselect.playerIds[0] : null,
            season: this.$seasonService.getSeason(),
            players: [],
        };
        this.$scope.$on('scouting:roster:populateTeamRoster', function (__, _a) {
            var populatingTeamRoster = _a.populatingTeamRoster;
            _this.$scope.$broadcast('scouting:reportSetup:populatingTeamRoster', {
                populatingTeamRoster: populatingTeamRoster,
            });
        });
    };
    ReportSetupModalController.prototype.proceed = function () {
        var _this = this;
        var report = new this.$scoutingReportResource({
            players: this.selected.players,
            season: this.selected.season,
            template: this.selected.template,
            primaryTeam: this.selected.primaryTeam,
            primaryPlayerId: this.selected.primaryPlayerId,
        });
        if (report.template.taggingElement) {
            report.tags = this.selected.tags;
        }
        if (report.template.gameElement) {
            report.game = this.selected.game;
            report.teams = [report.game.home, report.game.away];
            report.league = report.game.league;
        }
        else {
            report.teams = [this.selected.primaryTeam];
            report.league =
                this.selected.primaryLeague || _.get(this.selected, 'primaryTeam.latestTeamStats.league');
        }
        this.promise = report.$save();
        this.promise.then(function () {
            _this.$uibModal.close(report._id);
        });
    };
    ReportSetupModalController.prototype.cancel = function () {
        this.$uibModal.dismiss();
    };
    ReportSetupModalController.prototype.findTeams = function (query) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, searchResult, temporaryTeams, existingTeams, foundTeams;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.$q.all([
                            this.$searchService.searchForTeams(query),
                            this.$scoutingService.findTemporaryTeams(query),
                        ])];
                    case 1:
                        _a = _b.sent(), searchResult = _a[0], temporaryTeams = _a[1];
                        existingTeams = searchResult.teams.map(function (item) {
                            return _.pick(item._source, _this.TEAM_IMPORTANT_PROPERTIES);
                        });
                        foundTeams = existingTeams.concat(temporaryTeams);
                        if (temporaryTeams.some(function (team) { return team.name == query; })) {
                            return [2, foundTeams];
                        }
                        else {
                            return [2, foundTeams.concat([
                                    {
                                        name: query,
                                        temporaryId: this.$scoutingService.createTemporaryId(),
                                    },
                                ])];
                        }
                        return [2];
                }
            });
        });
    };
    return ReportSetupModalController;
}());
angular.module('app.scouting').component('modalReportSetup', {
    templateUrl: 'scouting/components/modals/report-setup.html',
    controller: ReportSetupModalController,
    bindings: {
        $uibModal: '<modalInstance',
        autoselect: '<',
    },
});
;