class PlaylistService {
  private $http;
  private nextGenPlaylistEndpoint;

  constructor(SCConfiguration, $http) {
    this.$http = $http;
    this.nextGenPlaylistEndpoint = SCConfiguration.getEndpoint() + '/api/nextgen';
  }

  fetchTopLevel() {
    return this.$http
      .get(this.nextGenPlaylistEndpoint + '/my-top-level')
      .then((response) => response.data);
  }

  fetchChildNodes(id) {
    return this.$http
      .get(this.nextGenPlaylistEndpoint + `/child-nodes/${id}`)
      .then((res) => res.data);
  }

  fetchScoutingReportPlaylists(scoutingReportId, playlistIds) {
    return this.$http
      .get(
        this.nextGenPlaylistEndpoint +
          `/playlists-by-scouting-report/${scoutingReportId}?playlistIds=${playlistIds}`,
      )
      .then((res) => res.data.map(({playlist}) => playlist));
  }
}

angular.module('app.scouting').service('PlaylistService', PlaylistService);
