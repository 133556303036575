class RichTextController {
  editorName = '';
  toolbarName = '';
  selectedPlayer = null;
  playlistPromise = null;
  ngModel = '';
  ngChange = null;
  outputRef = null;
  editable = false;
  editorClass = '';
  toolbarVisible = false;
  compact = false;
  report = null;
  $promise;

  constructor(
    private textAngularManager,
    private taSelection,
    private uuid,
    private $scope,
    private $uibModal,
    private PlayerService,
    private ScoutingService,
    private GameService,
    private $timeout,
  ) {
    this.editorName = this.uuid.v4();
    this.toolbarName = this.uuid.v4();
    this.$promise = false;
  }

  $onChanges(changes) {
    if (changes.compact) {
      this.toolbarVisible = !changes.compact.currentValue;
    }
    if (changes.editable && changes.editable.currentValue) {
      this.setupEditor();
    }
  }

  handleRichTextPlaylistClick($event) {
    $event.preventDefault();
    const playlistId = $event.target.getAttribute('href');
    if (playlistId) {
      this.playPlaylist(playlistId);
    }
  }

  playPlaylist(playlistId) {
    this.GameService.openNextGenScoutingPlaylist(this.report._id, playlistId);
  }

  pickPlayerPlaylist() {
    return this.$uibModal.open({
      size: 'md',
      template: '<playlist-picker-modal modal-instance="$ctrl.modalInstance" />',
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
        },
      ],
      controllerAs: '$ctrl',
    });
  }

  toggleToolbarVisible() {
    if (this.toolbarVisible === false) {
      this.toolbarVisible = true;
      this.setupEditor();
    } else {
      this.toolbarVisible = false;
    }
    const editorScope = this.textAngularManager.retrieveEditor(this.editorName).scope;
    this.$timeout(function () {
      editorScope.displayElements.text.trigger('focus');
    });
  }

  setupEditor() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    const saveSelection = () => {
      const sel = window.getSelection();
      const ranges = [];
      if (sel.rangeCount) {
        for (let x = 0; x < sel.rangeCount; ++x) {
          ranges.push(sel.getRangeAt(x));
        }
      }
      return ranges;
    };

    const restoreSelection = (savedSelection) => {
      const sel = window.getSelection();
      sel.removeAllRanges();
      for (let x = 0; x < savedSelection.length; ++x) {
        sel.addRange(savedSelection[x]);
      }
    };

    setTimeout(() => {
      if (!this.toolbarVisible) {
        return;
      }
      this.textAngularManager.removeTool('playlist' + this.editorName);
      this.textAngularManager.addToolToToolbar(
        'playlist' + this.editorName,
        {
          iconclass: 'add-to-playlist',
          action: function () {
            const lastSel = saveSelection();
            const isCollapsed = lastSel.every((range) => range.collapsed);
            self.pickPlayerPlaylist().result.then((playlists) => {
              restoreSelection(lastSel);
              const [playlist] = playlists;
              if (playlist) {
                if (isCollapsed) {
                  // insert playlist without text selection
                  this.$editor().wrapSelection(
                    'inserthtml',
                    `<a href="${playlist.id}" title="${playlist.name}">${playlist.name}</a>`,
                    true,
                  );
                } else {
                  // insert playlist with text selection
                  // a hack to insert title to playlist
                  this.$editor().wrapSelection(
                    'createLink',
                    playlist.id + `" title="${playlist.name}`,
                    true,
                  );
                }
              }
            });
          },
        },
        this.toolbarName,
      );
    }, 0);
  }
}

angular.module('app.scouting').component('richTextEditor', {
  templateUrl: 'scouting/components/field/partials/rich-text.html',
  controller: RichTextController as any,
  bindings: {
    report: '<',
    selectedPlayer: '<',
    editable: '<',
    compact: '<',
    editorClass: '<',
    ngModel: '=',
    ngChange: '&',
  },
});
