class DepartmentCreateLabelModalController {
  private $uibModal;
  private $labelsResource;

  private name: string;
  private color: string;
  private visibility: 'private' | 'organization' | 'users';
  private shareWith: any[];
  private promise;

  private organizations: any[];

  constructor(
    private OrganizationService,
    private $q,
    ScoutingLabelsResource,
  ) {
    this.$labelsResource = ScoutingLabelsResource;
    this.visibility = 'private';
    this.shareWith = [];
    this.color = '#8a8a8a';
    this.promise = this.$q.resolve();
  }

  $onInit() {
    this.promise = this.OrganizationService.listHierarchyOrganizations().then((organizations) => {
      this.organizations = organizations;
    });
  }

  cancel() {
    this.$uibModal.dismiss();
  }

  proceed() {
    this.promise = this.createLabel();
  }

  handleVisiblityChange() {
    this.shareWith = [];
  }

  private async createLabel() {
    this.$uibModal.close(
      await this.$labelsResource.save({
        label: {
          name: this.name,
          color: this.color,
          visibility: this.visibility,
          shareWith: this.visibility === 'users' ? this.shareWith : [],
        },
      }).$promise,
    );
  }

  private selectShareWithUsers(users) {
    this.shareWith = users;
  }
}

angular.module('app.scouting').component('modalDepartmentCreateLabel', {
  templateUrl: 'scouting/components/modals/department-create-label.html',
  controller: DepartmentCreateLabelModalController as any,
  bindings: {
    $uibModal: '<modalInstance',
  },
});
