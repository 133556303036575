class ScoutingCollectionsController {
  private $playerCollectionService;
  private $promise;
  private playerCollections;
  private selectedCollection;
  private labels;
  private $scoutingService;
  private $labelsResource;

  constructor(
    private $q,
    private $uibModal,
    PlayerCollectionsService,
    ScoutingService,
    ScoutingLabelsResource,
    private $state,
  ) {
    this.$playerCollectionService = PlayerCollectionsService;
    this.$scoutingService = ScoutingService;
    this.$labelsResource = ScoutingLabelsResource;
  }
  $onInit() {
    this.$promise = this.$q
      .all({
        collections: this.$playerCollectionService.fetchPlayerCollections(),
        labels: this.$labelsResource.query(),
      })
      .then(({collections, labels}) => {
        this.playerCollections = collections;
        this.labels = labels;
        this.selectedCollection =
          collections.find((item) => item._id === this.$state.params.id) || collections[0];
        if (this.selectedCollection && this.$state.is('root.scouting.collections')) {
          this.$state.go('root.scouting.collections.lists', {
            id: collections[0]._id,
            listId: collections[0].lists[0]._id,
          });
        }
      });
  }

  public async showCreateLabelModal() {
    const modal = {
      template: '<modal-department-create-label modal-instance="$ctrl.modalInstance">',
      controllerAs: '$ctrl',
      animation: false,
      backdrop: true,
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
        },
      ],
    };

    try {
      const result = await this.$uibModal.open(modal).result;

      this.labels.push(result);
    } catch (reason) {
      //
    }
  }

  gotoLists(collectionId) {
    this.selectedCollection =
      this.playerCollections.find((item) => item._id === collectionId) || this.playerCollections[0];
    this.$state.go('root.scouting.collections.lists', {id: this.selectedCollection._id});
  }
}

angular.module('app.scouting').component('scoutingCollections', {
  templateUrl: 'scouting/components/collections.html',
  controller: ScoutingCollectionsController,
  controllerAs: '$collectionCtrl',
});
