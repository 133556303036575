class ScoutingReportGameViewController {
  private report;
  private editable;

  constructor(private $scope) {}

  getReportAttachmentHolder(teamId) {
    const holder =
      this.report.attachments && this.report.attachments.find((holder) => holder.team === teamId);

    return holder || this.createHolder(teamId);
  }

  @CacheReturnValue((context) => context.$scope)
  @OnReturnObjectChangedOnce(
    (context) => context.$scope,
    // eslint-disable-next-line no-empty-pattern
    (context, holder, []) => {
      context.report.attachments = context.report.attachments || [];
      context.report.attachments.push(holder);
    },
  )
  private createHolder(teamId) {
    return {team: teamId, list: []};
  }
}

angular.module('app.scouting').component('scoutingReportGameView', {
  templateUrl: 'scouting/components/report/game-view.html',
  controller: ScoutingReportGameViewController,
  bindings: {
    report: '<',
    editable: '<',
  },
});
