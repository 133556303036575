import {
  Filter,
  FilterCriterion,
  SerializedRepresentation,
} from '../../services/ScoutingFilterService';

class ScoutingFilterElementController {
  private $scoutingFilterService;

  private dateInputFormat: string;
  private dateRange: {min: Date; max: Date};
  private numberRange: {min: number; max: number};
  private selectableChoices: any[];

  private value;
  private instance;
  private promise: Promise<void>;
  private criterion: FilterCriterion;
  private previousFilters: Filter[];
  private type: string;
  private showPublic;
  private onChange: () => void;

  private previousFiltersSerialized: SerializedRepresentation;

  constructor(UserService, ScoutingFilterService) {
    this.$scoutingFilterService = ScoutingFilterService;

    this.dateInputFormat = UserService.getUser().settings.dateformat;
    this.previousFiltersSerialized = {};
  }

  serializeFilters(filters: Filter[]) {
    return this.$scoutingFilterService.serialize(filters);
  }

  $onChanges(changes) {
    let shouldRefreshFilter = false;

    if (changes.previousFilters) {
      const serializedFilters = this.serializeFilters(changes.previousFilters.currentValue);

      if (!_.isEqual(serializedFilters, this.previousFiltersSerialized)) {
        shouldRefreshFilter = true;
      }

      this.previousFiltersSerialized = serializedFilters;
    }

    if (changes.criterion && changes.criterion.currentValue.element === 'date') {
      this.promise = this.fetchDateRange();
      return;
    }

    if (changes.criterion && changes.criterion.currentValue.element === 'number') {
      this.promise = this.fetchNumberRange();
      return;
    }

    if (changes.criterion && changes.criterion.currentValue.element === 'select') {
      this.promise = this.fetchAvailableChoices();
      return;
    }

    if (shouldRefreshFilter) {
      if (this.criterion.element === 'select') {
        this.promise = this.fetchAvailableChoices();
      } else if (this.criterion.element === 'number') {
        this.promise = this.fetchNumberRange();
      } else if (this.criterion.element === 'date') {
        this.promise = this.fetchDateRange();
      }
    }

    if (changes.type && this.numberRange && this.criterion && this.criterion.element === 'number') {
      if (this.type === 'is_exact' && !this.value.exact) {
        this.value.exact = this.numberRange.min;
        this.onChange();
      }

      if (this.type === 'is_between' && !this.value.range.lt && !this.value.range.gt) {
        this.value.range.lt = this.numberRange.max;
        this.value.range.gt = this.numberRange.min;
        this.onChange();
      }

      if (this.type === 'is_less_than' && !this.value.range.lt) {
        this.value.range.lt = this.numberRange.max;
        this.onChange();
      }

      if (this.type === 'is_greater_than' && !this.value.range.gt) {
        this.value.range.gt = this.numberRange.min;
        this.onChange();
      }
    }
  }

  searchChoices(query) {
    const availableChoices = this.selectableChoices.filter((item) => {
      if (this.value.or.includes(item)) {
        return false;
      }

      if (item._id && !!this.value.or.find((element) => element._id === item._id)) {
        return false;
      }

      return true;
    });

    query = _.deburr(query).toLocaleLowerCase();

    if (!query.replace(/\s/, '')) {
      return availableChoices;
    }

    return availableChoices.filter((choice) => {
      const choiceText = _.deburr(this.getChoiceDisplay(choice)).toLocaleLowerCase();

      return query.split(/\s+/).every((part) => choiceText.includes(part));
    });
  }

  getChoiceDisplay(choice) {
    return this.criterion.displayTransformer(choice);
  }

  async fetchDateRange() {
    this.dateRange = await this.$scoutingFilterService.queryRange(
      this.criterion.field,
      this.previousFilters,
    );
    this.dateRange.max = new Date(this.dateRange.max);
    this.dateRange.min = new Date(this.dateRange.min);
  }

  async fetchNumberRange() {
    this.numberRange = await this.$scoutingFilterService.queryRange(
      this.criterion.field,
      this.previousFilters,
    );
  }

  async fetchAvailableChoices() {
    if (this.instance.choices) {
      this.selectableChoices = this.instance.choices;
    } else {
      this.selectableChoices = await this.$scoutingFilterService.queryChoices(
        this.criterion.field,
        this.previousFilters,
        this.showPublic ? 1 : -1,
      );
    }
  }
}

angular.module('app.scouting').component('scoutingFilterInputElement', {
  templateUrl: 'scouting/components/filters/filter-input-element.html',
  controller: ScoutingFilterElementController,
  bindings: {
    criterion: '<',
    previousFilters: '<',
    type: '<',
    value: '=',
    instance: '<',
    showPublic: '<',
    onChange: '&',
  },
});
