var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var ScoutingReportsListController = (function () {
    function ScoutingReportsListController($scope, $state, $uibModal, UserService, ScoutingReportResource, ACLService, ScoutingTemplateResource, Paginator, ScoutingService, ScoutingFilterService, ScoutingLabelsResource, ScoutingLabelsService) {
        this.$scope = $scope;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.UserService = UserService;
        this.showReportPlayers = true;
        this.filters = [];
        this.showPublicReports = true;
        this.$reportResource = ScoutingReportResource;
        this.$paginator = Paginator;
        this.$aclService = ACLService;
        this.$templateResource = ScoutingTemplateResource;
        this.$scoutingService = ScoutingService;
        this.$scoutingFilterService = ScoutingFilterService;
        this.$labelsResource = ScoutingLabelsResource;
        this.$labelsService = ScoutingLabelsService;
    }
    ScoutingReportsListController.prototype.$onInit = function () {
        var _this = this;
        this.initPromise = this.$scoutingFilterService
            .queryChoices('organization', undefined, this.showPublicReports ? 1 : -1)
            .then(function (organizations) {
            _this.accessibleOrganizations = organizations;
            _this.assumeTemplatesExistence();
            _this.verifyTemplatesExistence();
            _this.initializeDefaultFilters();
            _this.update();
            _this.availableLabels = _this.$labelsResource.query();
        });
    };
    ScoutingReportsListController.prototype.createNewReport = function () {
        this.$scoutingService.requestReportCreation();
    };
    ScoutingReportsListController.prototype.addFilter = function (filter) {
        if (this.isTemporaryDisabledFilter(filter)) {
            this.showReportPlayers = true;
        }
        this.filters.push(filter);
        this.update();
    };
    Object.defineProperty(ScoutingReportsListController.prototype, "viewType", {
        get: function () {
            return this.showReportPlayers ? 'players' : 'reports';
        },
        enumerable: false,
        configurable: true
    });
    ScoutingReportsListController.prototype.update = function () {
        var _this = this;
        this.paginator = this.$paginator.getInstance({
            sortBy: _.get(this.paginator, 'paginationModel.sort', 'createdAt'),
            direction: _.get(this.paginator, 'paginationModel.direction', -1),
            limit: 15,
            pagingFunction: function (params) {
                var type = _this.viewType;
                var filters = _this.filters.filter(function (filter) { return !_this.isTemporaryDisabledFilter(filter); });
                return _this.$reportResource.query(Object.assign({}, __assign(__assign({}, params), { showPublic: _this.showPublicReports ? '1' : '-1' }), { type: type }), { filters: filters }).$promise;
            },
        });
    };
    ScoutingReportsListController.prototype.assumeTemplatesExistence = function () {
        this.isTemplatesExisting = true;
    };
    ScoutingReportsListController.prototype.verifyTemplatesExistence = function () {
        return __awaiter(this, void 0, void 0, function () {
            var templates, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4, this.$aclService.hasAccountPermissionsPromise('scouting-template-view')];
                    case 1:
                        _a.sent();
                        return [4, this.$templateResource.query().$promise];
                    case 2:
                        templates = _a.sent();
                        this.isTemplatesExisting = templates.length > 0;
                        return [3, 4];
                    case 3:
                        error_1 = _a.sent();
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    ScoutingReportsListController.prototype.viewReport = function (reportId, player) {
        this.$state.go('root.scouting.report', {
            id: reportId,
            player: player ? player._id || player.temporaryId : undefined,
        });
    };
    ScoutingReportsListController.prototype.initializeDefaultFilters = function () {
        var _this = this;
        this.filters = this.$scoutingFilterService
            .deserialize(this.preselect)
            .map(function (filter) {
            if (filter.field === 'organization') {
                if (_this.accessibleOrganizations.length <= 1) {
                    return null;
                }
                filter.choices = _this.accessibleOrganizations;
                filter.permanent = true;
            }
            return filter;
        })
            .filter(function (item) { return !!item; });
        if (Object.keys(this.filters).length === 0) {
            var user_1 = this.UserService.getUser();
            this.filters = __spreadArray(__spreadArray([], (this.accessibleOrganizations.length > 1
                ? [
                    {
                        criterion: 'organization',
                        field: 'organization',
                        negative: false,
                        permanent: true,
                        choices: this.accessibleOrganizations,
                        value: {
                            or: [this.accessibleOrganizations.find(function (item) { return item._id === user_1.account._id; })],
                        },
                    },
                ]
                : []), true), [
                {
                    criterion: 'player',
                    field: 'players.player',
                    negative: false,
                    value: {
                        or: [],
                    },
                },
            ], false);
        }
    };
    ScoutingReportsListController.prototype.isTemporaryDisabledFilter = function (filter) {
        if (this.showReportPlayers) {
            return false;
        }
        return filter.field.startsWith('players.') && filter.field !== 'players.team';
    };
    ScoutingReportsListController.prototype.labelHasNamePredicate = function () {
        return function (label) { return !!label.label; };
    };
    Object.defineProperty(ScoutingReportsListController.prototype, "isAllPlayersSelected", {
        get: function () {
            return this.paginator && this.paginator.data.every(function (item) { return item.$$selected; });
        },
        set: function (value) {
            if (value) {
                this.paginator.data.forEach(function (item) { return (item.$$selected = true); });
            }
            else {
                this.paginator.data.forEach(function (item) { return (item.$$selected = false); });
            }
        },
        enumerable: false,
        configurable: true
    });
    ScoutingReportsListController.prototype.togglePlayerSelection = function (playerId, selected) {
        this.paginator.data.forEach(function (item) {
            if (item.players.player._id === playerId) {
                item.$$selected = selected;
            }
        });
    };
    ScoutingReportsListController.prototype.getSelectedPlayers = function (reports) {
        var _this = this;
        return _.chain(reports)
            .filter(function (item) { return item.$$selected; })
            .map(function (item) { return _this.transformPlayerReportIntoPlayerLabels(item); })
            .uniqBy('_id')
            .value();
    };
    ScoutingReportsListController.prototype.applyLabelsChanges = function (changes) {
        var _this = this;
        this.promise = this.$labelsService.applyChanges(this.availableLabels, changes).then(function () {
            _this.promise = null;
            _this.paginator.reset();
        });
    };
    ScoutingReportsListController.prototype.compareReports = function () {
        var players = _.uniqBy(this.paginator.data.filter(function (item) { return item.$$selected; }).map(function (report) { return report.players.player; }), '_id');
        var selectedReports = this.paginator.data
            .filter(function (item) { return item.$$selected; })
            .map(function (report) {
            return __assign(__assign({}, report), { players: [report.players] });
        });
        this.$scoutingService.compareReports(players, selectedReports);
    };
    ScoutingReportsListController.prototype.transformPlayerReportIntoPlayerLabels = function (report) {
        return {
            _id: report.players.player._id,
            player: report.players.player,
            labels: report.labels,
        };
    };
    ScoutingReportsListController.prototype.showCreateLabelModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modal, result, reason_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        modal = {
                            template: '<modal-department-create-label modal-instance="$ctrl.modalInstance">',
                            controllerAs: '$ctrl',
                            backdrop: true,
                            controller: [
                                '$uibModalInstance',
                                function ($uibModalInstance) {
                                    this.modalInstance = $uibModalInstance;
                                },
                            ],
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.$uibModal.open(modal).result];
                    case 2:
                        result = _a.sent();
                        this.availableLabels.push(result);
                        return [3, 4];
                    case 3:
                        reason_1 = _a.sent();
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    return ScoutingReportsListController;
}());
angular.module('app.scouting').component('scoutingReportsList', {
    templateUrl: 'scouting/components/reports-list.html',
    controller: ScoutingReportsListController,
    bindings: {
        preselect: '<',
    },
});
;