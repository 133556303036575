class CommentsBoardAddColumn {
  private modalInstance;
  private scoutingSheet;

  private availableTemplates;
  private selectedTemplate;
  private columnName;
  private $promise;

  constructor(
    private ScoutingTemplateResource,
    private ScoutingService,
  ) {}

  private $onInit() {
    this.$promise = this.ScoutingTemplateResource.query({
      type: 'note,memo',
      skip: 0,
      limit: 1000,
    }).$promise.then((availableTemplates) => {
      this.availableTemplates = availableTemplates
        // filter out the template that do not has text_field element
        .filter((tmpl) => (tmpl.elements || []).findIndex((el) => el.type === 'text_field') > -1)
        // filter out the templates that are already added as column
        .filter((tmpl) => !this.scoutingSheet.templates.some((t) => t._id === tmpl._id));
    });
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  proceed() {
    this.$promise = this.addColumn();
  }

  private async addColumn() {
    const payload: any = {};

    if (this.selectedTemplate) {
      payload.template = {
        _id: this.selectedTemplate._id,
        name: this.selectedTemplate.name,
      };
    } else if (this.columnName) {
      payload.columnName = this.columnName;
    }

    this.modalInstance.close(
      await this.ScoutingService.addColumnToScoutingSheet(this.scoutingSheet._id, payload),
    );
  }

  private handleTemplateSelect() {
    if (this.selectedTemplate) {
      this.columnName = '';
    }
  }

  private handleColumnNameChange() {
    if (this.columnName) {
      this.selectedTemplate = null;
    }
  }
}

angular.module('app.scouting').component('commentsBoardAddColumn', {
  templateUrl: 'scouting/components/modals/comments-board-add-column.html',
  controller: CommentsBoardAddColumn,
  bindings: {
    modalInstance: '<',
    scoutingSheet: '<',
  },
});
