(function () {
  'use strict';

  class ScoutingReportTextFieldController {
    constructor(private $scope) {}

    notifyAboutChanges() {
      this.$scope.$emit('scouting:element:changed');
    }
  }

  angular.module('app.scouting').component('scoutingReportFieldText', {
    templateUrl: 'scouting/components/field/text.html',
    controller: ScoutingReportTextFieldController,
    bindings: {params: '<', value: '=', editable: '<', selectedPlayer: '<', report: '<'},
  });
})();
