import {PlayerRecord} from '@app/js/player/services/PlayerService';
import {BasicUserRecord} from '@app/js/organisation/services/AgencyOrganizationService';

class ScoutingDepartmentPlayerContactController {
  private player: PlayerRecord;
  private user: BasicUserRecord;

  constructor() {}
}

angular.module('app.scouting').component('scoutingDepartmentPlayerContact', {
  templateUrl: 'scouting/components/department-player-contact.html',
  controller: ScoutingDepartmentPlayerContactController,

  bindings: {
    player: '<',
    user: '<',
  },
});
