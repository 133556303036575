var AVAILABLE_OPERATORS = {
    isExact: {
        type: 'is_exact',
        negative: false,
    },
    isOneOf: {
        type: 'is_one_of',
        negative: false,
    },
    isNotOneOf: {
        type: 'is_one_of',
        negative: true,
    },
    shouldContain: {
        type: 'contains',
        negative: false,
    },
    shouldNotContain: {
        type: 'contains',
        negative: true,
    },
    isGreaterThan: {
        type: 'is_greater_than',
        negative: false,
    },
    isLessThan: {
        type: 'is_less_than',
        negative: false,
    },
    isBetween: {
        type: 'is_between',
        negative: false,
    },
};
var ScoutingFilterItemController = (function () {
    function ScoutingFilterItemController(UserService, ScoutingFilterService) {
        this.$scoutingFilterService = ScoutingFilterService;
        this.dateInputFormat = UserService.getUser().settings.dateformat;
    }
    ScoutingFilterItemController.prototype.$onInit = function () {
        var _this = this;
        this.inner = {
            criterion: this.$scoutingFilterService.availableCriteria.find(function (item) { return item.name === _this.instance.criterion; }),
        };
        if (this.instance.value) {
            if (this.instance.value.query) {
                this.operator = !this.instance.negative
                    ? AVAILABLE_OPERATORS.shouldContain
                    : AVAILABLE_OPERATORS.shouldNotContain;
            }
            if (this.instance.value.or) {
                this.operator = !this.instance.negative
                    ? AVAILABLE_OPERATORS.isOneOf
                    : AVAILABLE_OPERATORS.isNotOneOf;
            }
            if (this.instance.value.exact) {
                this.operator = AVAILABLE_OPERATORS.isExact;
            }
            if (this.instance.value.range &&
                this.instance.value.range.gt &&
                !this.instance.value.range.lt) {
                this.instance.value.range.gt = new Date(this.instance.value.range.gt);
                this.operator = AVAILABLE_OPERATORS.isGreaterThan;
            }
            if (this.instance.value.range &&
                this.instance.value.range.lt &&
                !this.instance.value.range.gt) {
                this.instance.value.range.lt = new Date(this.instance.value.range.lt);
                this.operator = AVAILABLE_OPERATORS.isLessThan;
            }
        }
        if (!this.operator && this.inner.criterion.features.exactSearch) {
            this.operator = AVAILABLE_OPERATORS.isOneOf;
        }
    };
    Object.defineProperty(ScoutingFilterItemController.prototype, "availableOperators", {
        get: function () {
            return AVAILABLE_OPERATORS;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScoutingFilterItemController.prototype, "enabled", {
        get: function () {
            return !this.instance.disabled;
        },
        set: function (value) {
            this.instance.disabled = !value;
            this.onChange();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScoutingFilterItemController.prototype, "operator", {
        get: function () {
            return this.inner.operator;
        },
        set: function (operator) {
            if (this.inner.operator && operator.type !== this.inner.operator.type) {
                this.instance.value = {};
            }
            if (operator.type === 'is_one_of' && (!this.instance.value || !this.instance.value.or)) {
                this.instance.value = { or: [] };
            }
            this.inner.operator = operator;
            this.instance.negative = operator.negative;
            this.onChange();
        },
        enumerable: false,
        configurable: true
    });
    ScoutingFilterItemController.prototype.delete = function () {
        var index = this.filters.indexOf(this.instance);
        if (index >= 0) {
            this.filters.splice(index, 1);
        }
        if (!this.instance.disabled) {
            this.onChange();
        }
    };
    Object.defineProperty(ScoutingFilterItemController.prototype, "previousFilters", {
        get: function () {
            var thisFilterIdx = this.filters.indexOf(this.instance);
            return this.filters.slice(0, thisFilterIdx);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScoutingFilterItemController.prototype, "isTemporaryDisabled", {
        get: function () {
            if (this.viewType === 'players') {
                return false;
            }
            return this.instance.field.startsWith('players.') && this.instance.field !== 'players.team';
        },
        enumerable: false,
        configurable: true
    });
    return ScoutingFilterItemController;
}());
angular.module('app.scouting').component('scoutingFilterItem', {
    templateUrl: 'scouting/components/filters/filter-item.html',
    controller: ScoutingFilterItemController,
    bindings: {
        instance: '<',
        filters: '<',
        viewType: '<',
        showPublic: '<',
        onChange: '&',
    },
});
;