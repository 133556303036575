class DepartmenAddPlayerModalController {
  private $labelsResource;
  private $uibModal;
  private player;

  private labels: any[];
  private selected: any[];
  private promise: Promise<any>;
  private newUnnamedLabel: any;

  constructor(
    private $q,
    ScoutingLabelsResource,
  ) {
    this.$labelsResource = ScoutingLabelsResource;
    this.newUnnamedLabel = {};
  }

  $onInit() {
    this.selected = [];
    this.labels = this.$labelsResource.query();

    this.promise = (this.labels as any).$promise.then((labels) => {
      const unnamedLabel = labels.find((label) => !label.label);
      if (!unnamedLabel) {
        this.labels = [this.newUnnamedLabel, ...this.labels];
      } else {
        this.labels = [unnamedLabel, ...this.labels.filter((label) => !!label.label)];
      }
      this.selected =
        this.player.labels && this.player.labels.length
          ? labels.filter((item) => this.player.labels.some((label) => label._id === item._id))
          : [unnamedLabel ? unnamedLabel : this.newUnnamedLabel];
    });
  }

  cancel() {
    this.$uibModal.dismiss();
  }
  confirm() {
    this.promise = this.save().then(() => this.$uibModal.close());
  }

  hasNamePredicate() {
    return (label) => !!label.label;
  }

  isSelected(label: any) {
    return (this.selected || []).some((selection) => selection === label);
  }

  toggleLabel($event: any, label: any) {
    if (this.isSelected(label)) {
      this.selected = this.selected.filter((item) => item !== label);
    } else {
      this.selected = [...this.selected, label];
    }
  }

  get applyButtonDisabled() {
    return (
      (!this.selected || !this.selected.length) &&
      (!this.player.labels || !this.player.labels.length)
    );
  }

  async save() {
    const selections = this.selected || [];
    const originalSelections = this.player.labels || [];

    const labelsToAdd = _.differenceBy(selections, originalSelections, '_id');
    const labelsToRemove = _.differenceBy(originalSelections, selections, '_id');

    const player = _.pick(this.player, ['_id', 'firstName', 'lastName']);

    await this.$q.all(
      labelsToAdd.map(
        (addition: any) =>
          this.$labelsResource.addPlayers({id: addition._id}, {players: [player]}).$promise,
      ),
    );

    await this.$q.all(
      labelsToRemove.map(
        (removal: any) =>
          this.$labelsResource.removePlayers({id: removal._id}, {players: [player]}).$promise,
      ),
    );
  }
}

angular.module('app.scouting').component('modalDepartmentAddPlayer', {
  templateUrl: 'scouting/components/modals/department-add-player.html',
  controller: DepartmenAddPlayerModalController,
  bindings: {
    $uibModal: '<modalInstance',
    player: '<',
  },
});
