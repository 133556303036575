var ScoutingPlayerLineupOverviewController = (function () {
    function ScoutingPlayerLineupOverviewController($scope) {
        this.$scope = $scope;
    }
    ScoutingPlayerLineupOverviewController.prototype.$onChanges = function (changes) {
        var report = this.report || _.get(changes.report, 'currentValue');
        var player = _.get(changes.player, 'currentValue');
        if (player) {
            this.teamKind = null;
            if (report.game && report.game.home._id === player.team) {
                this.teamKind = 'home';
            }
            else if (report.game && report.game.away._id === player.team) {
                this.teamKind = 'away';
            }
        }
        this.update();
    };
    Object.defineProperty(ScoutingPlayerLineupOverviewController.prototype, "lineup", {
        get: function () {
            if (!this.report || !this.report.game || !this.teamKind) {
                return null;
            }
            return this.teamKind ? this.report.game["".concat(this.teamKind, "_lineup")] : null;
        },
        enumerable: false,
        configurable: true
    });
    ScoutingPlayerLineupOverviewController.prototype.update = function () {
        var _this = this;
        this.lines = [];
        if (this.lineup) {
            ['power', 'box', 'even'].forEach(function (type) {
                var availableLines = (_this.lineup[type] || []).map(function (item) {
                    return {
                        name: type,
                        scheme: item.scheme.they && item.scheme.we ? "".concat(item.scheme.we, "-").concat(item.scheme.they) : null,
                        position: _this.findLineupPosition(item, _this.player.player._id),
                    };
                });
                _this.lines = _this.lines.concat(availableLines.filter(function (item) { return !!item.position; }));
            });
            ['lines'].forEach(function (type) {
                var availableLines = (_this.lineup[type] || []).map(function (item, index) {
                    return {
                        name: 'line',
                        scheme: index,
                        position: _this.findLineupPosition(item, _this.player.player._id, _this.gameReportLineups && _this.gameReportLineups[_this.teamKind][type]
                            ? _this.gameReportLineups[_this.teamKind][type][index]
                            : undefined),
                    };
                });
                _this.lines = _this.lines.concat(availableLines.filter(function (item) { return !!item.position; }));
            });
            ['goalies'].forEach(function (type) {
                var availableLines = [
                    {
                        name: type,
                        position: _this.findLineupPosition(_this.lineup[type], _this.player.player._id),
                    },
                ];
                _this.lines = _this.lines.concat(availableLines.filter(function (item) { return !!item.position; }));
            });
            this.lines = _.uniqBy(this.lines, 'name');
            this.lines = _.sortBy(this.lines, function (item) {
                switch (item.name) {
                    case 'goalies':
                        return 1;
                    case 'line':
                        return 2;
                    case 'power':
                        return 3;
                    case 'box':
                        return 4;
                    case 'even':
                        return 5;
                    default:
                        return 0;
                }
            });
        }
    };
    ScoutingPlayerLineupOverviewController.prototype.findLineupPosition = function (lineup, playerId, gameReportLineup) {
        var lineupValue = _.chain(lineup)
            .pickBy(function (item) { return item === playerId; })
            .keys()
            .first()
            .value() || undefined;
        if (lineupValue !== undefined) {
            return lineupValue;
        }
        var lineValueInGameReport = _.chain(gameReportLineup)
            .pickBy(function (item) { return item === playerId; })
            .keys()
            .first()
            .value() || undefined;
        if (lineValueInGameReport && lineup[lineValueInGameReport] !== null) {
            return lineValueInGameReport;
        }
        return null;
    };
    return ScoutingPlayerLineupOverviewController;
}());
angular.module('app.scouting').component('scoutingPlayerLineupOverview', {
    templateUrl: 'scouting/components/general/player-lineup-overview.html',
    controller: ScoutingPlayerLineupOverviewController,
    bindings: {
        player: '<',
        report: '<',
        gameReportLineups: '<',
    },
});
;