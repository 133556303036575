var PlayerCollectionsService = (function () {
    function PlayerCollectionsService(SCConfiguration, $http) {
        this.$http = $http;
        this.getPlayerCollectionsEndpoint = SCConfiguration.getEndpoint() + '/api/player-collection';
    }
    PlayerCollectionsService.prototype.fetchPlayerCollections = function () {
        return this.$http.get(this.getPlayerCollectionsEndpoint).then(function (response) { return response.data; });
    };
    PlayerCollectionsService.prototype.fetchPlayerValues = function (collectionId, listId, _a) {
        var skip = _a.skip, limit = _a.limit;
        var endPoint = "".concat(this.getPlayerCollectionsEndpoint, "/").concat(collectionId, "/list/").concat(listId, "/playerValues?skip=").concat(skip, "&limit=").concat(limit);
        return this.$http.get(endPoint).then(function (response) { return response.data; });
    };
    return PlayerCollectionsService;
}());
angular.module('app.scouting').service('PlayerCollectionsService', PlayerCollectionsService);