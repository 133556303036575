(function () {
  'use strict';

  class ScoutingReportDatepickerFieldController {
    private value;
    private dateInputFormat;

    constructor(
      UserService,
      private $scope,
    ) {
      this.dateInputFormat = UserService.getUser().settings.dateformat;
    }

    $onInit() {
      this.$scope.$watch(
        () => this.value,
        () => {
          if (this.value && !(this.value instanceof Date)) {
            this.value = new Date(this.value);
          }
        },
      );
    }

    notifyAboutChanges() {
      this.$scope.$emit('scouting:element:changed');
    }
  }

  angular.module('app.scouting').component('scoutingReportFieldDatepicker', {
    templateUrl: 'scouting/components/field/datepicker.html',
    controller: ScoutingReportDatepickerFieldController,
    bindings: {params: '<', value: '=', editable: '<'},
  });
})();
