var ScoutingReportAttachedPlaylistController = (function () {
    function ScoutingReportAttachedPlaylistController(ScoutingReportResource, GameService, $uibModal, $scope, PlaylistService) {
        this.GameService = GameService;
        this.$uibModal = $uibModal;
        this.$scope = $scope;
        this.PlaylistService = PlaylistService;
        this.$reportResource = ScoutingReportResource;
    }
    ScoutingReportAttachedPlaylistController.prototype.$onChanges = function (changes) {
        var _this = this;
        var changesPlayer = _.get(changes, 'selectedPlayer.currentValue');
        if (changesPlayer) {
            this.lists = null;
            if (changesPlayer.attachedPlaylists && changesPlayer.attachedPlaylists.length) {
                this.ts = Date.now();
                this.fetchLists(this.ts, changesPlayer).then(function (_a) {
                    var ts = _a[0], lists = _a[1];
                    if (ts !== _this.ts) {
                        return;
                    }
                    _this.lists = lists;
                });
            }
        }
    };
    ScoutingReportAttachedPlaylistController.prototype.fetchLists = function (ts, player) {
        return this.PlaylistService.fetchScoutingReportPlaylists(this.report._id, (player.attachedPlaylists || []).join(',')).then(function (list) { return [ts, list]; });
    };
    ScoutingReportAttachedPlaylistController.prototype.playPlaylist = function (list) {
        this.GameService.openNextGenScoutingPlaylist(this.report._id, list.id);
    };
    ScoutingReportAttachedPlaylistController.prototype.createPlayerComparator = function () {
        var _this = this;
        return function (list) {
            return (_this.selectedPlayer && (!list.player || list.player._id === _this.selectedPlayer.player._id));
        };
    };
    ScoutingReportAttachedPlaylistController.prototype.notifyAboutChanges = function () {
        this.$scope.$emit('scouting:element:changed');
    };
    ScoutingReportAttachedPlaylistController.prototype.attachPlaylist = function () {
        var _this = this;
        this.$uibModal
            .open({
            size: 'md',
            template: '<playlist-picker-modal modal-instance="$ctrl.modalInstance" multiple="true" />',
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    this.modalInstance = $uibModalInstance;
                },
            ],
            controllerAs: '$ctrl',
        })
            .result.then(function (selectedPlaylists) {
            if (selectedPlaylists.length) {
                if (!_this.selectedPlayer.attachedPlaylists) {
                    _this.selectedPlayer.attachedPlaylists = [];
                }
                selectedPlaylists.forEach(function (p) {
                    var playlistExisted = _this.selectedPlayer.attachedPlaylists.some(function (attachedPlaylistId) { return attachedPlaylistId === p.id; });
                    if (!playlistExisted) {
                        _this.selectedPlayer.attachedPlaylists.push(p.id);
                        _this.lists = (_this.lists || []).concat(p);
                        _this.notifyAboutChanges();
                    }
                });
            }
        });
    };
    ScoutingReportAttachedPlaylistController.prototype.getCreatorName = function (playlist) {
        return playlist.createdBy.firstName + ' ' + playlist.createdBy.lastName;
    };
    ScoutingReportAttachedPlaylistController.prototype.getFriendlyCreatedAt = function (playlist) {
        return moment(playlist.createdAt).fromNow();
    };
    ScoutingReportAttachedPlaylistController.prototype.getClipsLength = function (playlist) {
        return (Object.keys(playlist.clipIndex) || []).length;
    };
    ScoutingReportAttachedPlaylistController.prototype.removePlaylist = function (p) {
        this.selectedPlayer.attachedPlaylists = this.selectedPlayer.attachedPlaylists.filter(function (id) { return id !== p.id; });
        this.lists = (this.lists || []).filter(function (playlist) { return playlist.id !== p.id; });
        this.notifyAboutChanges();
    };
    return ScoutingReportAttachedPlaylistController;
}());
angular.module('app.scouting').component('scoutingReportAttachedPlaylists', {
    templateUrl: 'scouting/components/report/attached-playlists.html',
    controller: ScoutingReportAttachedPlaylistController,
    bindings: {
        report: '<',
        selectedPlayer: '<',
        editable: '<',
    },
});