var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ScoutingReportElementsController = (function () {
    function ScoutingReportElementsController($scope) {
        this.$scope = $scope;
    }
    Object.defineProperty(ScoutingReportElementsController.prototype, "currentElements", {
        get: function () {
            return this.elements || this.report.template.elements;
        },
        enumerable: false,
        configurable: true
    });
    ScoutingReportElementsController.prototype.getHolder = function (wrapper, type, id) {
        if (this.idsPrefix) {
            id = "".concat(this.idsPrefix, ".").concat(id);
        }
        var holder = wrapper.values && wrapper.values.find(function (holder) { return holder.type === type && holder.id === id; });
        return holder || this.createHolder(wrapper, type, id);
    };
    ScoutingReportElementsController.prototype.createHolder = function (wrapper, type, id) {
        return { type: type, id: id };
    };
    __decorate([
        CacheReturnValue(function (context) { return context.$scope; }),
        OnReturnObjectChangedOnce(function (context) { return context.$scope; }, function (context, holder, _a) {
            var wrapper = _a[0];
            wrapper.values = wrapper.values || [];
            wrapper.values.push(holder);
        })
    ], ScoutingReportElementsController.prototype, "createHolder", null);
    return ScoutingReportElementsController;
}());
angular.module('app.scouting').component('scoutingReportElements', {
    templateUrl: 'scouting/components/report/elements.html',
    controller: ScoutingReportElementsController,
    bindings: {
        report: '<',
        elements: '<',
        idsPrefix: '<',
        editable: '<',
        compact: '<',
        selectedPlayer: '<',
    },
});