var ScoutingReportControlsController = (function () {
    function ScoutingReportControlsController($state, $uibModal, UserService, ScoutingService, History, $scope, ACLService) {
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.UserService = UserService;
        this.ScoutingService = ScoutingService;
        this.$scope = $scope;
        this.ACLService = ACLService;
        this.$historyService = History;
    }
    ScoutingReportControlsController.prototype.$onInit = function () {
        this.user = this.UserService.getUser();
    };
    ScoutingReportControlsController.prototype.onCompletedChanged = function () {
        this.ScoutingService.triggerChangedReportSave(this.report);
        if (this.report.completed) {
            this.editable = false;
        }
    };
    ScoutingReportControlsController.prototype.edit = function () {
        this.editable = true;
        if (this.report.completed) {
            this.report.completed = false;
            this.ScoutingService.triggerChangedReportSave(this.report);
        }
    };
    ScoutingReportControlsController.prototype.delete = function () {
        var _this = this;
        var modalInstance = this.$uibModal.open({
            size: 'sm',
            template: '<modal-report-delete modal-instance="$ctrl.modalInstance">',
            controllerAs: '$ctrl',
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    this.modalInstance = $uibModalInstance;
                },
            ],
        });
        modalInstance.result
            .then(function () { return (_this.report.$promise = _this.report.$delete()); })
            .then(function () { return _this.$state.go('root.scouting.player_reports', null, { location: 'replace' }); });
    };
    ScoutingReportControlsController.prototype.close = function () {
        if (window.history.length) {
            window.history.back();
        }
        else {
            this.$state.go('root.scouting.player_reports', null);
        }
    };
    ScoutingReportControlsController.prototype.togglePublicReport = function () {
        this.report.isPublic = !this.report.isPublic;
        this.ScoutingService.triggerChangedReportSave(this.report);
    };
    Object.defineProperty(ScoutingReportControlsController.prototype, "canEditReport", {
        get: function () {
            if (this.report.organization.id !== this.user.account._id) {
                return false;
            }
            return (this.ACLService.hasAccountPermissions(['organization-admin-access']) ||
                this.report.createdBy.userId === this.user._id);
        },
        enumerable: false,
        configurable: true
    });
    return ScoutingReportControlsController;
}());
angular.module('app.scouting').component('scoutingReportControls', {
    templateUrl: 'scouting/components/report/controls.html',
    controller: ScoutingReportControlsController,
    bindings: {
        report: '<',
        editable: '=',
    },
});