class DepartmentEditLabelModalController {
  private $uibModal;
  private label;
  private user;
  private organizations;

  private promise;

  constructor(
    private UserService,
    private OrganizationService,
    private $q,
  ) {
    this.promise = this.$q.resolve();
  }

  $onInit() {
    this.user = this.UserService.getUser();
    this.promise = this.OrganizationService.listHierarchyOrganizations().then((organizations) => {
      this.organizations = organizations;
    });
  }

  handleVisiblityChange() {
    this.label.label.shareWith = [];
  }

  cancel() {
    this.$uibModal.dismiss();
  }

  proceed() {
    this.promise = this.updateLabelName();
  }

  delete() {
    this.promise = this.deleteLabel();
  }

  private async updateLabelName() {
    this.$uibModal.close(await this.label.$updateDetails());
  }

  private async deleteLabel() {
    await this.label.$delete();

    this.$uibModal.close(null);
  }

  private selectShareWithUsers(users) {
    this.label.label.shareWith = users;
  }
}

angular.module('app.scouting').component('modalDepartmentEditLabel', {
  templateUrl: 'scouting/components/modals/department-edit-label.html',
  controller: DepartmentEditLabelModalController,
  bindings: {
    $uibModal: '<modalInstance',
    label: '<',
  },
});
