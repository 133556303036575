angular.module('app.scouting').component('lineupDirectLayout', {
  templateUrl: 'scouting/components/general/lineup-direct-layout.html',
  bindings: {
    size: '@',
    team: '<',
    holder: '<',
    players: '<',
    readonly: '<',
  },
});
