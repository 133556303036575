class ScoutingReportAttachedPlaylistController {
  private $reportResource;
  private ts;

  private report;
  private selectedPlayer;

  private lists;
  private editable;

  constructor(
    ScoutingReportResource,
    private GameService,
    private $uibModal,
    private $scope,
    private PlaylistService,
  ) {
    this.$reportResource = ScoutingReportResource;
  }

  $onChanges(changes) {
    const changesPlayer = _.get(changes, 'selectedPlayer.currentValue');

    if (changesPlayer) {
      this.lists = null;
      if (changesPlayer.attachedPlaylists && changesPlayer.attachedPlaylists.length) {
        this.ts = Date.now();
        this.fetchLists(this.ts, changesPlayer).then(([ts, lists]) => {
          // if the timestamp for this fetch doesn't match the timestamp on this component
          // we just silently ignore the fetch result
          if (ts !== this.ts) {
            return;
          }
          this.lists = lists;
        });
      }
    }
  }

  fetchLists(ts, player) {
    return this.PlaylistService.fetchScoutingReportPlaylists(
      this.report._id,
      (player.attachedPlaylists || []).join(','),
    ).then((list) => [ts, list]);
  }

  playPlaylist(list) {
    this.GameService.openNextGenScoutingPlaylist(this.report._id, list.id);
  }

  createPlayerComparator() {
    return (list) => {
      return (
        this.selectedPlayer && (!list.player || list.player._id === this.selectedPlayer.player._id)
      );
    };
  }

  notifyAboutChanges() {
    this.$scope.$emit('scouting:element:changed');
  }

  attachPlaylist() {
    this.$uibModal
      .open({
        size: 'md',
        template: '<playlist-picker-modal modal-instance="$ctrl.modalInstance" multiple="true" />',
        controller: [
          '$uibModalInstance',
          function ($uibModalInstance) {
            this.modalInstance = $uibModalInstance;
          },
        ],
        controllerAs: '$ctrl',
      })
      .result.then((selectedPlaylists) => {
        if (selectedPlaylists.length) {
          if (!this.selectedPlayer.attachedPlaylists) {
            this.selectedPlayer.attachedPlaylists = [];
          }
          selectedPlaylists.forEach((p) => {
            const playlistExisted = this.selectedPlayer.attachedPlaylists.some(
              (attachedPlaylistId) => attachedPlaylistId === p.id,
            );
            if (!playlistExisted) {
              this.selectedPlayer.attachedPlaylists.push(p.id);
              this.lists = (this.lists || []).concat(p);
              this.notifyAboutChanges();
            }
          });
        }
      });
  }

  getCreatorName(playlist) {
    return playlist.createdBy.firstName + ' ' + playlist.createdBy.lastName;
  }

  getFriendlyCreatedAt(playlist) {
    return moment(playlist.createdAt).fromNow();
  }

  getClipsLength(playlist) {
    return (Object.keys(playlist.clipIndex) || []).length;
  }

  removePlaylist(p) {
    this.selectedPlayer.attachedPlaylists = this.selectedPlayer.attachedPlaylists.filter(
      (id) => id !== p.id,
    );
    this.lists = (this.lists || []).filter((playlist) => playlist.id !== p.id);
    this.notifyAboutChanges();
  }
}

angular.module('app.scouting').component('scoutingReportAttachedPlaylists', {
  templateUrl: 'scouting/components/report/attached-playlists.html',
  controller: ScoutingReportAttachedPlaylistController,
  bindings: {
    report: '<',
    selectedPlayer: '<',
    editable: '<',
  },
});
