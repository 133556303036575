var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var ScoutingDepartmentLabelsDropdownController = (function () {
    function ScoutingDepartmentLabelsDropdownController() {
    }
    ScoutingDepartmentLabelsDropdownController.prototype.createAvailableActions = function () {
        var _this = this;
        if (!this.players.length || !this.availableLabels.length) {
            return (this.actions = null);
        }
        var normalLabels = this.availableLabels.filter(function (label) { return !!label.label; });
        var unamedLabel = this.availableLabels.find(function (label) { return !label.label; });
        this.actions = __spreadArray([unamedLabel], normalLabels, true).map(function (label) {
            var initial = null;
            if (_this.players.every(function (item) { return !!item.labels.find(function (inner) { return inner._id === label._id; }); })) {
                initial = true;
            }
            else if (_this.players.every(function (item) { return !item.labels.find(function (inner) { return inner._id === label._id; }); })) {
                initial = false;
            }
            return {
                initial: initial,
                desired: initial,
                label: label,
            };
        });
    };
    ScoutingDepartmentLabelsDropdownController.prototype.changeAction = function (event, action) {
        switch (action.desired) {
            case null:
                action.desired = true;
                break;
            case true:
                action.desired = false;
                break;
            case false:
                action.desired = action.initial === null ? null : true;
                break;
        }
        event.preventDefault();
    };
    Object.defineProperty(ScoutingDepartmentLabelsDropdownController.prototype, "isApplyPossible", {
        get: function () {
            return (this.actions || []).some(function (item) { return item.desired !== item.initial; });
        },
        enumerable: false,
        configurable: true
    });
    ScoutingDepartmentLabelsDropdownController.prototype.apply = function () {
        var changes = [];
        for (var _i = 0, _a = this.actions.filter(function (item) { return item.desired !== item.initial; }); _i < _a.length; _i++) {
            var action = _a[_i];
            switch (action.desired) {
                case true:
                    changes.push.apply(changes, this.findPlayersForLabelAdd(action.label._id));
                    break;
                case false:
                    changes.push.apply(changes, this.findPlayersForLabelRemove(action.label._id));
                    break;
            }
        }
        this.onChange({ $changes: changes });
    };
    ScoutingDepartmentLabelsDropdownController.prototype.findPlayersForLabelAdd = function (needle) {
        return this.players
            .filter(function (item) { return item.labels.every(function (label) { return label._id !== needle; }); })
            .map(function (item) {
            return {
                type: 'add',
                player: _.pick(item.player, ['_id', 'firstName', 'lastName']),
                label: needle,
            };
        });
    };
    ScoutingDepartmentLabelsDropdownController.prototype.findPlayersForLabelRemove = function (needle) {
        return this.players
            .filter(function (item) { return item.labels.some(function (label) { return label._id === needle; }); })
            .map(function (item) {
            return {
                type: 'remove',
                player: _.pick(item.player, ['_id', 'firstName', 'lastName']),
                label: needle,
            };
        });
    };
    return ScoutingDepartmentLabelsDropdownController;
}());
angular.module('app.scouting').component('scoutingDepartmentLabelsDropdown', {
    templateUrl: 'scouting/components/department-labels-dropdown.html',
    controller: ScoutingDepartmentLabelsDropdownController,
    bindings: {
        players: '<',
        availableLabels: '<',
        onChange: '&',
        onCreateLabel: '&',
    },
});