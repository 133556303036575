import {Filter, FilterCriterion} from '../services/ScoutingFilterService';

class ScoutingReportsListController {
  private $reportResource;
  private $templateResource;
  private $aclService;
  private $scoutingService;
  private $scoutingFilterService;
  private $paginator;
  private $labelsResource;
  private $labelsService;

  private reports;
  private paginator;
  private isTemplatesExisting;

  private showReportPlayers = true;

  private initPromise: Promise<any>;
  private accessibleOrganizations;

  private promise: Promise<any>;
  private availableLabels: Label[];
  private filters: Filter[] = [];
  private preselect: any;

  private showPublicReports = true;

  constructor(
    private $scope,
    private $state,
    private $uibModal,
    private UserService,
    ScoutingReportResource,
    ACLService,
    ScoutingTemplateResource,
    Paginator,
    ScoutingService,
    ScoutingFilterService,
    ScoutingLabelsResource,
    ScoutingLabelsService,
  ) {
    this.$reportResource = ScoutingReportResource;
    this.$paginator = Paginator;
    this.$aclService = ACLService;
    this.$templateResource = ScoutingTemplateResource;
    this.$scoutingService = ScoutingService;
    this.$scoutingFilterService = ScoutingFilterService;
    this.$labelsResource = ScoutingLabelsResource;
    this.$labelsService = ScoutingLabelsService;
  }

  public $onInit() {
    this.initPromise = this.$scoutingFilterService
      .queryChoices('organization', undefined, this.showPublicReports ? 1 : -1)
      .then((organizations) => {
        this.accessibleOrganizations = organizations;

        this.assumeTemplatesExistence();
        this.verifyTemplatesExistence();

        this.initializeDefaultFilters();
        this.update();

        this.availableLabels = this.$labelsResource.query();
      });
  }

  public createNewReport() {
    this.$scoutingService.requestReportCreation();
  }

  public addFilter(filter: Filter) {
    if (this.isTemporaryDisabledFilter(filter)) {
      this.showReportPlayers = true;
    }

    this.filters.push(filter);
    this.update();
  }

  get viewType() {
    return this.showReportPlayers ? 'players' : 'reports';
  }

  public update() {
    this.paginator = this.$paginator.getInstance({
      sortBy: _.get(this.paginator, 'paginationModel.sort', 'createdAt'),
      direction: _.get(this.paginator, 'paginationModel.direction', -1),
      limit: 15,

      pagingFunction: (params) => {
        const type = this.viewType;
        const filters = this.filters.filter((filter) => !this.isTemporaryDisabledFilter(filter));

        return this.$reportResource.query(
          Object.assign({}, {...params, showPublic: this.showPublicReports ? '1' : '-1'}, {type}),
          {filters},
        ).$promise;
      },
    });
  }

  private assumeTemplatesExistence() {
    this.isTemplatesExisting = true;
  }
  private async verifyTemplatesExistence() {
    try {
      await this.$aclService.hasAccountPermissionsPromise('scouting-template-view');

      const templates = await this.$templateResource.query().$promise;
      this.isTemplatesExisting = templates.length > 0;
    } catch (error) {
      //
    }
  }

  public viewReport(reportId, player) {
    this.$state.go('root.scouting.report', {
      id: reportId,
      player: player ? player._id || player.temporaryId : undefined,
    });
  }

  public initializeDefaultFilters() {
    this.filters = this.$scoutingFilterService
      .deserialize(this.preselect)
      .map((filter) => {
        if (filter.field === 'organization') {
          if (this.accessibleOrganizations.length <= 1) {
            return null;
          }

          filter.choices = this.accessibleOrganizations;
          filter.permanent = true;
        }
        return filter;
      })
      .filter((item) => !!item);

    if (Object.keys(this.filters).length === 0) {
      const user = this.UserService.getUser();

      this.filters = [
        ...(this.accessibleOrganizations.length > 1
          ? [
              {
                criterion: 'organization',
                field: 'organization',
                negative: false,
                permanent: true,
                choices: this.accessibleOrganizations,
                value: {
                  or: [this.accessibleOrganizations.find((item) => item._id === user.account._id)],
                },
              },
            ]
          : []),
        {
          criterion: 'player',
          field: 'players.player',
          negative: false,
          value: {
            or: [],
          },
        },
      ];
    }
  }

  public isTemporaryDisabledFilter(filter: Filter) {
    if (this.showReportPlayers) {
      return false;
    }

    return filter.field.startsWith('players.') && filter.field !== 'players.team';
  }

  public labelHasNamePredicate() {
    return (label) => !!label.label;
  }

  get isAllPlayersSelected() {
    return this.paginator && this.paginator.data.every((item) => item.$$selected);
  }

  set isAllPlayersSelected(value: boolean) {
    if (value) {
      this.paginator.data.forEach((item) => (item.$$selected = true));
    } else {
      this.paginator.data.forEach((item) => (item.$$selected = false));
    }
  }

  public togglePlayerSelection(playerId: string, selected: boolean) {
    this.paginator.data.forEach((item) => {
      if (item.players.player._id === playerId) {
        item.$$selected = selected;
      }
    });
  }

  public getSelectedPlayers(reports: any[]) {
    return _.chain(reports)
      .filter((item) => item.$$selected)
      .map((item) => this.transformPlayerReportIntoPlayerLabels(item))
      .uniqBy('_id')
      .value();
  }

  public applyLabelsChanges(changes: LabelPlayersChange[]) {
    this.promise = this.$labelsService.applyChanges(this.availableLabels, changes).then(() => {
      this.promise = null;
      this.paginator.reset();
    });
  }

  public compareReports() {
    const players = _.uniqBy(
      this.paginator.data.filter((item) => item.$$selected).map((report) => report.players.player),
      '_id',
    );

    const selectedReports = this.paginator.data
      .filter((item) => item.$$selected)
      .map((report) => {
        return {
          ...report,
          players: [report.players],
        };
      });

    this.$scoutingService.compareReports(players, selectedReports);
  }

  private transformPlayerReportIntoPlayerLabels(report: any) {
    return {
      _id: report.players.player._id,
      player: report.players.player,
      labels: report.labels,
    };
  }

  public async showCreateLabelModal() {
    const modal = {
      template: '<modal-department-create-label modal-instance="$ctrl.modalInstance">',
      controllerAs: '$ctrl',
      backdrop: true,
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
        },
      ],
    };

    try {
      const result = await this.$uibModal.open(modal).result;
      this.availableLabels.push(result);
    } catch (reason) {
      //
    }
  }
}

angular.module('app.scouting').component('scoutingReportsList', {
  templateUrl: 'scouting/components/reports-list.html',
  controller: ScoutingReportsListController,
  bindings: {
    preselect: '<',
  },
});
