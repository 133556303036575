var ABSENSE_REASONS = [
    { name: 'scratch', display: 'Healthy scratch' },
    { name: 'miss', display: 'Not in line up' },
    { name: 'ill', display: 'Not in line up - ill' },
    { name: 'injured', display: 'Not in line up - injured' },
    { name: 'suspended', display: 'Not in line up - suspended' },
    { name: 'call_up', display: 'Call Up' },
    { name: 'sent_down', display: 'Sent Down' },
];
var ScoutingTeamLineupController = (function () {
    function ScoutingTeamLineupController() {
        this.currentLineup = {
            goalies: {},
            lines: [],
            playerIds: Set,
        };
    }
    Object.defineProperty(ScoutingTeamLineupController.prototype, "absenseReasons", {
        get: function () {
            return ABSENSE_REASONS;
        },
        enumerable: false,
        configurable: true
    });
    ScoutingTeamLineupController.prototype.$onInit = function () {
        if (!this.lineup.power) {
            this.lineup.power = [{ scheme: { we: null, they: null } }, { scheme: { we: null, they: null } }];
        }
        if (!this.lineup.box) {
            this.lineup.box = [{ scheme: { we: null, they: null } }, { scheme: { we: null, they: null } }];
        }
        if (!this.lineup.even) {
            this.lineup.even = [];
        }
        this.sortLineupItems();
    };
    ScoutingTeamLineupController.prototype.$onChanges = function (changes) {
        if (changes.lineup || changes.gameReportLineup) {
            this.updateCurrentLineup();
            this.sortLineupItems();
        }
    };
    ScoutingTeamLineupController.prototype.updateCurrentLineup = function () {
        var _this = this;
        var currentLineup = {
            goalies: {},
            lines: [],
            playerIds: Set,
        };
        var playerInsideLineupIds = new Set();
        currentLineup.goalies = {};
        currentLineup.goalies.GK1 = _.get(this.lineup, ['goalies', 'GK1']);
        if (currentLineup.goalies.GK1 === undefined) {
            currentLineup.goalies.GK1 = _.get(this.gameReportLineup, ['goalies', 'GK1']);
        }
        if (currentLineup.goalies.GK1) {
            playerInsideLineupIds.add(currentLineup.goalies.GK1);
        }
        currentLineup.goalies.GK2 = _.get(this.lineup, ['goalies', 'GK2']);
        if (currentLineup.goalies.GK2 === undefined) {
            currentLineup.goalies.GK2 = _.get(this.gameReportLineup, ['goalies', 'GK2']);
        }
        if (currentLineup.goalies.GK2) {
            playerInsideLineupIds.add(currentLineup.goalies.GK2);
        }
        currentLineup.goalies.GK3 = _.get(this.lineup, ['goalies', 'GK3']);
        if (currentLineup.goalies.GK3 === undefined) {
            currentLineup.goalies.GK3 = _.get(this.gameReportLineup, ['goalies', 'GK3']);
        }
        if (currentLineup.goalies.GK3) {
            playerInsideLineupIds.add(currentLineup.goalies.GK3);
        }
        currentLineup.lines = [];
        ['1', '2', '3', '4', '5'].forEach(function (line) {
            currentLineup.lines[line] = {};
            ['RD', 'LD', 'RW', 'LW', 'C'].forEach(function (position) {
                currentLineup.lines[line][position] = _.get(_this.lineup, ['lines', line, position]);
                if (currentLineup.lines[line][position] === undefined) {
                    currentLineup.lines[line][position] = _.get(_this.gameReportLineup, [
                        'lines',
                        line,
                        position,
                    ]);
                }
                if (currentLineup.lines[line][position]) {
                    playerInsideLineupIds.add(currentLineup.lines[line][position]);
                }
            });
        });
        currentLineup.playerIds = playerInsideLineupIds;
        this.currentLineup = currentLineup;
    };
    ScoutingTeamLineupController.prototype.isPositionMatches = function (position) {
        return function (wrapper) {
            switch (wrapper.player.playerPosition) {
                case 'CENTRE':
                case 'RIGHT_WING':
                case 'LEFT_WING':
                case 'FORWARD':
                    return position === 'FORWARD';
                case 'DEFENSEMAN':
                case 'LEFT_DEFENSEMAN':
                case 'RIGHT_DEFENSEMAN':
                    return position === 'DEFENSEMAN';
                default:
                    return position === wrapper.player.playerPosition;
            }
        };
    };
    ScoutingTeamLineupController.prototype.isSuitableButNotPlayed = function () {
        var _this = this;
        return function (player) {
            return (player.team === (_this.team._id || _this.team.temporaryId) &&
                !_this.currentLineup.playerIds.has(player.player._id));
        };
    };
    ScoutingTeamLineupController.prototype.getMissedHolder = function (player) {
        if (!this.lineup.missing) {
            this.lineup.missing = [];
        }
        return new MissingPlayerHolder(this.lineup, player._id);
    };
    ScoutingTeamLineupController.prototype.getMissedDisplay = function (player) {
        var reason = this.getMissedHolder(player).reason;
        var type = ABSENSE_REASONS.find(function (item) { return item.name == reason; });
        return type ? type.display : null;
    };
    ScoutingTeamLineupController.prototype.removeLine = function (list, item) {
        if (list.includes(item)) {
            list.splice(list.indexOf(item), 1);
        }
        this.sortLineupItems();
    };
    Object.defineProperty(ScoutingTeamLineupController.prototype, "newStrengthLine", {
        set: function (details) {
            this.lineup[details.type].push({
                scheme: { we: details.we, they: details.they },
            });
            this.sortLineupItems();
        },
        enumerable: false,
        configurable: true
    });
    ScoutingTeamLineupController.prototype.sortLineupItems = function () {
        var sort = function (lineupItem) {
            return _.chain(lineupItem)
                .groupBy(function (item) { return item.scheme.we + '-' + item.scheme.they; })
                .toPairs()
                .sort(function (a, b) {
                var aSchema = a[0];
                var bSchema = b[0];
                return aSchema < bSchema ? 1 : aSchema === bSchema ? 0 : -1;
            })
                .value();
        };
        this.sortedPower = sort(this.lineup.power);
        this.sortedBox = sort(this.lineup.box);
        this.sortedEven = sort(this.lineup.even);
    };
    return ScoutingTeamLineupController;
}());
var MissingPlayerHolder = (function () {
    function MissingPlayerHolder(lineup, playerId) {
        this.lineup = lineup;
        this.playerId = playerId;
        this.holder = this.lineup.missing.find(function (item) { return item.player == playerId; });
    }
    Object.defineProperty(MissingPlayerHolder.prototype, "reason", {
        get: function () {
            return this.holder ? this.holder.reason : null;
        },
        set: function (value) {
            if (!this.holder) {
                this.holder = {
                    player: this.playerId,
                    reason: null,
                };
                this.lineup.missing.push(this.holder);
            }
            this.holder.reason = value;
        },
        enumerable: false,
        configurable: true
    });
    return MissingPlayerHolder;
}());
angular.module('app.scouting').component('scoutingTeamLineup', {
    templateUrl: 'scouting/components/general/lineup.html',
    controller: ScoutingTeamLineupController,
    bindings: {
        team: '<',
        players: '<',
        gameReportLineup: '<',
        lineup: '<',
        readonly: '<',
    },
});
;