var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var DEFAULT_PAGINATION_SIZE = 10;
var UNLIMITED_PAGINATION_SIZE = 999999;
var ScoutingDepartmentPlayersController = (function () {
    function ScoutingDepartmentPlayersController($timeout, ScoutingService, ScoutingLabelsResource, ScoutingLabelsService, Paginator) {
        this.$timeout = $timeout;
        this.ScoutingService = ScoutingService;
        this.$labelsResource = ScoutingLabelsResource;
        this.$labelsService = ScoutingLabelsService;
        this.$paginator = Paginator;
    }
    ScoutingDepartmentPlayersController.prototype.$onInit = function () {
        var _this = this;
        this.uiTreeConfig = {
            dragStart: function (event) {
                event.elements.placeholder.append('<td colspan="9999">');
            },
            dropped: function (event) {
                _this.$timeout(function () { return _this.populateRanks(); });
            },
        };
    };
    ScoutingDepartmentPlayersController.prototype.$onChanges = function (changes) {
        var _this = this;
        if (changes.selectedLabel) {
            this.hasRankSorting = !!this.selectedLabel && !!this.selectedLabel.hasRanks;
            this.paginator = this.$paginator.getInstance({
                sortBy: this.hasRankSorting ? 'rank' : 'player.lastName',
                strategy: changes.selectedLabel.currentValue
                    ? 'PaginatorNormalStrategy'
                    : 'PaginatorPreloadStrategy',
                limit: changes.selectedLabel.currentValue
                    ? UNLIMITED_PAGINATION_SIZE
                    : DEFAULT_PAGINATION_SIZE,
                pagingFunction: function (params) {
                    return _this.$labelsResource.queryPlayers(Object.assign({ id: _.get(changes.selectedLabel.currentValue, '_id') }, params)).$promise;
                },
            });
        }
        if (changes.availableLabels) {
            var availableLabels_1 = changes.availableLabels.currentValue;
            if (this.paginator.data && this.paginator.data.length) {
                this.paginator.data = this.paginator.data.map(function (item) {
                    item.labels = (item.labels || []).map(function (label) { return availableLabels_1.find(function (avlb) { return avlb._id === label._id; }) || label; });
                    return item;
                });
            }
        }
    };
    ScoutingDepartmentPlayersController.prototype.populateRanks = function () {
        var list = this.paginator.data;
        if (this.paginator.paginationModel.direction === -1 &&
            this.paginator.paginationModel.sort === 'rank') {
            list = list.slice().reverse();
        }
        this.hasRankSorting = true;
        this.paginator.paginationModel.sort = 'rank';
        var ranks = list.map(function (item, index) {
            item.rank = index;
            return { player: item._id, rank: item.rank };
        });
        this.$labelsResource.updateRanks({ id: this.selectedLabel._id }, { ranks: ranks });
    };
    ScoutingDepartmentPlayersController.prototype.hasNamePredicate = function () {
        return function (selectedLabel) { return !!selectedLabel.label; };
    };
    Object.defineProperty(ScoutingDepartmentPlayersController.prototype, "isAllPlayersSelected", {
        get: function () {
            return this.paginator.data.every(function (item) { return item.$$selected; });
        },
        set: function (value) {
            if (value) {
                this.paginator.data.forEach(function (item) { return (item.$$selected = true); });
            }
            else {
                this.paginator.data.forEach(function (item) { return (item.$$selected = false); });
            }
        },
        enumerable: false,
        configurable: true
    });
    ScoutingDepartmentPlayersController.prototype.applyPlayersChanges = function (changes) {
        var _this = this;
        this.promise = this.$labelsService
            .applyChanges(this.availableLabels, changes)
            .then(function () {
            _this.paginator.reset();
            _this.onChange();
            return _this.paginator.lastPromise;
        })
            .then(function () { return (_this.promise = null); });
    };
    ScoutingDepartmentPlayersController.prototype.compareReports = function () {
        var players = this.paginator.data
            .filter(function (item) { return item.$$selected; })
            .map(function (item) {
            return __assign(__assign({}, item.player), { user: item.user });
        });
        this.ScoutingService.compareReports(players, []);
    };
    ScoutingDepartmentPlayersController.prototype.showCommentsBoard = function () {
        this.ScoutingService.showCommentsBoard(this.paginator.data);
    };
    ScoutingDepartmentPlayersController.prototype.createNewReport = function () {
        this.ScoutingService.requestReportCreation();
    };
    return ScoutingDepartmentPlayersController;
}());
angular.module('app.scouting').component('scoutingDepartmentPlayers', {
    templateUrl: 'scouting/components/department-players.html',
    controller: ScoutingDepartmentPlayersController,
    bindings: {
        selectedLabel: '<',
        availableLabels: '<',
        onChange: '&',
        onCreateLabel: '&',
    },
});