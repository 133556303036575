export interface ScoutingRatingValue {
  skill: string;
  value: number;
}

export interface ScoutingRatingSkill {
  name: string;
  includedForAggregation: boolean;
}

function scoutingRatingAvgFilter() {
  return (values: ScoutingRatingValue[], skills: ScoutingRatingSkill[]) => {
    if (!values || !skills) {
      return null;
    }

    let accumulator = 0;
    let count = 0;

    const hasAggregationSkills = skills.some((skill) => !!skill.includedForAggregation);
    const skillsForAgg = hasAggregationSkills
      ? skills.filter((skill) => !!skill.includedForAggregation)
      : skills;

    for (const value of values) {
      const skill = skillsForAgg.find((item) => item.name === value.skill);

      if (skill && value.value != null) {
        accumulator += value.value;
        count += 1;
      }
    }

    return count > 0 ? Math.round((accumulator / count) * 10) / 10 : null;
  };
}

angular.module('app.scouting').filter('scoutingRatingAvg', scoutingRatingAvgFilter);
