var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var ScoutingReportSidebarController = (function () {
    function ScoutingReportSidebarController($state, $uibModal, ScoutingService) {
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.$scoutingService = ScoutingService;
    }
    ScoutingReportSidebarController.prototype.isPositionMatches = function (position) {
        return function (wrapper) {
            switch (wrapper.player.playerPosition) {
                case 'CENTRE':
                case 'RIGHT_WING':
                case 'LEFT_WING':
                case 'FORWARD':
                    return position === 'FORWARD';
                case 'DEFENSEMAN':
                case 'LEFT_DEFENSEMAN':
                case 'RIGHT_DEFENSEMAN':
                    return position === 'DEFENSEMAN';
                default:
                    return position === wrapper.player.playerPosition;
            }
        };
    };
    ScoutingReportSidebarController.prototype.showPlayersPickerModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var report, players, modal, reason_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        report = this.report;
                        players = angular.copy(this.report.players);
                        modal = {
                            template: '<modal-players-picker modal-instance="$ctrl.modalInstance" players="$ctrl.players" report="$ctrl.report">',
                            controllerAs: '$ctrl',
                            size: 'lg',
                            backdrop: true,
                            controller: [
                                '$uibModalInstance',
                                function ($uibModalInstance) {
                                    this.modalInstance = $uibModalInstance;
                                    this.report = report;
                                    this.players = players;
                                },
                            ],
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.$uibModal.open(modal).result];
                    case 2:
                        _a.sent();
                        return [3, 4];
                    case 3:
                        reason_1 = _a.sent();
                        return [3, 4];
                    case 4:
                        this.onPlayersUpdated({ $players: players });
                        return [2];
                }
            });
        });
    };
    ScoutingReportSidebarController.prototype.showLineupModal = function (team) {
        return __awaiter(this, void 0, void 0, function () {
            var players, readonly, teamKind, gameReportLineup, lineup, modal, reason_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        players = angular.copy(this.report.players);
                        readonly = !this.editable;
                        teamKind = (this.report.game.home._id || this.report.game.home.temporaryId) ===
                            (team._id || team.temporaryId)
                            ? 'home'
                            : 'away';
                        gameReportLineup = this.gameReportLineups[teamKind];
                        lineup = this.report.game[teamKind + '_lineup'];
                        if (!lineup) {
                            lineup = {
                                goalies: { GK1: undefined, GK2: undefined, GK3: undefined },
                            };
                            lineup.lines = [];
                            ['1', '2', '3', '4', '5'].forEach(function (line) {
                                lineup.lines[line] = {};
                                ['RD', 'LD', 'RW', 'LW', 'C'].forEach(function (position) {
                                    lineup.lines[line][position] = undefined;
                                });
                            });
                        }
                        modal = {
                            template: '<modal-lineup modal-instance="$ctrl.modalInstance" team="$ctrl.team" lineup="$ctrl.lineup" players="$ctrl.players" game-report-lineup="$ctrl.gameReportLineup" readonly="$ctrl.readonly">',
                            size: 'lg',
                            windowClass: 'modal-lineup',
                            controllerAs: '$ctrl',
                            backdrop: true,
                            controller: [
                                '$uibModalInstance',
                                function ($uibModalInstance) {
                                    this.modalInstance = $uibModalInstance;
                                    this.team = team;
                                    this.players = players;
                                    this.gameReportLineup = gameReportLineup;
                                    this.lineup = lineup;
                                    this.readonly = readonly;
                                },
                            ],
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.$uibModal.open(modal).result];
                    case 2:
                        _a.sent();
                        return [3, 4];
                    case 3:
                        reason_2 = _a.sent();
                        return [3, 4];
                    case 4:
                        ['GK1', 'GK2', 'GK3'].forEach(function (g) {
                            if (lineup.goalies[g] === undefined) {
                                _.unset(lineup, 'goalies.' + g);
                            }
                        });
                        ['1', '2', '3', '4', '5'].forEach(function (line) {
                            ['RD', 'LD', 'RW', 'LW', 'C'].forEach(function (position) {
                                if (lineup.lines[line][position] === undefined) {
                                    _.unset(lineup, "lines.".concat(line, ".").concat(position));
                                }
                            });
                        });
                        this.onPlayersUpdated({ $players: players, $lineup: lineup, $teamKind: teamKind });
                        return [2];
                }
            });
        });
    };
    ScoutingReportSidebarController.prototype.isTeamPlayerSelected = function (team) {
        return this.selectedPlayer && this.selectedPlayer.team === (team._id || team.temporaryId);
    };
    ScoutingReportSidebarController.prototype.getTeamTabLabel = function (team) {
        var playerCount = this.report.players.filter(function (item) { return item.team === (team._id || team.temporaryId) && item.selected; }).length;
        return "(".concat(playerCount, ") ").concat(team.name);
    };
    return ScoutingReportSidebarController;
}());
angular.module('app.scouting').component('scoutingReportSidebar', {
    templateUrl: 'scouting/components/report/sidebar.html',
    controller: ScoutingReportSidebarController,
    bindings: {
        report: '<',
        gameReportLineups: '<',
        loadingGameReportLineups: '<',
        editable: '<',
        selectedPlayer: '<',
        isGameSelected: '<',
        onPlayersUpdated: '&',
        onLineupUpdated: '&',
        onPlayerSelect: '&',
        onGameSelect: '&',
    },
});
;