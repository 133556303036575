(function () {
    'use strict';
    var ScoutingReportAttachmentsController = (function () {
        function ScoutingReportAttachmentsController($scope, S3_UPLOADS_END_POINT) {
            this.$scope = $scope;
            this.s3Endpoint = S3_UPLOADS_END_POINT;
        }
        ScoutingReportAttachmentsController.prototype.addAttachments = function (holder, files) {
            var _a;
            this.value = this.value || [];
            (_a = this.value).push.apply(_a, files);
            this.$scope.$emit('scouting:element:changed');
        };
        ScoutingReportAttachmentsController.prototype.removeAttachment = function (file) {
            var index = this.value.indexOf(file);
            this.value.splice(index, 1);
            this.$scope.$emit('scouting:element:changed');
        };
        ScoutingReportAttachmentsController.prototype.isPathLookingLikePdf = function (path) {
            return /\.pdf$/.test(path);
        };
        ScoutingReportAttachmentsController.prototype.getPath = function (path) {
            if (path.indexOf('https://') > -1) {
                return path;
            }
            return "".concat(this.s3Endpoint, "/").concat(path);
        };
        return ScoutingReportAttachmentsController;
    }());
    angular.module('app.scouting').component('scoutingReportAttachments', {
        templateUrl: 'scouting/components/report/attachments.html',
        controller: ScoutingReportAttachmentsController,
        bindings: {
            value: '=',
            template: '<',
            editable: '<',
            direction: '@',
            withMessage: '@',
        },
    });
})();