class ScoutingLabelsController {
  private labelsHolder;
  private playerId;

  constructor(
    private ScoutingLabelsResource,
    private $filter,
  ) {}

  removeLabel(label) {
    const confirmationText = this.$filter('translate')('scouting.Delete_label_confirmation', {
      labelName: label.label.name,
    });
    if (window.confirm(confirmationText)) {
      const idx = this.labelsHolder.labels.indexOf(label);
      this.labelsHolder.labels.splice(idx, 1);

      this.ScoutingLabelsResource.removePlayers({id: label._id}, {players: [{_id: this.playerId}]});
    }
  }
}

angular.module('app.scouting').component('scoutingLabels', {
  templateUrl: 'scouting/components/general/labels.html',
  controller: ScoutingLabelsController,
  bindings: {
    labelsHolder: '<',
    playerId: '<',
  },
});
