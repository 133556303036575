var ScoutingNewFilterButtonController = (function () {
    function ScoutingNewFilterButtonController($filter, ScoutingFilterService) {
        this.$filter = $filter;
        this.$scoutingFilterService = ScoutingFilterService;
    }
    Object.defineProperty(ScoutingNewFilterButtonController.prototype, "availableCriteria", {
        get: function () {
            return this.$scoutingFilterService.availableCriteria;
        },
        enumerable: false,
        configurable: true
    });
    ScoutingNewFilterButtonController.prototype.select = function (criterion) {
        this.onFilterSelected({
            $filter: {
                criterion: criterion.name,
                field: criterion.field,
                negative: false,
                value: {},
            },
        });
    };
    ScoutingNewFilterButtonController.prototype.criterionNameTransfomer = function () {
        var filter = this.$filter('scTranslate');
        return function (citerion) { return filter("scouting.filters.".concat(citerion.name)); };
    };
    return ScoutingNewFilterButtonController;
}());
angular.module('app.scouting').component('scoutingNewFilterButton', {
    templateUrl: 'scouting/components/filters/new-filter-button.html',
    controller: ScoutingNewFilterButtonController,
    bindings: {
        onFilterSelected: '&',
    },
});
;