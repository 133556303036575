interface LabelPlayersAction {
  initial: boolean;
  desired: boolean;
  label: Label;
}

interface LabelPlayersChange {
  type: 'add' | 'remove';
  player: LabelPlayer;
  label: string;
}

class ScoutingDepartmentLabelsDropdownController {
  private players: LabelPlayer[];
  private availableLabels: Label[];
  private onChange: (args: {$changes: LabelPlayersChange[]}) => void;

  private actions: LabelPlayersAction[];

  constructor() {}

  createAvailableActions() {
    if (!this.players.length || !this.availableLabels.length) {
      return (this.actions = null);
    }

    const normalLabels = this.availableLabels.filter((label) => !!label.label);
    const unamedLabel = this.availableLabels.find((label) => !label.label);

    this.actions = [unamedLabel, ...normalLabels].map((label) => {
      let initial = null;

      if (this.players.every((item) => !!item.labels.find((inner) => inner._id === label._id))) {
        initial = true;
      } else if (
        this.players.every((item) => !item.labels.find((inner) => inner._id === label._id))
      ) {
        initial = false;
      }

      return {
        initial,
        desired: initial,
        label: label,
      };
    });
  }

  changeAction(event: MouseEvent, action: LabelPlayersAction) {
    switch (action.desired) {
      case null:
        action.desired = true;
        break;

      case true:
        action.desired = false;
        break;

      case false:
        action.desired = action.initial === null ? null : true;
        break;
    }

    event.preventDefault();
  }

  get isApplyPossible() {
    return (this.actions || []).some((item) => item.desired !== item.initial);
  }

  apply() {
    const changes = [];

    for (const action of this.actions.filter((item) => item.desired !== item.initial)) {
      switch (action.desired) {
        case true:
          changes.push(...this.findPlayersForLabelAdd(action.label._id));
          break;

        case false:
          changes.push(...this.findPlayersForLabelRemove(action.label._id));
          break;
      }
    }

    this.onChange({$changes: changes});
  }

  private findPlayersForLabelAdd(needle: string) {
    return this.players
      .filter((item) => item.labels.every((label) => label._id !== needle))
      .map((item) => {
        return {
          type: 'add',
          player: _.pick(item.player, ['_id', 'firstName', 'lastName']),
          label: needle,
        };
      });
  }

  private findPlayersForLabelRemove(needle: string) {
    return this.players
      .filter((item) => item.labels.some((label) => label._id === needle))
      .map((item) => {
        return {
          type: 'remove',
          player: _.pick(item.player, ['_id', 'firstName', 'lastName']),
          label: needle,
        };
      });
  }
}

angular.module('app.scouting').component('scoutingDepartmentLabelsDropdown', {
  templateUrl: 'scouting/components/department-labels-dropdown.html',
  controller: ScoutingDepartmentLabelsDropdownController,

  bindings: {
    players: '<',
    availableLabels: '<',
    onChange: '&',
    onCreateLabel: '&',
  },
});
