var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var ScoutingFilterElementController = (function () {
    function ScoutingFilterElementController(UserService, ScoutingFilterService) {
        this.$scoutingFilterService = ScoutingFilterService;
        this.dateInputFormat = UserService.getUser().settings.dateformat;
        this.previousFiltersSerialized = {};
    }
    ScoutingFilterElementController.prototype.serializeFilters = function (filters) {
        return this.$scoutingFilterService.serialize(filters);
    };
    ScoutingFilterElementController.prototype.$onChanges = function (changes) {
        var shouldRefreshFilter = false;
        if (changes.previousFilters) {
            var serializedFilters = this.serializeFilters(changes.previousFilters.currentValue);
            if (!_.isEqual(serializedFilters, this.previousFiltersSerialized)) {
                shouldRefreshFilter = true;
            }
            this.previousFiltersSerialized = serializedFilters;
        }
        if (changes.criterion && changes.criterion.currentValue.element === 'date') {
            this.promise = this.fetchDateRange();
            return;
        }
        if (changes.criterion && changes.criterion.currentValue.element === 'number') {
            this.promise = this.fetchNumberRange();
            return;
        }
        if (changes.criterion && changes.criterion.currentValue.element === 'select') {
            this.promise = this.fetchAvailableChoices();
            return;
        }
        if (shouldRefreshFilter) {
            if (this.criterion.element === 'select') {
                this.promise = this.fetchAvailableChoices();
            }
            else if (this.criterion.element === 'number') {
                this.promise = this.fetchNumberRange();
            }
            else if (this.criterion.element === 'date') {
                this.promise = this.fetchDateRange();
            }
        }
        if (changes.type && this.numberRange && this.criterion && this.criterion.element === 'number') {
            if (this.type === 'is_exact' && !this.value.exact) {
                this.value.exact = this.numberRange.min;
                this.onChange();
            }
            if (this.type === 'is_between' && !this.value.range.lt && !this.value.range.gt) {
                this.value.range.lt = this.numberRange.max;
                this.value.range.gt = this.numberRange.min;
                this.onChange();
            }
            if (this.type === 'is_less_than' && !this.value.range.lt) {
                this.value.range.lt = this.numberRange.max;
                this.onChange();
            }
            if (this.type === 'is_greater_than' && !this.value.range.gt) {
                this.value.range.gt = this.numberRange.min;
                this.onChange();
            }
        }
    };
    ScoutingFilterElementController.prototype.searchChoices = function (query) {
        var _this = this;
        var availableChoices = this.selectableChoices.filter(function (item) {
            if (_this.value.or.includes(item)) {
                return false;
            }
            if (item._id && !!_this.value.or.find(function (element) { return element._id === item._id; })) {
                return false;
            }
            return true;
        });
        query = _.deburr(query).toLocaleLowerCase();
        if (!query.replace(/\s/, '')) {
            return availableChoices;
        }
        return availableChoices.filter(function (choice) {
            var choiceText = _.deburr(_this.getChoiceDisplay(choice)).toLocaleLowerCase();
            return query.split(/\s+/).every(function (part) { return choiceText.includes(part); });
        });
    };
    ScoutingFilterElementController.prototype.getChoiceDisplay = function (choice) {
        return this.criterion.displayTransformer(choice);
    };
    ScoutingFilterElementController.prototype.fetchDateRange = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, this.$scoutingFilterService.queryRange(this.criterion.field, this.previousFilters)];
                    case 1:
                        _a.dateRange = _b.sent();
                        this.dateRange.max = new Date(this.dateRange.max);
                        this.dateRange.min = new Date(this.dateRange.min);
                        return [2];
                }
            });
        });
    };
    ScoutingFilterElementController.prototype.fetchNumberRange = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, this.$scoutingFilterService.queryRange(this.criterion.field, this.previousFilters)];
                    case 1:
                        _a.numberRange = _b.sent();
                        return [2];
                }
            });
        });
    };
    ScoutingFilterElementController.prototype.fetchAvailableChoices = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.instance.choices) return [3, 1];
                        this.selectableChoices = this.instance.choices;
                        return [3, 3];
                    case 1:
                        _a = this;
                        return [4, this.$scoutingFilterService.queryChoices(this.criterion.field, this.previousFilters, this.showPublic ? 1 : -1)];
                    case 2:
                        _a.selectableChoices = _b.sent();
                        _b.label = 3;
                    case 3: return [2];
                }
            });
        });
    };
    return ScoutingFilterElementController;
}());
angular.module('app.scouting').component('scoutingFilterInputElement', {
    templateUrl: 'scouting/components/filters/filter-input-element.html',
    controller: ScoutingFilterElementController,
    bindings: {
        criterion: '<',
        previousFilters: '<',
        type: '<',
        value: '=',
        instance: '<',
        showPublic: '<',
        onChange: '&',
    },
});
;