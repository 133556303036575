export interface FilterCriterion {
  name: string;
  field: string;
  element: 'text' | 'number' | 'date' | 'select';

  displayTransformer?: (item: any) => any;

  deserialize: (value: string) => any;
  serialize: (value: any) => string;

  readonly features: {
    querySearch: boolean;
    exactSearch: boolean;
    rangeSearch: boolean;
  };
}

export interface Filter {
  criterion: string;
  field: string;
  disabled?: boolean;
  permanent?: boolean;
  negative: boolean;
  choices?: any[];

  value: {
    query?: string;

    range?: {gt?: any; lt?: any};
    exact?: any;
    or?: any[];
  };
}

export interface SerializedRepresentation {
  [name: string]: string | string[];
}

class ScoutingFilterCriteriaProvider {
  $get = [
    '$filter',
    ($filter) => [
      {
        name: 'creator',
        field: 'createdBy',
        element: 'select',

        displayTransformer: (item) => `${item.firstName} ${item.lastName}`,

        deserialize: (value) => this.deserializePerson(value),
        serialize: (value) => `${this.serializeId(value)}:${value.firstName}:${value.lastName}`,

        features: {
          querySearch: false,
          exactSearch: true,
          rangeSearch: false,
        },
      },

      {
        name: 'organization',
        field: 'organization',
        element: 'select',

        displayTransformer: (item) => `${item.name}`,

        deserialize: (value) => this.deserializeOrganization(value),
        serialize: (value) => `${this.serializeId(value)}:${value.name}`,

        features: {
          querySearch: false,
          exactSearch: true,
          rangeSearch: false,
        },
      },

      {
        name: 'creation_date',
        field: 'createdAt',
        element: 'date',

        displayTransformer: (item) => $filter('scDate')(item),

        deserialize: (value) => new Date(value),
        serialize: (value) => (value ? value.toISOString() : ''),

        features: {
          querySearch: false,
          exactSearch: false,
          rangeSearch: true,
        },
      },

      {
        name: 'game_date',
        field: 'game.date',
        element: 'date',

        displayTransformer: (item) => $filter('scDate')(item),

        deserialize: (value) => new Date(value),
        serialize: (value) => (value ? value.toISOString() : ''),

        features: {
          querySearch: false,
          exactSearch: false,
          rangeSearch: true,
        },
      },

      {
        name: 'report_league',
        field: 'league',
        element: 'select',

        displayTransformer: (item) => item.name,

        deserialize: (value) => this.deserializeOrganization(value),
        serialize: (value) => `${this.serializeId(value)}:${value.name}`,

        features: {
          querySearch: false,
          exactSearch: true,
          rangeSearch: false,
        },
      },

      {
        name: 'report_team',
        field: 'players.team',
        element: 'select',

        displayTransformer: (item) => item.name,

        deserialize: (value) => this.deserializeOrganization(value),
        serialize: (value) => `${this.serializeId(value)}:${value.name}`,

        features: {
          querySearch: false,
          exactSearch: true,
          rangeSearch: false,
        },
      },

      {
        name: 'player_name',
        field: 'players.player.lastName',
        element: 'text',

        displayTransformer: (item) => item,

        deserialize: (value) => value,
        serialize: (value) => value,

        features: {
          querySearch: true,
          exactSearch: false,
          rangeSearch: false,
        },
      },

      {
        name: 'player',
        field: 'players.player',
        element: 'select',

        displayTransformer: (item) => `${item.firstName} ${item.lastName}`,

        deserialize: (value) => this.deserializePerson(value),
        serialize: (value) => `${this.serializeId(value)}:${value.firstName}:${value.lastName}`,

        features: {
          querySearch: false,
          exactSearch: true,
          rangeSearch: false,
        },
      },

      {
        name: 'report_player_position',
        field: 'players.player.playerPosition',
        element: 'select',

        displayTransformer: (item) =>
          typeof item == 'string' ? $filter('capitalize')(item) : null,

        deserialize: (value) => value,
        serialize: (value) => value,

        features: {
          querySearch: false,
          exactSearch: true,
          rangeSearch: false,
        },
      },

      {
        name: 'tag',
        field: 'tags',
        element: 'select',

        displayTransformer: (item) => item,

        deserialize: (value) => value,
        serialize: (value) => value,

        features: {
          querySearch: false,
          exactSearch: true,
          rangeSearch: false,
        },
      },

      {
        name: 'player_nationality',
        field: 'players.player.country',
        element: 'select',

        displayTransformer: (item) => item.name,

        deserialize: (value) => this.deserializeOrganization(value),
        serialize: (value) => `${this.serializeId(value)}:${value.name}`,

        features: {
          querySearch: false,
          exactSearch: true,
          rangeSearch: false,
        },
      },

      {
        name: 'player_date_of_birth',
        field: 'players.player.dateOfBirth',
        element: 'date',

        displayTransformer: (item) => $filter('scDate')(item),

        deserialize: (value) => new Date(value),
        serialize: (value) => (value ? value.toISOString() : ''),

        features: {
          querySearch: false,
          exactSearch: false,
          rangeSearch: true,
        },
      },

      {
        name: 'player_age',
        field: 'players.player.age',
        element: 'number',

        displayTransformer: (item) => item,

        deserialize: (value) => +value,
        serialize: (value) => value,

        features: {
          querySearch: false,
          exactSearch: false,
          rangeSearch: true,
        },
      },
    ],
  ];

  private deserializeOrganization(value: string) {
    const partsWithMongoId = /^([a-z0-9]{24}):(.+)$/.exec(value);
    const partsWithTempId = /^(.+):(.+)$/.exec(value);

    if (partsWithMongoId && partsWithMongoId.length) {
      return {
        _id: partsWithMongoId[1],
        name: partsWithMongoId[2],
      };
    } else if (partsWithTempId && partsWithTempId.length) {
      return {
        temporaryId: partsWithTempId[1],
        name: partsWithTempId[2],
      };
    } else {
      throw new Error('Cannot parse organization: ' + value);
    }
  }

  private deserializePerson(value: string) {
    const partsWithMongoId = /^([a-z0-9]{24}):(.+):(.+)$/.exec(value);
    const partsWithTempId = /^(.+):(.+):(.+)$/.exec(value);

    if (partsWithMongoId && partsWithMongoId.length) {
      return {
        _id: partsWithMongoId[1],
        firstName: partsWithMongoId[2],
        lastName: partsWithMongoId[3],
      };
    } else if (partsWithTempId && partsWithTempId.length) {
      return {
        temporaryId: partsWithTempId[1],
        firstName: partsWithTempId[2],
        lastName: partsWithTempId[3],
      };
    } else {
      throw new Error('Cannot parse person: ' + value);
    }
  }

  private serializeId(value: any) {
    return value._id || value.temporaryId;
  }
}

class ScoutingFilterService {
  private endpoint: string;
  private criteria: FilterCriterion[];

  constructor(
    private $http,
    $filter,
    SCConfiguration,
    ScoutingFilterCriteria,
  ) {
    this.endpoint = SCConfiguration.getEndpoint() + '/api/scouting/filters';
    this.criteria = ScoutingFilterCriteria;
  }

  get availableCriteria() {
    return this.criteria;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  @LastCallAsyncCaching()
  async queryRange(field: string, filters: Filter[]) {
    return this.$http
      .post(`${this.endpoint}/range`, {field, filters})
      .then((response) => response.data);
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  @LastCallAsyncCaching()
  async queryChoices(field: string, filters: Filter[], showPublic: string) {
    return this.$http
      .post(`${this.endpoint}/choices?showPublic=${showPublic}`, {field, filters})
      .then((response) => response.data);
  }

  serialize(filters: Filter[]): SerializedRepresentation {
    const transformed = filters.map((item) => {
      const criterion = this.criteria.find((criterion) => criterion.name === item.criterion);

      if (!criterion || item.disabled) {
        return null;
      }

      let value;

      if (item.value.or) {
        value = item.value.or.map((value) => criterion.serialize(value)).join(',');
      } else if (item.value.exact) {
        value = criterion.serialize(item.value.exact);
      } else if (item.value.query) {
        value = criterion.serialize(item.value.query);
      } else if (item.value.range) {
        value = `(${criterion.serialize(item.value.range.gt)},${criterion.serialize(
          item.value.range.lt,
        )})`;
      }

      if (item.negative) {
        value = `not(${value})`;
      }

      return {
        name: item.criterion,
        value,
      };
    });

    return _.chain(transformed)
      .filter((item) => !!item)
      .groupBy((item) => item.name)
      .mapValues((item: any) => {
        const values = item.map((item) => item.value).filter((item) => !!item);

        return values.length === 1 ? values[0] : values;
      })
      .value();
  }

  deserialize(serialized: SerializedRepresentation): Filter[] {
    const filters = [];

    Object.keys(serialized).forEach((criterion) => {
      if (!serialized[criterion]) {
        return;
      }

      const instance = this.criteria.find((item) => item.name === criterion);
      const values = Array.isArray(serialized[criterion])
        ? (serialized[criterion] as string[])
        : [serialized[criterion] as string];

      if (instance && instance.features.exactSearch) {
        try {
          for (const value of values) {
            filters.push(this.deserializeExactSearchFilter(instance, value));
          }

          return;
        } catch (error) {
          console.error('Unable to parse exact search filter:', error);
        }
      }

      if (instance && instance.features.rangeSearch) {
        try {
          for (const value of values) {
            filters.push(this.deserializeRangeSearchFilter(instance, value));
          }

          return;
        } catch (error) {
          console.error('Unable to parse range filter:', error);
        }
      }

      if (instance && instance.features.querySearch) {
        try {
          for (const value of values) {
            filters.push(this.deserializeQuerySearchFilter(instance, value));
          }

          return;
        } catch (error) {
          console.error('Unable to parse query filter:', error);
        }
      }
    });

    return filters;
  }

  private deserializeExactSearchFilter(criterion: FilterCriterion, value: string): Filter {
    let negative = false;

    if (value.startsWith('not(')) {
      negative = true;
      value = /not\((.+)\)/.exec(value)[1];
    }

    return {
      criterion: criterion.name,
      field: criterion.field,
      negative,

      value: {
        [criterion.element === 'date' ? 'exact' : 'or']:
          criterion.element === 'date'
            ? criterion.deserialize(value)
            : value.split(',').map((value) => criterion.deserialize(value)),
      },
    };
  }

  private deserializeRangeSearchFilter(criterion: FilterCriterion, value: string): Filter {
    const parts = /^\((.*),(.*)\)$/.exec(value);

    if (parts && parts.length) {
      return {
        criterion: criterion.name,
        field: criterion.field,
        negative: false,

        value: {
          range: {
            gt: parts[1] ? criterion.deserialize(parts[1]) : undefined,
            lt: parts[2] ? criterion.deserialize(parts[2]) : undefined,
          },
        },
      };
    } else {
      return {
        criterion: criterion.name,
        field: criterion.field,
        negative: false,

        value: {
          exact: criterion.deserialize(value),
        },
      };
    }
  }

  private deserializeQuerySearchFilter(criterion: FilterCriterion, value: string): Filter {
    let negative = false;

    if (value.startsWith('not(')) {
      negative = true;
      value = /not\((.+)\)/.exec(value)[1];
    }

    return {
      criterion: criterion.name,
      field: criterion.field,
      negative,

      value: {
        query: value,
      },
    };
  }
}

angular
  .module('app.scouting')
  .provider('ScoutingFilterCriteria', ScoutingFilterCriteriaProvider)
  .service('ScoutingFilterService', ScoutingFilterService);
