class PlaylistPickerModalController {
  private modalInstance;
  private myPlaylists;
  private sharedPlaylists;
  private myPlaylistsBreadcrumbs = [{name: 'Home', id: ''}];
  private sharedPlaylistsBreadcrumbs = [{name: 'Home', id: ''}];
  private selectedTab;
  private selectedPlaylists = [];
  private myPlaylistPromise;
  private sharedPlaylistPromise;
  private multiple;
  private currentUser;

  constructor(
    private PlaylistService,
    private UserService,
  ) {}

  $onInit() {
    this.currentUser = this.UserService.getUser();
    this.myPlaylistPromise = this.PlaylistService.fetchTopLevel().then((playlists) => {
      this.myPlaylists = this.getSortedPlaylists(this.filterMyPlaylist(playlists, true));
      this.sharedPlaylists = this.getSortedPlaylists(this.filterSharedPlaylists(playlists, true));
    });
    this.sharedPlaylistPromise = this.myPlaylistPromise;
  }

  filterMyPlaylist(nodes, isRoot) {
    return nodes.filter(
      (p) =>
        !p.deleted &&
        p.capabilities.isOwner &&
        (isRoot ? p.workspace === `user:${this.currentUser._id}` : true),
    );
  }

  filterSharedPlaylists(nodes, isRoot) {
    const nodeById = _.keyBy(nodes, (node) => node.id);
    return nodes.filter((p) => {
      if (p.deleted) {
        return false;
      }
      if (!isRoot) {
        return !_.get(p, 'capabilities.isOwner');
      }
      if (p.workspace === `user:${this.currentUser._id}`) {
        return false;
      }

      const caps = p.capabilities;
      const parentNode = p.parent ? nodeById[p.parent] : null;
      const hasAccessibleParent = parentNode
        ? Boolean(_.get(parentNode, 'capabilities.canView'))
        : false;

      if (hasAccessibleParent) {
        return false;
      }

      return Boolean(
        caps &&
          caps.subscribed &&
          !caps.isOwner &&
          caps.canView &&
          caps.appliedPolicy &&
          !caps.appliedPolicy.inheritedFrom,
      );
    });
  }

  getSortedPlaylists(playlists) {
    return _.orderBy(playlists, [(p) => p.type, (p) => p.name.toLowerCase()], ['desc', 'asc']);
  }

  gotoFolder(playlist) {
    if (this.selectedTab === 0) {
      const breadcrumbIdx = this.myPlaylistsBreadcrumbs.findIndex((b) => b.id === playlist.id);
      if (breadcrumbIdx > -1) {
        this.myPlaylistsBreadcrumbs = this.myPlaylistsBreadcrumbs.filter(
          (b, idx) => idx <= breadcrumbIdx,
        );
      } else {
        this.myPlaylistsBreadcrumbs.push({id: playlist.id, name: playlist.name});
      }
      this.myPlaylistPromise = (
        playlist.id
          ? this.PlaylistService.fetchChildNodes(playlist.id)
          : this.PlaylistService.fetchTopLevel()
      ).then((playlists) => {
        this.myPlaylists = this.getSortedPlaylists(this.filterMyPlaylist(playlists, !playlist.id));
      });
    }
    if (this.selectedTab === 1) {
      const breadcrumbIdx = this.sharedPlaylistsBreadcrumbs.findIndex((b) => b.id === playlist.id);
      if (breadcrumbIdx > -1) {
        this.sharedPlaylistsBreadcrumbs = this.sharedPlaylistsBreadcrumbs.filter(
          (b, idx) => idx <= breadcrumbIdx,
        );
      } else {
        this.sharedPlaylistsBreadcrumbs.push({id: playlist.id, name: playlist.name});
      }
      this.sharedPlaylistPromise = (
        playlist.id
          ? this.PlaylistService.fetchChildNodes(playlist.id)
          : this.PlaylistService.fetchTopLevel()
      ).then((playlists) => {
        this.sharedPlaylists = this.getSortedPlaylists(
          this.filterSharedPlaylists(playlists, !playlist.id),
        );
      });
    }
  }

  isSelectedPlaylist(playlist) {
    return this.selectedPlaylists.findIndex((p) => p.id === playlist.id) > -1;
  }

  goBack() {
    const lastFolder =
      this.selectedTab === 0
        ? this.myPlaylistsBreadcrumbs[this.myPlaylistsBreadcrumbs.length - 2]
        : this.sharedPlaylistsBreadcrumbs[this.sharedPlaylistsBreadcrumbs.length - 2];

    if (lastFolder) {
      this.gotoFolder(lastFolder);
    }
  }

  togglePlaylistSelection(playlist) {
    if (this.selectedPlaylists.findIndex((p) => p.id === playlist.id) > -1) {
      this.selectedPlaylists = this.selectedPlaylists.filter((p) => p.id !== playlist.id);
    } else {
      if (!this.multiple) {
        this.selectedPlaylists = [playlist];
      } else {
        this.selectedPlaylists.push(playlist);
      }
    }
  }

  confirm() {
    this.modalInstance.close(this.selectedPlaylists);
  }
}

angular.module('app.scouting').component('playlistPickerModal', {
  templateUrl: 'scouting/components/report/playlist-picker-modal.html',
  controller: PlaylistPickerModalController as any,
  bindings: {
    modalInstance: '<',
    multiple: '<',
  },
});
