function scoutingRatingSumFilter() {
    return function (values, skills) {
        if (!values || !skills) {
            return null;
        }
        var accumulator = 0;
        var count = 0;
        var hasAggregationSkills = skills.some(function (skill) { return !!skill.includedForAggregation; });
        var skillsForAgg = hasAggregationSkills
            ? skills.filter(function (skill) { return !!skill.includedForAggregation; })
            : skills;
        var _loop_1 = function (value) {
            var skill = skillsForAgg.find(function (item) { return item.name === value.skill; });
            if (skill && value.value != null) {
                accumulator += value.value;
                count += 1;
            }
        };
        for (var _i = 0, values_1 = values; _i < values_1.length; _i++) {
            var value = values_1[_i];
            _loop_1(value);
        }
        return count > 0 ? accumulator : null;
    };
}
angular.module('app.scouting').filter('scoutingRatingSum', scoutingRatingSumFilter);
;