angular.module('app.scouting').filter('scoutingReportName', scoutingReportNameFilter);

function scoutingReportNameFilter($filter) {
  const scDateFilter = $filter('scDate');

  return (report) => {
    switch (!!report.game) {
      case true:
        return `${scDateFilter(report.game.date)} - "${report.game.home.name}" ${
          report.game.score_home ? report.game.score_home + ' ' : ''
        }:${report.game.score_away ? ' ' + report.game.score_away : ''} "${report.game.away.name}"`;

      case false:
        return `${scDateFilter(report.createdAt)} - ${report.template.name}`;
      default:
        return '';
    }
  };
}
