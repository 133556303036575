class ScoutingCollectionPlayersController {
  private paginator;
  private setPaginator;
  private $paginator;
  private selectedCollection;
  private selectedList;
  private availableLabels;
  private $playerCollectionsService;
  private $scoutingService;
  private $labelsService;
  private $applyLabelPromise;

  constructor(Paginator, PlayerCollectionsService, ScoutingLabelsService, ScoutingService) {
    this.$paginator = Paginator;
    this.$playerCollectionsService = PlayerCollectionsService;
    this.$applyLabelPromise = false;
    this.$scoutingService = ScoutingService;
    this.$labelsService = ScoutingLabelsService;
  }

  $onChanges(changes) {
    if (!changes.paginator.currentValue) {
      this.setPaginator({
        newPaginator: this.$paginator.getInstance({
          limit: 30,
          strategy: 'PaginatorNormalStrategy',
          pagingFunction: ({skip, limit}) =>
            this.$playerCollectionsService.fetchPlayerValues(
              this.selectedCollection._id,
              this.selectedList._id,
              {skip, limit},
            ),
        }),
      });
    }
  }

  getColumns() {
    if (this.selectedList.columnDef) {
      return this.selectedList.columnDef.columns;
    }
    return this.selectedCollection.columnDef.columns;
  }

  getValue(col, playerValue) {
    const colVal = playerValue.values.find((item) => item.columnUuid === col.uuid);
    return colVal ? colVal.value : '-';
  }

  createNewReport() {
    this.$scoutingService.requestReportCreation();
  }

  showCommentsBoard() {
    this.$scoutingService.showCommentsBoard(this.paginator.data);
  }

  compareReports() {
    const players = this.paginator.data.filter((item) => item.$$selected);
    this.$scoutingService.compareReports(players, []);
  }

  public applyPlayersChanges(changes: LabelPlayersChange[]) {
    this.$applyLabelPromise = this.$labelsService
      .applyChanges(this.availableLabels, changes)
      .then(() => {
        this.paginator.reset();
        return this.paginator.lastPromise;
      });
  }

  get isAllPlayersSelected() {
    if (!this.paginator || this.paginator.data.length === 0) {
      return false;
    }
    return this.paginator.data.every((item) => item.$$selected);
  }

  set isAllPlayersSelected(value: boolean) {
    this.paginator.data.forEach((item) => {
      item.$$selected = value;
    });
  }
}
angular.module('app.scouting').component('scoutingCollectionPlayers', {
  templateUrl: 'scouting/components/collections-players.html',
  controller: ScoutingCollectionPlayersController,
  bindings: {
    selectedCollection: '<',
    selectedList: '<',
    availableLabels: '<',
    onCreateLabel: '&',
    paginator: '<',
    setPaginator: '&',
  },
});
