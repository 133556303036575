var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ScoutingReportSkillTableFieldController = (function () {
    function ScoutingReportSkillTableFieldController($scope, $uibModal, GameService, ScoutingService, PlaylistService) {
        this.$scope = $scope;
        this.$uibModal = $uibModal;
        this.GameService = GameService;
        this.ScoutingService = ScoutingService;
        this.PlaylistService = PlaylistService;
        this.loadingPlaylist = null;
    }
    ScoutingReportSkillTableFieldController.prototype.getValueHolder = function (skill) {
        var holder = this.value && this.value.find(function (item) { return item.skill === skill.name; });
        return (holder ||
            this.createHolder({ id: "".concat(this.selectedPlayer.player._id, "-").concat(this.params.id) }, skill.name));
    };
    ScoutingReportSkillTableFieldController.prototype.getSkills = function (player) {
        switch (player.player.playerPosition) {
            case 'GOALIE':
                return this.params.goaliesSkills || this.params.skills;
            default:
                return this.params.skills;
        }
    };
    ScoutingReportSkillTableFieldController.prototype.createHolder = function (wrapper, skill) {
        return { skill: skill };
    };
    ScoutingReportSkillTableFieldController.prototype.notifyAboutChanges = function () {
        this.$scope.$emit('scouting:element:changed');
    };
    ScoutingReportSkillTableFieldController.prototype.attachPlaylistToSkill = function (skill) {
        var _this = this;
        this.$uibModal
            .open({
            size: 'md',
            template: '<playlist-picker-modal modal-instance="$ctrl.modalInstance" multiple="true" />',
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    this.modalInstance = $uibModalInstance;
                },
            ],
            controllerAs: '$ctrl',
        })
            .result.then(function (selectedPlaylists) {
            if (selectedPlaylists.length) {
                var skillValue_1 = _this.getValueHolder(skill);
                if (!skillValue_1.attachedPlaylists) {
                    skillValue_1.attachedPlaylists = [];
                }
                if (skillValue_1.attachedPlaylists.some(function (p) {
                    return selectedPlaylists.some(function (selectedPlaylist) { return selectedPlaylist.id === p.id; });
                })) {
                    return;
                }
                selectedPlaylists.forEach(function (p) {
                    skillValue_1.attachedPlaylists.push({
                        id: p.id,
                        name: p.name,
                    });
                });
                _this.notifyAboutChanges();
            }
        });
    };
    ScoutingReportSkillTableFieldController.prototype.removePlaylist = function (skill, playlist) {
        var skillValue = this.getValueHolder(skill);
        if (skillValue.attachedPlaylists) {
            skillValue.attachedPlaylists = skillValue.attachedPlaylists.filter(function (p) { return p.id !== playlist.id; });
            this.notifyAboutChanges();
        }
    };
    ScoutingReportSkillTableFieldController.prototype.isLoadingPlaylist = function (playlist, skill) {
        if (!this.loadingPlaylist) {
            return false;
        }
        return (this.loadingPlaylist.playlistId === playlist.id && this.loadingPlaylist.skill === skill.name);
    };
    ScoutingReportSkillTableFieldController.prototype.playPlaylist = function (attachedPlaylist) {
        this.GameService.openNextGenScoutingPlaylist(this.report._id, attachedPlaylist.id);
    };
    __decorate([
        CacheReturnValue(function (context) { return context.$scope; }),
        OnReturnObjectChangedOnce(function (context) { return context.$scope; }, function (context, holder, _a) {
            context.value = context.value || [];
            context.value.push(holder);
        })
    ], ScoutingReportSkillTableFieldController.prototype, "createHolder", null);
    return ScoutingReportSkillTableFieldController;
}());
angular.module('app.scouting').component('scoutingReportFieldSkillTable', {
    templateUrl: 'scouting/components/field/skill-table.html',
    controller: ScoutingReportSkillTableFieldController,
    bindings: {
        params: '<',
        value: '=',
        editable: '<',
        selectedPlayer: '<',
        compact: '<',
        report: '<',
    },
});