var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var CommentsBoardController = (function () {
    function CommentsBoardController(OrganizationService, ScoutingService, ScoutingReportResource, UserService, $uibModal, $filter, $timeout, Toastr, _) {
        this.OrganizationService = OrganizationService;
        this.ScoutingService = ScoutingService;
        this.ScoutingReportResource = ScoutingReportResource;
        this.UserService = UserService;
        this.$uibModal = $uibModal;
        this.$filter = $filter;
        this.$timeout = $timeout;
        this.Toastr = Toastr;
        this._ = _;
        this.state = {};
    }
    CommentsBoardController.prototype.$onInit = function () {
        this.user = this.UserService.getUser();
        this.$promise = this.fetchData();
    };
    CommentsBoardController.prototype.fetchData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var members, _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4, this.OrganizationService.listMembers()];
                    case 1:
                        members = _c.sent();
                        this.membersHasAccess = members
                            .filter(function (member) { return member.rights.indexOf('commentsboardaccess') > -1; })
                            .map(function (user) {
                            var firstName = _this._.get(user, 'profile.firstName', '');
                            var lastName = _this._.get(user, 'profile.lastName', '');
                            var position = _this._.get(user, 'profile.playerPosition', '');
                            var fullName = _this._.joinIfPresent(' ', firstName, lastName);
                            return {
                                _id: _this._.get(user, '_id', ''),
                                type: 'user',
                                organizationId: _this.user.account._id,
                                roles: (user.roles || []).join(', '),
                                display: fullName,
                                position: position,
                            };
                        });
                        this.fixedMembers = [this.membersHasAccess.find(function (member) { return member._id === _this.user._id; })];
                        _a = this;
                        return [4, this.ScoutingService.getScoutingSheet()];
                    case 2:
                        _a.scoutingSheet = _c.sent();
                        _b = this;
                        return [4, this.ScoutingService.getScoutingSheetComments(this.scoutingSheet._id, this.players.map(function (player) { return player.player._id; }), this.scoutingSheet.templates.map(function (tpl) { return tpl._id; }))];
                    case 3:
                        _b.allComments = _c.sent();
                        this.groupComments();
                        return [2];
                }
            });
        });
    };
    CommentsBoardController.prototype.groupComments = function () {
        var _this = this;
        var commentsByPlayer = {};
        this.players.forEach(function (player) {
            var playerComments = _this.allComments.filter(function (comment) {
                return comment.players.some(function (playerWrapper) { return playerWrapper.player._id === player.player._id; });
            });
            playerComments = playerComments
                .map(function (comment) {
                var playerWrapper = comment.players.find(function (playerWrapper) { return playerWrapper.player._id === player.player._id; });
                var commentValueWrapper = (playerWrapper.values || []).find(function (value) { return value.type === 'text_field'; });
                return __assign(__assign({}, comment), { $$commentWrapper: {
                        player: playerWrapper.player,
                        commentText: commentValueWrapper ? commentValueWrapper.value : '',
                    } });
            })
                .filter(function (comment) { return Boolean(comment.$$commentWrapper.commentText); });
            commentsByPlayer[player.player._id] = _.groupBy(playerComments, 'template._id');
        });
        this.commentsByPlayer = commentsByPlayer;
    };
    CommentsBoardController.prototype.addColumn = function () {
        var _this = this;
        var self = this;
        var modal = this.$uibModal.open({
            template: '<comments-board-add-column modal-instance="$ctrl.modalInstance" scouting-sheet="$ctrl.scoutingSheet" />',
            controllerAs: '$ctrl',
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    this.modalInstance = $uibModalInstance;
                    this.scoutingSheet = self.scoutingSheet;
                },
            ],
        });
        modal.result.then(function (updatedScoutingSheet) {
            _this.scoutingSheet = updatedScoutingSheet;
            _this.$onInit();
        }, angular.noop);
    };
    CommentsBoardController.prototype.removeColumn = function (template) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.$promise = this.ScoutingService.removeColumnFromScoutingSheet(this.scoutingSheet._id, template);
                        return [4, this.$promise];
                    case 1:
                        _a.sent();
                        this.$onInit();
                        return [2];
                }
            });
        });
    };
    CommentsBoardController.prototype.addCommentFor = function ($event, player, template) {
        var $button = $($event.currentTarget);
        var $wrapper = $button.closest('.player-comments-cell');
        _.set(this.state, "".concat(player.player._id, ".").concat(template._id), {
            isAdding: true,
            commentText: '',
        });
        this.$timeout(function () {
            $wrapper.find('textarea')[0].focus();
        }, 100);
    };
    CommentsBoardController.prototype.cancelAddComment = function (player, template) {
        _.set(this.state, "".concat(player.player._id, ".").concat(template._id), {
            isAdding: false,
            commentText: '',
        });
    };
    CommentsBoardController.prototype.sendComment = function (player, template) {
        return __awaiter(this, void 0, void 0, function () {
            var commentText, payload, newComment;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        commentText = _.get(this.state, "".concat(player.player._id, ".").concat(template._id, ".commentText"), '');
                        if (!commentText) return [3, 2];
                        _.set(this.state, "".concat(player.player._id, ".").concat(template._id, ".isSending"), true);
                        payload = {
                            template: {
                                _id: template._id,
                                name: template.name,
                            },
                            player: {
                                team: player.player.latestPlayerStats.team,
                                jerseyNumber: player.player.latestPlayerStats.jerseyNumber,
                                player: _.pick(player.player, [
                                    '_id',
                                    'firstName',
                                    'lastName',
                                    'imageUrl',
                                    'playerPosition',
                                    'dateOfBirth',
                                    'height',
                                    'weight',
                                    'shoots',
                                    'contract',
                                    'country',
                                ]),
                            },
                            comment: commentText,
                        };
                        return [4, this.ScoutingService.createScoutingSheetComment(this.scoutingSheet._id, payload)];
                    case 1:
                        newComment = _a.sent();
                        this.allComments = __spreadArray([newComment], this.allComments, true);
                        this.groupComments();
                        _a.label = 2;
                    case 2:
                        _.set(this.state, "".concat(player.player._id, ".").concat(template._id), {
                            isSending: false,
                            isAdding: false,
                            commentText: '',
                        });
                        return [2];
                }
            });
        });
    };
    CommentsBoardController.prototype.updateComment = function (comment) {
        return __awaiter(this, void 0, void 0, function () {
            var currentComment, newComment, playerWrapper, commentValueWrapper;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        currentComment = _.get(comment, '$$commentWrapper.commentText', '');
                        newComment = (comment.$$newComment || '').trim();
                        if (!(newComment && newComment !== currentComment)) return [3, 2];
                        playerWrapper = comment.players.find(function (pw) { return pw.player._id === comment.$$commentWrapper.player._id; });
                        commentValueWrapper = (playerWrapper.values || []).find(function (value) { return value.type === 'text_field'; });
                        if (commentValueWrapper) {
                            _.set(commentValueWrapper, 'value', newComment);
                        }
                        delete comment.$$commentWrapper;
                        delete comment.$$isEditing;
                        delete comment.$$newComment;
                        return [4, this.ScoutingService.triggerChangedReportSave(comment)];
                    case 1:
                        _a.sent();
                        comment.$$commentWrapper = {
                            player: playerWrapper.player,
                            commentText: commentValueWrapper ? commentValueWrapper.value : '',
                        };
                        _a.label = 2;
                    case 2:
                        comment.$$isEditing = false;
                        return [2];
                }
            });
        });
    };
    CommentsBoardController.prototype.showDeleteConfirmation = function (comment) {
        var _this = this;
        var modal = this._showConfirmationModal('scouting.Comments_board_delete_comment_confirm');
        modal.result.then(function () {
            _this.deleteComment(comment);
        }, angular.noop);
    };
    CommentsBoardController.prototype.deleteComment = function (comment) {
        return __awaiter(this, void 0, void 0, function () {
            var idx;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        idx = this.allComments.findIndex(function (c) { return c._id === comment._id; });
                        return [4, new this.ScoutingReportResource(comment).$delete()];
                    case 1:
                        _a.sent();
                        this.allComments = this.allComments.filter(function (item, i) { return i !== idx; });
                        this.groupComments();
                        return [2];
                }
            });
        });
    };
    CommentsBoardController.prototype.grantAccess = function (members) {
        var _this = this;
        var additions = this._.differenceBy(members, this.membersHasAccess, '_id');
        var removals = this._.differenceBy(this.membersHasAccess, members, '_id');
        if (additions.length) {
            var translatedMessage = this.$filter('scTranslate')('scouting.Comments_board_grant_access_confirm', {
                userName: additions[0].display,
            });
            var modal = this._showConfirmationModal('', translatedMessage);
            modal.result.then(function () {
                _this.$promise = _this.ScoutingService.grantOrRevokeScoutingSheetAccess(_this.scoutingSheet._id, {
                    add: additions,
                })
                    .then(function () {
                    _this.membersHasAccess = members;
                })
                    .catch(function (err) {
                    _this.Toastr.error('Something wrong!');
                });
            }, angular.noop);
        }
        else if (removals.length) {
            var translatedMessage = this.$filter('scTranslate')('scouting.Comments_board_remove_access_confirm', {
                userName: removals[0].display,
            });
            var modal = this._showConfirmationModal('', translatedMessage);
            modal.result.then(function () {
                _this.$promise = _this.ScoutingService.grantOrRevokeScoutingSheetAccess(_this.scoutingSheet._id, {
                    remove: removals,
                })
                    .then(function () {
                    _this.membersHasAccess = members;
                })
                    .catch(function (err) {
                    _this.Toastr.error('Something wrong!');
                });
            }, angular.noop);
        }
    };
    CommentsBoardController.prototype.close = function () {
        this.modalInstance.dismiss();
    };
    CommentsBoardController.prototype._showConfirmationModal = function (message, translatedMessage) {
        return this.$uibModal.open({
            template: '<confirmation-modal modal-instance="$ctrl.modalInstance" message="$ctrl.message" translated-message="$ctrl.translatedMessage"/>',
            controllerAs: '$ctrl',
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    this.modalInstance = $uibModalInstance;
                    this.message = message;
                    this.translatedMessage = translatedMessage;
                },
            ],
        });
    };
    CommentsBoardController.prototype.hasNamePredicate = function (selectedLabel) {
        return function (selectedLabel) { return !!selectedLabel.label; };
    };
    return CommentsBoardController;
}());
angular.module('app.scouting').component('commentsBoard', {
    templateUrl: 'scouting/components/modals/comments-board.html',
    controller: CommentsBoardController,
    bindings: {
        players: '<',
        modalInstance: '<',
    },
});