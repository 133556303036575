var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ScoutingReportGameViewController = (function () {
    function ScoutingReportGameViewController($scope) {
        this.$scope = $scope;
    }
    ScoutingReportGameViewController.prototype.getReportAttachmentHolder = function (teamId) {
        var holder = this.report.attachments && this.report.attachments.find(function (holder) { return holder.team === teamId; });
        return holder || this.createHolder(teamId);
    };
    ScoutingReportGameViewController.prototype.createHolder = function (teamId) {
        return { team: teamId, list: [] };
    };
    __decorate([
        CacheReturnValue(function (context) { return context.$scope; }),
        OnReturnObjectChangedOnce(function (context) { return context.$scope; }, function (context, holder, _a) {
            context.report.attachments = context.report.attachments || [];
            context.report.attachments.push(holder);
        })
    ], ScoutingReportGameViewController.prototype, "createHolder", null);
    return ScoutingReportGameViewController;
}());
angular.module('app.scouting').component('scoutingReportGameView', {
    templateUrl: 'scouting/components/report/game-view.html',
    controller: ScoutingReportGameViewController,
    bindings: {
        report: '<',
        editable: '<',
    },
});