class ScoutingViewReportController {
  private $reportResource;
  private $scoutingService;

  private reportId;
  private playerId;
  private report;
  private promise;
  private selectedPlayer;
  private isGameSelected: boolean;
  private attachedTaggingLists;
  private editable;
  private trackingRecordId;
  private gameReportLineups = {
    home: {},
    away: {},
  };
  private loadingGameReportLineups;

  constructor(
    private $scope,
    private $q,
    private $state,
    ScoutingService,
    private GameService,
  ) {
    this.$scoutingService = ScoutingService;
  }

  $onInit() {
    if (this.editable) {
      this.$state.go('root.scouting.report', {edit: null}, {notify: false});
    }

    this.fetchReport();

    this.$scope.$on('$destroy', () => {
      this.$scoutingService.triggerChangedReportSave(this.report);
    });

    this.$scope.$on('beforeunload', () => {
      this.$scoutingService.triggerChangedReportSave(this.report);
    });

    this.$scope.$on(
      'scouting:element:changed',
      _.debounce(() => {
        this.$scoutingService.triggerChangedReportSave(this.report);
      }, 1000),
    );
  }

  fetchReport() {
    this.report = this.$scoutingService.fetchReport(this.reportId);

    this.report.$promise
      .then(() => {
        if (this.playerId) {
          this.selectedPlayer = this.report.players.find(
            (wrapper) =>
              (wrapper.player._id === this.playerId ||
                wrapper.player.temporaryId === this.playerId) &&
              wrapper.selected,
          );
        } else {
          this.selectedPlayer = this.$scoutingService.chooseSelectedPlayer(this.report);
        }
      })
      .then(() => {
        this.fetchReportLineups();
        this.$scoutingService
          .recordScoutingReportTracking(this.selectedPlayer.player, this.report._id)
          .then((res) => {
            this.trackingRecordId = res.data.trackingRecordId;
          });
      });
  }
  fetchReportLineups() {
    if (!this.report.game) {
      return;
    }
    const {home, away, date} = this.report.game;
    this.loadingGameReportLineups = true;
    this.$q
      .all([
        this.GameService.getGameReport(home._id, away._id, date),
        this.GameService.getGameLineupReport(home._id, away._id, date),
      ])
      .then(([gameReport, gameLineupReport]) => {
        this.loadingGameReportLineups = false;
        this.gameReportLineups = this.$scoutingService.calculateGameReportLineup(
          gameReport,
          gameLineupReport,
        );
      });
  }

  showAddLabelModal() {
    this.$scoutingService.requestAddingToDepartment(this.selectedPlayer.player, () => {
      this.$state.go(this.$state.$current, null, {reload: true});
    });
  }

  selectGame() {
    this.selectedPlayer = null;
    this.isGameSelected = true;

    this.$scoutingService.triggerChangedReportSave(this.report);
  }

  selectPlayer(playerWrapper) {
    this.isGameSelected = false;
    this.selectedPlayer = playerWrapper;
    if (this.trackingRecordId) {
      this.$scoutingService.updateScoutingReportTracking(
        this.trackingRecordId,
        playerWrapper.player,
      );
    }

    this.$scoutingService.triggerChangedReportSave(this.report);

    if (playerWrapper && this.$state.is('root.scouting.report')) {
      this.$state.go(
        'root.scouting.report',
        {
          id: this.report._id,
          player: this.selectedPlayer.player._id || this.selectedPlayer.player.temporaryId,
        },
        {location: 'replace', notify: false},
      );
    }
  }

  onPlayersUpdated(players, lineup, teamKind) {
    this.report.players = players;

    this.report.players.forEach((wrapper) => {
      if (!wrapper.selected) {
        delete wrapper.values;
        delete wrapper.tags;
      }
    });

    if (this.selectedPlayer) {
      this.selectedPlayer = this.report.players.find(
        (wrapper) =>
          wrapper.player._id === this.selectedPlayer.player._id && wrapper.selected === true,
      );
      this.selectPlayer(this.selectedPlayer);
    }

    if (!this.selectedPlayer && !this.isGameSelected) {
      this.selectedPlayer = this.$scoutingService.chooseSelectedPlayer(this.report);
      this.selectPlayer(this.selectedPlayer);
    }

    if (lineup && teamKind) {
      this.report.game[teamKind + '_lineup'] = lineup;
    }

    this.$scoutingService.triggerChangedReportSave(this.report);
  }
}

angular.module('app.scouting').component('scoutingViewReport', {
  templateUrl: 'scouting/components/view-report.html',
  controller: ScoutingViewReportController as any,
  bindings: {
    reportId: '<',
    playerId: '<',
    editable: '<',
  },
});
