class ScoutingReportSkillTableFieldController {
  private params;
  private value;
  private selectedPlayer;
  private report;
  private loadingPlaylist = null;

  constructor(
    private $scope,
    private $uibModal,
    private GameService,
    private ScoutingService,
    private PlaylistService,
  ) {}

  getValueHolder(skill: {name: string}) {
    const holder = this.value && this.value.find((item) => item.skill === skill.name);

    return (
      holder ||
      this.createHolder({id: `${this.selectedPlayer.player._id}-${this.params.id}`}, skill.name)
    );
  }

  getSkills(player) {
    switch (player.player.playerPosition) {
      case 'GOALIE':
        return this.params.goaliesSkills || this.params.skills;

      default:
        return this.params.skills;
    }
  }

  @CacheReturnValue((context) => context.$scope)
  @OnReturnObjectChangedOnce(
    (context) => context.$scope,
    // eslint-disable-next-line no-empty-pattern
    (context, holder, []) => {
      context.value = context.value || [];
      context.value.push(holder);
    },
  )
  private createHolder(wrapper, skill: string) {
    return {skill};
  }

  notifyAboutChanges() {
    this.$scope.$emit('scouting:element:changed');
  }

  attachPlaylistToSkill(skill) {
    this.$uibModal
      .open({
        size: 'md',
        template: '<playlist-picker-modal modal-instance="$ctrl.modalInstance" multiple="true" />',
        controller: [
          '$uibModalInstance',
          function ($uibModalInstance) {
            this.modalInstance = $uibModalInstance;
          },
        ],
        controllerAs: '$ctrl',
      })
      .result.then((selectedPlaylists) => {
        if (selectedPlaylists.length) {
          const skillValue = this.getValueHolder(skill);
          if (!skillValue.attachedPlaylists) {
            skillValue.attachedPlaylists = [];
          }
          if (
            skillValue.attachedPlaylists.some((p) =>
              selectedPlaylists.some((selectedPlaylist) => selectedPlaylist.id === p.id),
            )
          ) {
            return;
          }
          selectedPlaylists.forEach((p) => {
            skillValue.attachedPlaylists.push({
              id: p.id,
              name: p.name,
            });
          });
          this.notifyAboutChanges();
        }
      });
  }

  removePlaylist(skill, playlist) {
    const skillValue = this.getValueHolder(skill);
    if (skillValue.attachedPlaylists) {
      skillValue.attachedPlaylists = skillValue.attachedPlaylists.filter(
        (p) => p.id !== playlist.id,
      );
      this.notifyAboutChanges();
    }
  }

  isLoadingPlaylist(playlist, skill) {
    if (!this.loadingPlaylist) {
      return false;
    }
    return (
      this.loadingPlaylist.playlistId === playlist.id && this.loadingPlaylist.skill === skill.name
    );
  }

  playPlaylist(attachedPlaylist) {
    this.GameService.openNextGenScoutingPlaylist(this.report._id, attachedPlaylist.id);
  }
}

angular.module('app.scouting').component('scoutingReportFieldSkillTable', {
  templateUrl: 'scouting/components/field/skill-table.html',
  controller: ScoutingReportSkillTableFieldController,
  bindings: {
    params: '<',
    value: '=',
    editable: '<',
    selectedPlayer: '<',
    compact: '<',
    report: '<',
  },
});
