class ScoutingReportRatingDataController {
  private skill;
  private value;
  private dateInputFormat: string;

  constructor(
    UserService,
    private $scope,
  ) {
    this.dateInputFormat = UserService.getUser().settings.dateformat;
  }

  $onInit() {
    this.$scope.$watch(
      () => this.value,
      () => {
        if (this.skill.dataCommentType == 'date' && this.value && !(this.value instanceof Date)) {
          this.value = new Date(this.value);
        }
      },
    );
  }

  public notifyAboutChanges() {
    this.$scope.$emit('scouting:element:changed');
  }
}

angular.module('app.scouting').component('scoutingReportRatingData', {
  templateUrl: 'scouting/components/field/partials/rating-data.html',
  controller: ScoutingReportRatingDataController,
  bindings: {
    skill: '<',
    editable: '<',
    value: '=',
  },
});
