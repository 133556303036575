var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var VALID_ELEMENT_TYPES = ['checkbox', 'radio_button', 'total', 'datepicker'];
var ReportsComparisionModalController = (function () {
    function ReportsComparisionModalController($element, $filter, $q, ScoutingReportResource, $sce) {
        this.$element = $element;
        this.$filter = $filter;
        this.$q = $q;
        this.ScoutingReportResource = ScoutingReportResource;
        this.$sce = $sce;
        this.getAverageColorIndex = function (avgValue, sortedScales) {
            if (sortedScales.length == 0) {
                return 0;
            }
            if (!_.isNumber(avgValue)) {
                return null;
            }
            var nearestIdx = _.findLastIndex(sortedScales, function (scale) { return scale.val <= avgValue; });
            if (nearestIdx < sortedScales.length - 1) {
                var middleValue = (sortedScales[nearestIdx + 1].val - sortedScales[nearestIdx].val) / 2 +
                    sortedScales[nearestIdx].val;
                if (avgValue >= middleValue) {
                    nearestIdx = nearestIdx + 1;
                }
            }
            return nearestIdx;
        };
        this.syncScroll = this.syncScroll.bind(this);
        this.$domCache = {};
        this.colorizeRating = true;
        this.showComments = true;
        this.showAllElements = true;
        this.showGraphView = false;
        this.avgFilter = $filter('scoutingRatingAvg');
        this.sumFilter = $filter('scoutingRatingSum');
        this.colorFilter = $filter('scColorTransition');
        this.showPublicReports = true;
    }
    ReportsComparisionModalController.prototype.$onInit = function () {
        this.fetchReports();
    };
    ReportsComparisionModalController.prototype.fetchReports = function (forceFetch) {
        var _this = this;
        if (forceFetch === void 0) { forceFetch = false; }
        if (!this.reports || !this.reports.length || forceFetch) {
            var params = {
                skip: 0,
                limit: 1000,
                sort: 'createdAt',
                direction: -1,
                showPublic: this.showPublicReports ? '1' : '-1',
            };
            var filters = [
                {
                    criterion: 'player',
                    field: 'players.player',
                    negative: false,
                    value: {
                        or: this.players.map(function (player) { return ({ _id: player._id }); }),
                    },
                },
            ];
            this.$promise = this.ScoutingReportResource.query(Object.assign({}, params, { type: 'players' }), {
                filters: filters,
            }).$promise.then(function (reports) {
                return (_this.reports = reports.map(function (report) {
                    report.players = [report.players];
                    return report;
                }));
            });
        }
        else {
            this.$promise = this.$q.resolve();
        }
        this.$promise.then(function () {
            var reportsByTemplates = _.groupBy(_this.reports, 'template._id');
            var templateSnapshotsById = _.groupBy(_this.reports.map(function (rep) { return rep.template; }), '_id');
            var uniqTemplates = _.uniqBy(_this.reports.map(function (rep) { return rep.template; }), '_id');
            _this.templates = uniqTemplates.map(function (tpl) {
                var reports = reportsByTemplates[tpl._id];
                tpl.reportsByPlayer = _this.createReportsByPlayer(reports);
                tpl.allSkills = _this.getAllTplSkills(templateSnapshotsById[tpl._id]);
                return tpl;
            });
            _this.templates.map(function (tpl) {
                tpl.isVisible =
                    tpl.allSkills.length > 0 ||
                        (_this.showComments
                            ? (tpl.elements || []).some(function (el) { return el.type === 'text_field'; })
                            : false) ||
                        (_this.showAllElements ? (tpl.elements || []).length : false);
                return tpl;
            });
        });
    };
    ReportsComparisionModalController.prototype.onToggleIncludeFeatureReports = function () {
        this.fetchReports(true);
    };
    ReportsComparisionModalController.prototype.getAllTplElements = function (template) {
        return _.flatten((template.elements || []).map(function (el) {
            return el.type === 'template_chapter'
                ? el.elements.map(function (childEl) { return (__assign(__assign({}, childEl), { id: "".concat(el.id, ".").concat(childEl.id) })); })
                : [el];
        }));
    };
    ReportsComparisionModalController.prototype.getAllTplSkills = function (templateSnapshots) {
        var _this = this;
        return _.chain(templateSnapshots)
            .map(function (el) { return _this.getAllTplElements(el); })
            .flatten()
            .filter(function (el) { return el.type === 'skill_table'; })
            .map(this.mapElementToSkills)
            .flatten()
            .uniqWith(function (a, b) {
            return a.$$elId + a.$$lookupKey + a.$$position === b.$$elId + b.$$lookupKey + b.$$position;
        })
            .value();
    };
    ReportsComparisionModalController.prototype.mapElementToSkills = function (el) {
        var skills = el.skills.map(function (skill) { return (__assign(__assign({}, skill), { $$aggregateType: el.aggregateType, $$lookupKey: skill.name, $$elScales: el.scales, $$elType: el.type, $$elId: el.id, $$elRatingType: el.ratingType, $$position: el.goaliesCustom ? 'skater' : 'skater+goalie' })); });
        if (el.goaliesCustom) {
            skills = skills.concat((el.goaliesSkills || []).map(function (skill) { return (__assign(__assign({}, skill), { $$aggregateType: el.aggregateType, $$lookupKey: skill.name, $$elScales: el.scales, $$elType: el.type, $$elId: el.id, $$elRatingType: el.ratingType, $$position: 'goalie' })); }));
        }
        if (el.allowOverallComment) {
            skills.unshift({
                name: "* Overall ".concat(el.name),
                $$lookupKey: '__overal__',
                $$elScales: null,
                $$elType: el.type,
                $$elId: el.id,
                $$elRatingType: 'text_comments',
                $$position: 'skater+goalie',
            });
        }
        if (el.allowOverallRating) {
            skills.unshift({
                name: "* Overall ".concat(el.name),
                $$lookupKey: '__overall__',
                $$elScales: el.overallRatingScales,
                $$elType: el.type,
                $$elId: el.id,
                $$elRatingType: el.overallRatingType,
                $$position: 'skater+goalie',
            });
        }
        if (el.aggregateData) {
            var aggregateColumn = {
                $$elScales: el.scales,
                $$elType: el.type,
                $$elId: el.id,
                $$elRatingType: el.ratingType,
                $$position: 'skater+goalie',
                includedForAggregation: false,
            };
            if (el.aggregateType === 'average') {
                aggregateColumn.$$lookupKey = '__average__';
                aggregateColumn.description = aggregateColumn.name = 'Average';
            }
            if (el.aggregateType === 'sum') {
                aggregateColumn.$$lookupKey = '__sum__';
                aggregateColumn.description = aggregateColumn.name = 'Sum';
            }
            skills.unshift(aggregateColumn);
        }
        return skills;
    };
    ReportsComparisionModalController.prototype.createReportsByPlayer = function (reports) {
        var _this = this;
        var reportsByPlayer = {};
        reports.forEach(function (report) {
            report.players.forEach(function (player) {
                if (player.selected) {
                    reportsByPlayer[player.player._id] = reportsByPlayer[player.player._id] || [];
                    var valueHolder = __assign(__assign({}, player), { reportId: report._id, isPublic: report.isPublic, organization: report.organization, game: report.game, createdAt: report.createdAt, createdBy: report.createdBy, values: {} });
                    var allTplSnapshotElements_1 = _this.getAllTplElements(report.template);
                    valueHolder.values = (player.values || [])
                        .filter(function (value) { return value.type === 'skill_table'; })
                        .reduce(function (acc, value) {
                        var skillMap = _.keyBy(value.value, 'skill');
                        var elementSnapshot = allTplSnapshotElements_1.find(function (el) { return el.id === value.id && el.type === value.type; });
                        skillMap = _.mapValues(skillMap, function (valueItem, key) {
                            var val = valueItem.value;
                            if (typeof val === 'undefined' || val === null || val === '') {
                                valueItem.__color = '#fff';
                            }
                            else if (key === '__overall__') {
                                var scales = elementSnapshot.overallRatingScales || elementSnapshot.scales;
                                valueItem.__color =
                                    scales && scales.length
                                        ? _this.colorFilter(_this.getValueIndex(scales, val), scales.length, elementSnapshot.reverseRatingColor, 0.7)
                                        : '#fff';
                            }
                            else if (elementSnapshot.ratingType === 'data_comments') {
                                valueItem.__color = '#fff';
                            }
                            else {
                                valueItem.__color = _this.colorFilter(_this.getValueIndex(elementSnapshot.scales, val), elementSnapshot.scales.length, elementSnapshot.reverseRatingColor, 0.7);
                            }
                            return valueItem;
                        });
                        if (elementSnapshot.aggregateData) {
                            var ratingValues = _.toArray(skillMap);
                            var snapshotSkills = elementSnapshot.goaliesCustom
                                ? elementSnapshot.goaliesSkills.concat(elementSnapshot.skills)
                                : elementSnapshot.skills;
                            var ratingMeasures = (snapshotSkills || []).filter(function (s) { return s.includedForAggregation; });
                            var scales = elementSnapshot.scales || [];
                            var avgValue = _this.avgFilter(ratingValues, ratingMeasures);
                            var sortedScales = _.sortBy(scales, 'val');
                            var colorIndex = _this.getAverageColorIndex(avgValue, sortedScales);
                            var __color = _.isNumber(colorIndex) && scales
                                ? _this.colorFilter(colorIndex, scales.length, false, 0.7)
                                : '#fff';
                            if (elementSnapshot.aggregateType === 'average') {
                                skillMap.__average__ = {
                                    __color: __color,
                                    skill: 'Average',
                                    value: avgValue,
                                };
                            }
                            if (elementSnapshot.aggregateType === 'sum') {
                                skillMap.__sum__ = {
                                    __color: __color,
                                    skill: 'Sum',
                                    value: _this.sumFilter(ratingValues, ratingMeasures),
                                };
                            }
                        }
                        acc["".concat(value.type, "-").concat(value.id)] = skillMap;
                        return acc;
                    }, {});
                    valueHolder.values.textFields = (player.values || [])
                        .filter(function (value) { return value.type === 'text_field'; })
                        .map(function (textField) {
                        var elementDef = report.template.elements.find(function (el) { return el.id === textField.id; });
                        if (elementDef) {
                            textField.name = elementDef.name;
                        }
                        return textField;
                    });
                    valueHolder.values.allElements = (player.values || [])
                        .filter(function (value) { return VALID_ELEMENT_TYPES.includes(value.type); })
                        .map(function (element) {
                        var elementDef = report.template.elements.find(function (el) { return el.id === element.id; });
                        if (elementDef) {
                            element.name = elementDef.name;
                        }
                        return element;
                    });
                    reportsByPlayer[player.player._id].push(valueHolder);
                }
            });
        });
        return reportsByPlayer;
    };
    ReportsComparisionModalController.prototype.onShowCommentsChanged = function () {
        var _this = this;
        this.templates.map(function (tpl) {
            tpl.isVisible =
                tpl.allSkills.length > 0 ||
                    (_this.showComments ? (tpl.elements || []).some(function (el) { return el.type === 'text_field'; }) : false);
            return tpl;
        });
    };
    ReportsComparisionModalController.prototype.onShowAllElementsChanged = function () {
        var _this = this;
        this.templates.map(function (tpl) {
            tpl.isVisible =
                tpl.allSkills.length > 0 ||
                    (_this.showComments ? (tpl.elements || []).some(function (el) { return el.type === 'text_field'; }) : false) ||
                    (_this.showAllElements ? (tpl.elements || []).length : false);
            return tpl;
        });
    };
    ReportsComparisionModalController.prototype.$postLink = function () {
        document.addEventListener('scroll', this.syncScroll, true);
    };
    ReportsComparisionModalController.prototype.$onDestroy = function () {
        document.removeEventListener('scroll', this.syncScroll, true);
    };
    ReportsComparisionModalController.prototype.syncScroll = function (e) {
        var $target = angular.element(e.target);
        if (!$target.hasClass('table-scrolling-body')) {
            return;
        }
        var templateId = $target.attr('data-template-id');
        var leftDistance = $target.scrollLeft();
        var $scrollingElements = this.$domCache[templateId];
        if (!$scrollingElements) {
            this.$domCache[templateId] = this.$element.find("[data-template-id=\"".concat(templateId, "\"]"));
            $scrollingElements = this.$domCache[templateId];
        }
        $scrollingElements.scrollLeft(leftDistance);
    };
    ReportsComparisionModalController.prototype.getValueIndex = function (scales, value) {
        return (scales || []).findIndex(function (item) { return item.val === value; });
    };
    ReportsComparisionModalController.prototype.close = function () {
        this.$uibModal.dismiss();
    };
    ReportsComparisionModalController.prototype.skillFilter = function (player) {
        return function (skill) {
            if (player.playerPosition === 'GOALIE') {
                return skill.$$position === 'goalie' || skill.$$position === 'skater+goalie';
            }
            return skill.$$position === 'skater' || skill.$$position === 'skater+goalie';
        };
    };
    ReportsComparisionModalController.prototype.getTooltip = function (lookupKey, elId, value, allSkills) {
        if (!value) {
            return '-';
        }
        if (lookupKey === '__average__' || lookupKey === '__sum__') {
            var aggregatedSkills = allSkills.filter(function (s) {
                return s.includedForAggregation &&
                    s.$$elId === elId &&
                    s.$$lookupKey !== lookupKey &&
                    s.$$aggregateType === lookupKey.replace(/_/g, '');
            });
            var skillNames = aggregatedSkills.map(function (s) { return s.name; }).join(', ');
            return this.$filter('scTranslate')("scouting.Skill_table_".concat(lookupKey, "_skill_tooltip"), {
                number: value,
                skillNames: skillNames,
            });
        }
        return value;
    };
    ReportsComparisionModalController.prototype.getReportValue = function (playerReport, skill) {
        try {
            var $$elType = skill.$$elType, $$elId = skill.$$elId, $$lookupKey = skill.$$lookupKey;
            return playerReport.values[$$elType + '-' + $$elId][$$lookupKey];
        }
        catch (_a) {
            return null;
        }
    };
    ReportsComparisionModalController.prototype.getTooltipHTML = function (value) {
        return this.$sce.trustAsHtml(value);
    };
    return ReportsComparisionModalController;
}());
angular.module('app.scouting').component('modalReportsComparision', {
    templateUrl: 'scouting/components/modals/reports-comparision.html',
    controller: ReportsComparisionModalController,
    bindings: {
        reports: '<',
        players: '<',
        $uibModal: '<modalInstance',
    },
});
;