const DEFAULT_PAGINATION_SIZE = 10;
const UNLIMITED_PAGINATION_SIZE = 999999;

interface LabelPlayer {
  _id: string;
  rank: number;
  labels: Label[];
  player: {_id: string; firstName: string; lastName: string};
}

class ScoutingDepartmentPlayersController {
  private $labelsResource;
  private $labelsService;
  private $paginator;

  private selectedLabel: Label;
  private availableLabels: Label[];
  private onChange: () => void;

  private promise: Promise<any>;
  private paginator;
  private hasRankSorting: boolean;
  private uiTreeConfig;

  constructor(
    private $timeout,
    private ScoutingService,
    ScoutingLabelsResource,
    ScoutingLabelsService,
    Paginator,
  ) {
    this.$labelsResource = ScoutingLabelsResource;
    this.$labelsService = ScoutingLabelsService;
    this.$paginator = Paginator;
  }

  public $onInit() {
    this.uiTreeConfig = {
      dragStart: (event) => {
        event.elements.placeholder.append('<td colspan="9999">');
      },

      dropped: (event) => {
        // stupid lib is doing array manipulations in $timeout, so we also have to wait...
        this.$timeout(() => this.populateRanks());
      },
    };
  }

  public $onChanges(changes) {
    if (changes.selectedLabel) {
      this.hasRankSorting = !!this.selectedLabel && !!this.selectedLabel.hasRanks;

      this.paginator = this.$paginator.getInstance({
        sortBy: this.hasRankSorting ? 'rank' : 'player.lastName',

        strategy: changes.selectedLabel.currentValue
          ? 'PaginatorNormalStrategy'
          : 'PaginatorPreloadStrategy',
        limit: changes.selectedLabel.currentValue
          ? UNLIMITED_PAGINATION_SIZE
          : DEFAULT_PAGINATION_SIZE,

        pagingFunction: (params) =>
          this.$labelsResource.queryPlayers(
            Object.assign({id: _.get(changes.selectedLabel.currentValue, '_id')}, params),
          ).$promise,
      });
    }

    if (changes.availableLabels) {
      const availableLabels = changes.availableLabels.currentValue;

      if (this.paginator.data && this.paginator.data.length) {
        this.paginator.data = this.paginator.data.map((item) => {
          item.labels = (item.labels || []).map(
            (label) => availableLabels.find((avlb) => avlb._id === label._id) || label,
          );
          return item;
        });
      }
    }
  }

  public populateRanks() {
    let list = this.paginator.data;

    if (
      this.paginator.paginationModel.direction === -1 &&
      this.paginator.paginationModel.sort === 'rank'
    ) {
      list = list.slice().reverse();
    }

    this.hasRankSorting = true;
    this.paginator.paginationModel.sort = 'rank';

    const ranks = list.map((item, index) => {
      item.rank = index;
      return {player: item._id, rank: item.rank};
    });

    this.$labelsResource.updateRanks({id: this.selectedLabel._id}, {ranks});
  }

  public hasNamePredicate() {
    return (selectedLabel) => !!selectedLabel.label;
  }

  get isAllPlayersSelected() {
    return this.paginator.data.every((item) => item.$$selected);
  }

  set isAllPlayersSelected(value: boolean) {
    if (value) {
      this.paginator.data.forEach((item) => (item.$$selected = true));
    } else {
      this.paginator.data.forEach((item) => (item.$$selected = false));
    }
  }

  public applyPlayersChanges(changes: LabelPlayersChange[]) {
    this.promise = this.$labelsService
      .applyChanges(this.availableLabels, changes)
      .then(() => {
        this.paginator.reset();
        this.onChange();

        return this.paginator.lastPromise;
      })
      .then(() => (this.promise = null));
  }

  public compareReports() {
    const players = this.paginator.data
      .filter((item) => item.$$selected)
      .map((item) => {
        return {
          ...item.player,
          user: item.user, // We need the user here to show the contact popup
        };
      });
    this.ScoutingService.compareReports(players, []);
  }

  public showCommentsBoard() {
    this.ScoutingService.showCommentsBoard(this.paginator.data);
  }

  public createNewReport() {
    this.ScoutingService.requestReportCreation();
  }
}

angular.module('app.scouting').component('scoutingDepartmentPlayers', {
  templateUrl: 'scouting/components/department-players.html',
  controller: ScoutingDepartmentPlayersController,

  bindings: {
    selectedLabel: '<',
    availableLabels: '<',
    onChange: '&',
    onCreateLabel: '&',
  },
});
