class ScoutingCollectionListsController {
  private selectedCollection;
  private selectedListIdx;
  private paginators;

  constructor(
    ScoutingService,
    private $state,
  ) {
    this.paginators = [];
    this.selectedListIdx = -1;
  }

  $onChanges(changes) {
    const params = this.$state.params;
    const collection = changes.selectedCollection.currentValue;
    if (collection) {
      this.paginators = [];
      this.selectedListIdx = collection.lists.findIndex((list) => list._id === params.listId);
      if (this.selectedListIdx < 0) {
        this.selectedListIdx = 0;
      }
    }
  }

  get selectedList() {
    return this.selectedCollection.lists[this.selectedListIdx];
  }

  get paginator() {
    return this.paginators[this.selectedListIdx];
  }

  setPaginator(value) {
    this.paginators[this.selectedListIdx] = value;
  }

  switchTab(collectionId, listId) {
    this.$state.go('root.scouting.collections.lists.players', {
      id: collectionId,
      listId: listId,
    });
  }
}

angular.module('app.scouting').component('scoutingCollectionLists', {
  templateUrl: 'scouting/components/collection-lists.html',
  controller: ScoutingCollectionListsController,
  controllerAs: '$collectionListCtrl',
  bindings: {
    selectedCollection: '<',
    availableLabels: '<',
    onCreateLabel: '&',
  },
});
