class ScoutingLineupModalController {
  private $uibModal;

  cancel() {
    this.$uibModal.dismiss();
  }
}

angular.module('app.scouting').component('modalLineup', {
  templateUrl: 'scouting/components/modals/lineup.html',
  controller: ScoutingLineupModalController,
  bindings: {
    lineup: '<',
    players: '<',
    gameReportLineup: '<',
    team: '<',
    readonly: '<',
    $uibModal: '<modalInstance',
  },
});
