var ScoutingViewReportController = (function () {
    function ScoutingViewReportController($scope, $q, $state, ScoutingService, GameService) {
        this.$scope = $scope;
        this.$q = $q;
        this.$state = $state;
        this.GameService = GameService;
        this.gameReportLineups = {
            home: {},
            away: {},
        };
        this.$scoutingService = ScoutingService;
    }
    ScoutingViewReportController.prototype.$onInit = function () {
        var _this = this;
        if (this.editable) {
            this.$state.go('root.scouting.report', { edit: null }, { notify: false });
        }
        this.fetchReport();
        this.$scope.$on('$destroy', function () {
            _this.$scoutingService.triggerChangedReportSave(_this.report);
        });
        this.$scope.$on('beforeunload', function () {
            _this.$scoutingService.triggerChangedReportSave(_this.report);
        });
        this.$scope.$on('scouting:element:changed', _.debounce(function () {
            _this.$scoutingService.triggerChangedReportSave(_this.report);
        }, 1000));
    };
    ScoutingViewReportController.prototype.fetchReport = function () {
        var _this = this;
        this.report = this.$scoutingService.fetchReport(this.reportId);
        this.report.$promise
            .then(function () {
            if (_this.playerId) {
                _this.selectedPlayer = _this.report.players.find(function (wrapper) {
                    return (wrapper.player._id === _this.playerId ||
                        wrapper.player.temporaryId === _this.playerId) &&
                        wrapper.selected;
                });
            }
            else {
                _this.selectedPlayer = _this.$scoutingService.chooseSelectedPlayer(_this.report);
            }
        })
            .then(function () {
            _this.fetchReportLineups();
            _this.$scoutingService
                .recordScoutingReportTracking(_this.selectedPlayer.player, _this.report._id)
                .then(function (res) {
                _this.trackingRecordId = res.data.trackingRecordId;
            });
        });
    };
    ScoutingViewReportController.prototype.fetchReportLineups = function () {
        var _this = this;
        if (!this.report.game) {
            return;
        }
        var _a = this.report.game, home = _a.home, away = _a.away, date = _a.date;
        this.loadingGameReportLineups = true;
        this.$q
            .all([
            this.GameService.getGameReport(home._id, away._id, date),
            this.GameService.getGameLineupReport(home._id, away._id, date),
        ])
            .then(function (_a) {
            var gameReport = _a[0], gameLineupReport = _a[1];
            _this.loadingGameReportLineups = false;
            _this.gameReportLineups = _this.$scoutingService.calculateGameReportLineup(gameReport, gameLineupReport);
        });
    };
    ScoutingViewReportController.prototype.showAddLabelModal = function () {
        var _this = this;
        this.$scoutingService.requestAddingToDepartment(this.selectedPlayer.player, function () {
            _this.$state.go(_this.$state.$current, null, { reload: true });
        });
    };
    ScoutingViewReportController.prototype.selectGame = function () {
        this.selectedPlayer = null;
        this.isGameSelected = true;
        this.$scoutingService.triggerChangedReportSave(this.report);
    };
    ScoutingViewReportController.prototype.selectPlayer = function (playerWrapper) {
        this.isGameSelected = false;
        this.selectedPlayer = playerWrapper;
        if (this.trackingRecordId) {
            this.$scoutingService.updateScoutingReportTracking(this.trackingRecordId, playerWrapper.player);
        }
        this.$scoutingService.triggerChangedReportSave(this.report);
        if (playerWrapper && this.$state.is('root.scouting.report')) {
            this.$state.go('root.scouting.report', {
                id: this.report._id,
                player: this.selectedPlayer.player._id || this.selectedPlayer.player.temporaryId,
            }, { location: 'replace', notify: false });
        }
    };
    ScoutingViewReportController.prototype.onPlayersUpdated = function (players, lineup, teamKind) {
        var _this = this;
        this.report.players = players;
        this.report.players.forEach(function (wrapper) {
            if (!wrapper.selected) {
                delete wrapper.values;
                delete wrapper.tags;
            }
        });
        if (this.selectedPlayer) {
            this.selectedPlayer = this.report.players.find(function (wrapper) {
                return wrapper.player._id === _this.selectedPlayer.player._id && wrapper.selected === true;
            });
            this.selectPlayer(this.selectedPlayer);
        }
        if (!this.selectedPlayer && !this.isGameSelected) {
            this.selectedPlayer = this.$scoutingService.chooseSelectedPlayer(this.report);
            this.selectPlayer(this.selectedPlayer);
        }
        if (lineup && teamKind) {
            this.report.game[teamKind + '_lineup'] = lineup;
        }
        this.$scoutingService.triggerChangedReportSave(this.report);
    };
    return ScoutingViewReportController;
}());
angular.module('app.scouting').component('scoutingViewReport', {
    templateUrl: 'scouting/components/view-report.html',
    controller: ScoutingViewReportController,
    bindings: {
        reportId: '<',
        playerId: '<',
        editable: '<',
    },
});