var ScoutingService = (function () {
    function ScoutingService($uibModal, $state, $http, SCConfiguration, ScoutingReportResource, PendingActionsService) {
        this.$uibModal = $uibModal;
        this.$state = $state;
        this.$http = $http;
        this.$scoutingReportResource = ScoutingReportResource;
        this.$pendingActionsService = PendingActionsService;
        this.temporaryTeamsEndpoint = SCConfiguration.getEndpoint() + '/api/scouting/temporary-teams';
        this.temporaryLeaguesEndpoint =
            SCConfiguration.getEndpoint() + '/api/scouting/temporary-leagues';
        this.temporaryPeopleEndpoint = SCConfiguration.getEndpoint() + '/api/scouting/temporary-people';
        this.scoutingSheetsEndpoint = SCConfiguration.getEndpoint() + '/api/scouting/sheets';
        this.scoutingReportEndpoint = SCConfiguration.getEndpoint() + '/api/scouting/reports';
    }
    ScoutingService.prototype.requestReportCreation = function () {
        this.openReportCreationModal({});
    };
    ScoutingService.prototype.requestLeagueReportCreation = function (league) {
        this.openReportCreationModal({
            league: league,
        });
    };
    ScoutingService.prototype.requestTeamReportCreation = function (team, league) {
        this.openReportCreationModal({
            team: team,
            league: league,
        });
    };
    ScoutingService.prototype.requestPlayerReportCreation = function (playerId, team, league) {
        this.openReportCreationModal({
            team: team,
            league: league,
            playerIds: [playerId],
        });
    };
    ScoutingService.prototype.requestAddingToDepartment = function (player, callback) {
        var modal = this.$uibModal.open({
            template: '<modal-department-add-player modal-instance="$ctrl.modalInstance" player="$ctrl.player" />',
            controllerAs: '$ctrl',
            animation: false,
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    this.modalInstance = $uibModalInstance;
                    this.player = player;
                },
            ],
        });
        modal.result.then(function () { return (callback ? callback() : angular.noop()); }).catch(angular.noop);
    };
    ScoutingService.prototype.compareReports = function (players, reports) {
        var modal = this.$uibModal.open({
            template: '<modal-reports-comparision modal-instance="$ctrl.modalInstance" players="$ctrl.players" reports="$ctrl.reports" />',
            animation: false,
            windowClass: 'modal-fullscreen',
            openedClass: 'reports-comparision-modal-open',
            controllerAs: '$ctrl',
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    this.modalInstance = $uibModalInstance;
                    this.players = players;
                    this.reports = reports;
                },
            ],
        });
        modal.result.then(angular.noop).catch(angular.noop);
    };
    ScoutingService.prototype.showCommentsBoard = function (players) {
        var modal = this.$uibModal.open({
            template: '<comments-board modal-instance="$ctrl.modalInstance" players="$ctrl.players"/>',
            windowClass: 'modal-fullscreen',
            openedClass: 'reports-comparision-modal-open',
            controllerAs: '$ctrl',
            animation: false,
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    this.modalInstance = $uibModalInstance;
                    this.players = players;
                },
            ],
        });
        modal.result.then(angular.noop).catch(angular.noop);
    };
    ScoutingService.prototype.openReportCreationModal = function (autoselect) {
        var _this = this;
        var modal = this.$uibModal.open({
            template: '<modal-report-setup modal-instance="$ctrl.modalInstance" autoselect="$ctrl.autoselect">',
            controllerAs: '$ctrl',
            animation: false,
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    this.modalInstance = $uibModalInstance;
                    this.autoselect = autoselect;
                },
            ],
        });
        modal.result.then(function (id) {
            _this.$state.go('root.scouting.report', { id: id, edit: true });
        }, angular.noop);
    };
    ScoutingService.prototype.findTemporaryTeams = function (query) {
        return this.$http
            .get(this.temporaryTeamsEndpoint, { params: { query: query } })
            .then(function (response) { return response.data; });
    };
    ScoutingService.prototype.findTemporaryLeagues = function (query) {
        return this.$http
            .get(this.temporaryLeaguesEndpoint, { params: { query: query } })
            .then(function (response) { return response.data; });
    };
    ScoutingService.prototype.findTemporaryPeople = function (query) {
        return this.$http
            .get(this.temporaryPeopleEndpoint, { params: { query: query } })
            .then(function (response) { return response.data; });
    };
    ScoutingService.prototype.getTemporaryRoster = function (temporaryTeamId) {
        return this.$http
            .get("".concat(this.temporaryTeamsEndpoint, "/").concat(temporaryTeamId, "/roster"))
            .then(function (response) { return response.data; });
    };
    ScoutingService.prototype.getScoutingSheet = function () {
        return this.$http.get(this.scoutingSheetsEndpoint).then(function (response) { return response.data; });
    };
    ScoutingService.prototype.addColumnToScoutingSheet = function (sheetId, _a) {
        var columnName = _a.columnName, template = _a.template;
        return this.$http
            .post(this.scoutingSheetsEndpoint + "/".concat(sheetId, "/add-column"), {
            columnName: columnName,
            template: template,
        })
            .then(function (response) { return response.data; });
    };
    ScoutingService.prototype.removeColumnFromScoutingSheet = function (sheetId, template) {
        return this.$http
            .post(this.scoutingSheetsEndpoint + "/".concat(sheetId, "/remove-column"), {
            template: template,
        })
            .then(function (response) { return response.data; });
    };
    ScoutingService.prototype.getScoutingSheetComments = function (sheetId, playerIds, templateIds) {
        return this.$http
            .post(this.scoutingSheetsEndpoint + "/".concat(sheetId, "/get-comments"), {
            playerIds: playerIds,
            templateIds: templateIds,
        })
            .then(function (response) { return response.data; });
    };
    ScoutingService.prototype.createScoutingSheetComment = function (sheetId, _a) {
        var player = _a.player, template = _a.template, comment = _a.comment;
        return this.$http
            .post(this.scoutingSheetsEndpoint + "/".concat(sheetId, "/add-comment"), {
            player: player,
            template: template,
            comment: comment,
        })
            .then(function (response) { return response.data; });
    };
    ScoutingService.prototype.grantOrRevokeScoutingSheetAccess = function (sheetId, _a) {
        var add = _a.add, remove = _a.remove;
        return this.$http
            .post(this.scoutingSheetsEndpoint + "/".concat(sheetId, "/members"), {
            add: add,
            remove: remove,
        })
            .then(function (response) { return response.data; });
    };
    ScoutingService.prototype.updateScoutingSheetComment = function (sheetId, comment) {
        return this.$http
            .post(this.scoutingSheetsEndpoint + "/".concat(sheetId, "/update-comment"), comment)
            .then(function (response) { return response.data; });
    };
    ScoutingService.prototype.createTemporaryId = function () {
        return "".concat(Date.now(), "-").concat(Math.round(Math.random() * 1000000));
    };
    ScoutingService.prototype.fetchReport = function (id) {
        var instance = this.$scoutingReportResource.get({ id: id });
        instance.$promise.then(function (report) {
            report.$$latestSavedState = angular.toJson(report);
        });
        return instance;
    };
    ScoutingService.prototype.triggerChangedReportSave = function (report) {
        var _this = this;
        if (report._id == null || angular.toJson(report) === report.$$latestSavedState) {
            return;
        }
        this.$pendingActionsService.start('scouting:report:save', 'Saving the report...');
        this.$scoutingReportResource
            .update(this.removeTemporaryFields(report))
            .$promise.then(function (response) {
            report.updatedAt = response.updatedAt;
            report.updatedBy = response.updatedBy;
            report.$$latestSavedState = angular.toJson(report);
            _this.$pendingActionsService.finish('scouting:report:save');
        });
    };
    ScoutingService.prototype.chooseSelectedPlayer = function (report) {
        var _this = this;
        var player;
        if (!player && report.primaryPlayerId) {
            player = report.players.find(function (item) { return item.player._id === report.primaryPlayerId && item.selected; });
        }
        if (!player && report.primaryTeam) {
            player = this.chooseTeamPlayer(report, report.primaryTeam);
        }
        if (!player) {
            player = report.teams
                .map(function (team) { return _this.chooseTeamPlayer(report, team); })
                .filter(function (item) { return !!item; })[0];
        }
        return player;
    };
    ScoutingService.prototype.chooseTeamPlayer = function (report, team) {
        return report.players.filter(function (item) { return item.team === (team._id || team.temporaryId) && item.selected; })[0];
    };
    ScoutingService.prototype.removeTemporaryFields = function (report) {
        report = angular.copy(report);
        for (var _i = 0, _a = report.players; _i < _a.length; _i++) {
            var wrapper = _a[_i];
            delete wrapper.freshPlayerInstance;
        }
        return report;
    };
    ScoutingService.prototype.getTaggingListById = function (reportId, playlistId) {
        return this.$http
            .get("".concat(this.scoutingReportEndpoint, "/").concat(reportId, "/playlist/").concat(playlistId))
            .then(function (response) { return response.data; });
    };
    ScoutingService.prototype.recordScoutingReportTracking = function (player, scoutingReportId) {
        return this.$http.post(this.scoutingReportEndpoint + '/tracking', {
            scoutingReportId: scoutingReportId,
            player: {
                _id: player._id,
                name: player.firstName + ' ' + player.lastName,
            },
            deviceInfo: {
                type: 'web',
                userAgent: window.navigator.userAgent,
            },
            viewMode: 'full',
        });
    };
    ScoutingService.prototype.updateScoutingReportTracking = function (trackingRecordId, player) {
        return this.$http.put(this.scoutingReportEndpoint + '/tracking', {
            recordId: trackingRecordId,
            player: {
                _id: player._id,
                name: player.firstName + ' ' + player.lastName,
            },
        });
    };
    ScoutingService.prototype.calculateGameReportLineup = function (gameReport, gameLineupReport) {
        if (!gameReport) {
            return;
        }
        var currentReport = gameLineupReport;
        if (gameReport.isRandomLineups === true ||
            gameReport.isRandomLineups === false ||
            !gameLineupReport) {
            currentReport = gameReport;
        }
        if (!currentReport) {
            return;
        }
        var players = [];
        var homePlayers = [];
        var awayPlayers = [];
        if (currentReport.players && currentReport.players.length) {
            players = currentReport.players;
            homePlayers = players.filter(function (player) { return player.team === currentReport.home.id; });
            awayPlayers = players.filter(function (player) { return player.team === currentReport.away.id; });
        }
        else if (currentReport.lineups && currentReport.lineups.length) {
            players = currentReport.lineups;
            homePlayers = players.filter(function (player) {
                return player.team === currentReport.home.scId || player.team === currentReport.home.id;
            });
            awayPlayers = players.filter(function (player) {
                return player.team === currentReport.away.scId || player.team === currentReport.away.id;
            });
        }
        var isRandomLineups = currentReport.isRandomLineups === true || currentReport.isRandomLineups === false
            ? currentReport.isRandomLineups
            : !players
                .filter(function (player) { return !['GK1', 'GK2', 'GK3'].includes(player.position); })
                .every(function (player) { return player.line && player.line !== 'goalkeepers'; });
        if (!isRandomLineups) {
            return {
                home: this.extractGameLineupReport(homePlayers),
                away: this.extractGameLineupReport(awayPlayers),
            };
        }
        return {
            home: {},
            away: {},
        };
    };
    ScoutingService.prototype.extractGameLineupReport = function (players) {
        var gk1 = players.find(function (p) { return p.position === 'GK1'; });
        var gk2 = players.find(function (p) { return p.position === 'GK2'; });
        var gk3 = players.find(function (p) { return p.position === 'GK3'; });
        var lineupPlayers = {};
        ['1', '2', '3', '4', '5'].forEach(function (line) {
            lineupPlayers[line] = {};
            ['RD', 'LD', 'RW', 'LW', 'C'].forEach(function (position) {
                var reportPlayer = players.find(function (p) {
                    return p.position && p.position.toLowerCase() === position.toLowerCase() && p.line === line;
                });
                lineupPlayers[line][position] = reportPlayer ? reportPlayer.scId : undefined;
            });
        });
        return {
            goalies: {
                GK1: gk1 ? gk1.scId : undefined,
                GK2: gk2 ? gk2.scId : undefined,
                GK3: gk3 ? gk3.scId : undefined,
            },
            lines: lineupPlayers,
        };
    };
    return ScoutingService;
}());
function ScoutingReportResource($resource, SCConfiguration) {
    return $resource(SCConfiguration.getEndpoint() + '/api/scouting/reports/:id/:endpoint', { id: '@_id' }, {
        query: {
            url: SCConfiguration.getEndpoint() + '/api/scouting/filters/:type',
            method: 'POST',
            isArray: true,
        },
        getFiltersStatistics: {
            url: SCConfiguration.getEndpoint() + '/api/scouting/filters/:type/statistics',
            method: 'POST',
            isArray: false,
        },
        update: { method: 'PUT' },
        getTags: {
            isArray: true,
            params: { endpoint: 'tags' },
        },
        getAttachedTaggingLists: {
            isArray: true,
            params: { endpoint: 'attached-tagging-lists' },
        },
        removeReportTag: {
            isArray: false,
            method: 'DELETE',
            params: { endpoint: 'report-tag' },
        },
        removePlayerTag: {
            isArray: false,
            method: 'DELETE',
            params: { endpoint: 'player-tag' },
        },
    });
}
angular
    .module('app.scouting')
    .service('ScoutingService', ScoutingService)
    .factory('ScoutingReportResource', ScoutingReportResource)
    .constant('LEAGUE_IMPORTANT_PROPERTIES', ['_id', 'name'])
    .constant('PLAYER_IMPORTANT_PROPERTIES', [
    '_id',
    'firstName',
    'lastName',
    'imageUrl',
    'playerPosition',
    'dateOfBirth',
    'height',
    'weight',
    'shoots',
    'catches',
    'contract',
    'country.iso3166_3',
    'country.name',
    'country._id',
    'secondaryCountry.iso3166_3',
    'secondaryCountry.name',
    'secondaryCountry._id',
])
    .constant('TEAM_IMPORTANT_PROPERTIES', [
    '_id',
    'name',
    'imageUrl',
    'country.iso3166_3',
    'country.name',
    'country._id',
    'latestTeamStats.league._id',
    'latestTeamStats.league.name',
]);
;