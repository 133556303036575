interface Label {
  _id: string;
  label?: {name: string};
  playersCount?: number;
  hasRanks?: number;
}

class ScoutingDepartmentController {
  private $labelsResource;

  private labels: Label[];
  private totalPlayersCount: number;
  private selectedLabel: any;

  constructor(
    private $uibModal,
    ScoutingLabelsResource,
    private $state,
  ) {
    this.$labelsResource = ScoutingLabelsResource;
  }

  public $onInit() {
    this.labels = this.$labelsResource.query();
    (this.labels as any).$promise.then((labels) => {
      const selectedLabel = labels.find((item) => item._id === this.$state.params.label);
      if (selectedLabel) {
        this.select(selectedLabel);
      }
      this.updateTotalPlayersCount();
    });
  }

  public hasNamePredicate() {
    return (label) => !!label.label;
  }

  public select(label: any) {
    this.selectedLabel = label;
    this.$state.go('root.scouting.department', {label: label ? label._id : ''}, {notify: false});
  }

  public updateTotalPlayersCount() {
    this.totalPlayersCount = this.labels.reduce((sum, item) => sum + item.playersCount, 0);
  }

  public async showEditLabelModal($event, label) {
    $event.stopPropagation();
    const modal = {
      template:
        '<modal-department-edit-label modal-instance="$ctrl.modalInstance" label="$ctrl.label">',
      controllerAs: '$ctrl',
      animation: false,
      backdrop: true,
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
          this.label = angular.copy(label);
        },
      ],
    };

    try {
      const result = await this.$uibModal.open(modal).result;

      if (result) {
        angular.copy(result, label);
      } else {
        this.labels.splice(this.labels.indexOf(label), 1);
      }

      this.labels = [...this.labels]; // make a new list to trigger onChanges in <department-players>
    } catch (reason) {
      //
    }
  }

  public async showCreateLabelModal() {
    const modal = {
      template: '<modal-department-create-label modal-instance="$ctrl.modalInstance">',
      controllerAs: '$ctrl',
      animation: false,
      backdrop: true,
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.modalInstance = $uibModalInstance;
        },
      ],
    };

    try {
      const result = await this.$uibModal.open(modal).result;

      this.selectedLabel = result;
      this.labels.push(result);
    } catch (reason) {
      //
    }
  }

  public async toggleFavourite($event, label) {
    $event.stopPropagation();
    if (!label.favourite) {
      label.favourite = true;
      label.favouriteAt = moment().unix();
    } else {
      delete label.favourite;
      delete label.favouriteAt;
    }

    this.labels = _.orderBy(
      this.labels,
      [
        (lb: any) => (lb.favourite ? 1 : 0),
        (lb: any) => (lb.label ? lb.label.name.toLowerCase() : ''),
      ],
      ['desc', 'asc'],
    );

    await label.$toggleFavourite().$promise;
  }
}

angular.module('app.scouting').component('scoutingDepartment', {
  templateUrl: 'scouting/components/department.html',
  controller: ScoutingDepartmentController,
});
