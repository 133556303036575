var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var COMPETITION_TYPES = {
    regular: 'REGULAR SEASON',
    post: 'POST-SEASON',
    tournament: 'TOURNAMENT',
    frindly: 'FRIENDLY GAME',
    pre: 'PRE-SEASON',
    custom: 'custom...',
};
var ScoutingGameDetailsController = (function () {
    function ScoutingGameDetailsController($uibModal, $q, $scope, ScoutingTemplateResource, SearchService, CompetitionService, ScoutingService, TEAM_IMPORTANT_PROPERTIES, LEAGUE_IMPORTANT_PROPERTIES) {
        this.$uibModal = $uibModal;
        this.$q = $q;
        this.$scope = $scope;
        this.TEAM_IMPORTANT_PROPERTIES = TEAM_IMPORTANT_PROPERTIES;
        this.LEAGUE_IMPORTANT_PROPERTIES = LEAGUE_IMPORTANT_PROPERTIES;
        this.$templateResource = ScoutingTemplateResource;
        this.$searchService = SearchService;
        this.$competitionService = CompetitionService;
        this.$scoutingService = ScoutingService;
    }
    ScoutingGameDetailsController.prototype.$onInit = function () {
        var _this = this;
        if (this.game && this.game.date) {
            this.game.date = moment(this.game.date).toDate();
        }
        if (this.game && !this.game._id) {
            this.game.home = this.game.home || this.team;
        }
        this.$scope.$watchCollection(function () { return _this.game; }, function () { return _this.$scope.$emit('scouting:element:changed'); }, true);
        this.$scope.$on('scouting:reportSetup:populatingTeamRoster', function (__, _a) {
            var populatingTeamRoster = _a.populatingTeamRoster;
            _this.isDisablingTeamSwap = populatingTeamRoster;
        });
    };
    ScoutingGameDetailsController.prototype.findCompetitionType = function (searchText) {
        return this.predefinedCompetitionTypes.filter(function (type) {
            return !searchText || type.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) !== -1;
        });
    };
    ScoutingGameDetailsController.prototype.findLeague = function (searchText) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, searchResult, temporaryLeagues, existingLeagues, foundLeagues;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.$q.all([
                            this.$searchService.searchForLeagues(searchText),
                            this.$scoutingService.findTemporaryLeagues(searchText),
                        ])];
                    case 1:
                        _a = _b.sent(), searchResult = _a[0], temporaryLeagues = _a[1];
                        existingLeagues = searchResult.leagues.map(function (item) {
                            return _.pick(item._source, _this.LEAGUE_IMPORTANT_PROPERTIES);
                        });
                        foundLeagues = existingLeagues.concat(temporaryLeagues);
                        if (temporaryLeagues.some(function (league) { return league.name == searchText; })) {
                            return [2, foundLeagues];
                        }
                        else {
                            return [2, foundLeagues.concat([
                                    {
                                        name: searchText,
                                        temporaryId: this.$scoutingService.createTemporaryId(),
                                    },
                                ])];
                        }
                        return [2];
                }
            });
        });
    };
    ScoutingGameDetailsController.prototype.resetCachedLeagueTeams = function () {
        this.cachedLeagueTeams = null;
    };
    ScoutingGameDetailsController.prototype.findTeam = function (searchText) {
        return __awaiter(this, void 0, void 0, function () {
            var teams, temporaryTeams, existingTeams, foundTeams;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.$searchService.searchForTeams(searchText)];
                    case 1:
                        teams = (_a.sent()).teams;
                        return [4, this.$scoutingService.findTemporaryTeams(searchText)];
                    case 2:
                        temporaryTeams = _a.sent();
                        existingTeams = teams.map(function (item) { return _.pick(item._source, _this.TEAM_IMPORTANT_PROPERTIES); });
                        foundTeams = existingTeams.concat(temporaryTeams);
                        if (temporaryTeams.some(function (team) { return team.name == searchText; })) {
                            return [2, foundTeams];
                        }
                        else {
                            return [2, foundTeams.concat([
                                    {
                                        name: searchText,
                                        temporaryId: this.$scoutingService.createTemporaryId(),
                                    },
                                ])];
                        }
                        return [2];
                }
            });
        });
    };
    ScoutingGameDetailsController.prototype.createNewCompetition = function (name) {
        this.game.league = { name: name };
    };
    ScoutingGameDetailsController.prototype.createNewTeam = function (type, name) {
        this.game[type] = { name: name };
    };
    ScoutingGameDetailsController.prototype.swapHomeAndAwayTeams = function () {
        var home = this.game.home;
        this.game.home = this.game.away;
        this.game.away = home;
    };
    ScoutingGameDetailsController.prototype.findTeamWithinLeague = function (searchText) {
        var _this = this;
        if (!this.cachedLeagueTeams) {
            this.cachedLeagueTeams = this.$competitionService.getTeamsListArray(this.game.league._id);
        }
        if (!this.cachedLeagueTeams.$resolved) {
            return this.cachedLeagueTeams.$promise.finally(function () { return _this.findTeamWithinLeague(searchText); });
        }
        return this.cachedLeagueTeams
            .filter(this.createTeamComparator(searchText))
            .map(function (item) { return ({ _source: item }); });
    };
    ScoutingGameDetailsController.prototype.createTeamComparator = function (query) {
        query = _.deburr(query).trim().toLocaleLowerCase();
        return function (team) {
            var teamName = _.deburr(team.name).trim().toLocaleLowerCase();
            return teamName.indexOf(query) >= 0;
        };
    };
    Object.defineProperty(ScoutingGameDetailsController.prototype, "predefinedCompetitionTypes", {
        get: function () {
            return [
                COMPETITION_TYPES.regular,
                COMPETITION_TYPES.post,
                COMPETITION_TYPES.tournament,
                COMPETITION_TYPES.frindly,
                COMPETITION_TYPES.pre,
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ScoutingGameDetailsController.prototype, "customCompetitionType", {
        get: function () {
            return COMPETITION_TYPES.custom;
        },
        enumerable: false,
        configurable: true
    });
    return ScoutingGameDetailsController;
}());
angular.module('app.scouting').component('scoutingGameDetails', {
    templateUrl: 'scouting/components/general/game-details.html',
    controller: ScoutingGameDetailsController,
    bindings: {
        game: '=',
        team: '<',
        brief: '@',
        compact: '@',
        editable: '<',
    },
});