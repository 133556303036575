(function () {
  'use strict';

  class ScoutingReportAttachmentsController {
    private value;
    private s3Endpoint;

    constructor(
      private $scope,
      S3_UPLOADS_END_POINT,
    ) {
      this.s3Endpoint = S3_UPLOADS_END_POINT;
    }

    addAttachments(holder, files) {
      this.value = this.value || [];

      this.value.push(...files);
      this.$scope.$emit('scouting:element:changed');
    }

    removeAttachment(file) {
      const index = this.value.indexOf(file);

      this.value.splice(index, 1);
      this.$scope.$emit('scouting:element:changed');
    }

    isPathLookingLikePdf(path) {
      return /\.pdf$/.test(path);
    }

    getPath(path) {
      if (path.indexOf('https://') > -1) {
        return path;
      }

      return `${this.s3Endpoint}/${path}`;
    }
  }

  angular.module('app.scouting').component('scoutingReportAttachments', {
    templateUrl: 'scouting/components/report/attachments.html',
    controller: ScoutingReportAttachmentsController,
    bindings: {
      value: '=',
      template: '<',
      editable: '<',
      direction: '@',
      withMessage: '@',
    },
  });
})();
