var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var ScoutingTagsController = (function () {
    function ScoutingTagsController($scope, ScoutingReportResource) {
        this.$scope = $scope;
        this.$reportResource = ScoutingReportResource;
    }
    ScoutingTagsController.prototype.$onInit = function () {
        var _this = this;
        this.params.tags = this.params.tags || [];
        this.$scope.$watch(function () { return _this.templateId; }, function () { return (_this.availableTags = null); });
        this.$scope.$watchCollection(function () { return _this.list; }, function () { return _this.$scope.$emit('scouting:element:changed'); });
    };
    ScoutingTagsController.prototype.getAvailableTags = function (searchText) {
        var _this = this;
        this.list = this.list || [];
        if (!this.availableTags) {
            this.availableTags = this.$reportResource.getTags({ template: this.templateId });
        }
        if (!this.availableTags.$resolved) {
            return this.availableTags.$promise
                .catch(function () { })
                .then(function () {
                var _a;
                return (_a = _this.availableTags).splice.apply(_a, __spreadArray([0, 0], (_this.params.tags || []), false));
            })
                .then(function () { return _this.getAvailableTags(searchText); });
        }
        var tags = _.uniq(this.availableTags)
            .filter(function (tag) { return _this.list.indexOf(tag) === -1; })
            .filter(function (tag) {
            return !searchText || tag.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) !== -1;
        });
        if (this.excluded) {
            return tags.filter(function (tag) { return !_this.excluded.includes(tag); });
        }
        else {
            return tags;
        }
    };
    return ScoutingTagsController;
}());
angular.module('app.scouting').component('scoutingTags', {
    templateUrl: 'scouting/components/general/tags.html',
    controller: ScoutingTagsController,
    bindings: {
        list: '=',
        params: '<',
        templateId: '<',
        editable: '<',
        excluded: '<',
    },
});